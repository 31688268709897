import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

import Graphics from '../../../../../assets/undraw_pdf.svg';
import { stdAlert } from '../../../../components/alert';
import { useTranslation } from '../../../../translations';
import EditButtonsScreenWrapper from '../EditButtonsScreenWrapper';
import EditPDFsForm from './EditPDFsForm';
import type { PDFButtonType } from './EditPDFsForm';

export default function EditPDFButtonsScreen() {
  const texts = useTranslation('editProfileButtons', 'errors');
  return (
    <EditButtonsScreenWrapper>
      {(data, saveProfileButtons) => {
        const pdfButtons = data.buttons.filter(
          (button) => button.data_type === 'pdf',
        );

        const handleOnSubmit = async (PDFButtonsData: PDFButtonType[]) => {
          const preparedData = PDFButtonsData.map((PDFButton) => {
            return {
              data: {
                uri: PDFButton.pdfUri ?? '',
                name: PDFButton.pdfName ?? '',
              },
              id: PDFButton.id,
            };
          });
          const result = await saveProfileButtons(preparedData, 'pdf');
          if (result) {
            stdAlert(texts.editProfileButtons.updateSuccess);
          } else {
            stdAlert(texts.errors.unexpected);
          }
        };
        return (
          <>
            <View style={styles.instructionWrapper}>
              <Text style={styles.instructionText}>
                {texts.editProfileButtons.editPDFButtonsInstruction}
              </Text>
            </View>
            <View style={styles.graphicsWrapper}>
              <Graphics width={300} height={200} />
            </View>
            <EditPDFsForm
              onSubmit={handleOnSubmit}
              initialPDFsData={pdfButtons.map(({ id, data, label }) => {
                if (data !== null) {
                  const uri = data;
                  const pdfName = decodeURI(
                    data.slice(data.lastIndexOf('/') + 1),
                  );
                  return {
                    id,
                    buttonName: label,
                    pdfUri: uri,
                    pdfName,
                  };
                }
                return {
                  id,
                  buttonName: label,
                  pdfName: undefined,
                  pdfUri: undefined,
                };
              })}
            />
          </>
        );
      }}
    </EditButtonsScreenWrapper>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  graphicsWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  instructionText: {
    opacity: 0.8,
    textAlign: 'center',
  },
});
