import React from 'react';
import { TextStyle, ViewStyle } from 'react-native';
import { useTheme, Button } from 'react-native-paper';

import { IconName } from './Icon';

type Props = React.ComponentProps<typeof Button> & {
  icon?: React.ComponentProps<typeof Button>['icon'] | IconName;
};

export default function ButtonWrapper({
  mode,
  style,
  labelStyle,
  ...rest
}: Props) {
  const { colors } = useTheme();
  const buttonStyle: ViewStyle = {};
  const labelStyleOverride: TextStyle = {};

  if (mode === 'outlined') {
    buttonStyle.borderColor = colors.text;
    labelStyleOverride.color = colors.text;
  }

  return (
    <Button
      mode={mode}
      style={[buttonStyle, style]}
      labelStyle={[labelStyleOverride, labelStyle]}
      {...rest}
    />
  );
}
