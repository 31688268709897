import React, { useEffect } from 'react';
import { StyleSheet, FlatList } from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';

import ScreenWrapper from '../../components/ScreenWrapper';
import { DataLoadStatus } from '../../store/types';
import { useActions, useStore } from '../../store';
import { useTranslation } from '../../translations';

import ListItem from './ListItem';

export default function MyProfilesList() {
  const {
    profiles: { getMyProfilesList },
  } = useActions();
  const {
    profiles: { profilesList },
  } = useStore();
  const texts = useTranslation('common');
  useEffect(() => {
    getMyProfilesList();
  }, []);

  return (
    <ScreenWrapper noPadding style={styles.container}>
      {profilesList.data.length === 0 &&
      profilesList.status !== DataLoadStatus.SUCCESS ? (
        <ActivityIndicator />
      ) : (
        <ScreenWrapper.Content style={styles.content}>
          <FlatList
            ItemSeparatorComponent={() => null}
            data={profilesList.data}
            keyExtractor={(item) => item.id.toString()}
            ListEmptyComponent={() => <Text>{texts.common.emptyList}</Text>}
            renderItem={({ item }) => <ListItem item={item} />}
            style={styles.listContainer}
          />
        </ScreenWrapper.Content>
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    paddingHorizontal: 0,
  },
  listContainer: {
    width: '100%',
  },
});
