import React, { useState, useContext } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { Text, ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';

import { useTranslation } from '../../../translations';
import { useTheme } from '../../../theming';
import { checkCameraRollPermissionForIOS } from '../../../components/UserMedia/imageUtils';
import { DEFAULT_URL_BANNER } from '../../../consts';
import AutoscaleImage from '../../../components/AutoscaleImage';
import Button from '../../../components/Button';
import { DataLoadStatus } from '../../../store/types';
import Icon from '../../../components/Icon';
import { stdAlert } from '../../../components/alert';
import DataContext from '../DataContext';
import PremiumFeatureGate from '../../../components/PremiumFeatureGate';

export default function ChangeProfileTopBanner() {
  const { data, status, updateProfileTopBanner, hasPolicy } = useContext(
    DataContext.Context,
  );
  const { colors } = useTheme();
  const texts = useTranslation('userMedia', 'common', 'errors', 'edit');
  const [hasPermission, setHasPermission] = useState<boolean | null>(
    Constants?.platform?.ios !== undefined ? null : true,
  );
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [topBannerUri, setTopBannerUri] = useState<string | null>(null);

  const [preview, setPreview] = useState(data?.logo_url ?? DEFAULT_URL_BANNER);

  const handleAddingPhoto = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );

    if (grantedPermission === true) {
      setHasPermission(true);
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.cancelled) {
        setTopBannerUri(result.uri);
        setPreview(result.uri);
      }
    } else {
      setHasPermission(false);
    }
  };

  const handleSave = async () => {
    if (topBannerUri !== null) {
      setIsUpdatingData(true);
      const result = await updateProfileTopBanner(topBannerUri);
      if (result) {
        stdAlert(texts.edit.updateSuccess);
      } else {
        stdAlert(texts.errors.unexpected);
      }
      setIsUpdatingData(false);
    }
  };

  if (hasPermission === false) {
    return (
      <>
        <AutoscaleImage
          uri={preview}
          style={styles.imagePreview}
          resizeMode="contain"
        />
        <Text>{texts.userMedia.noCameraPermission}</Text>
        <Button style={styles.saveButton} onPress={handleSave} mode="contained">
          {texts.edit.save}
        </Button>
      </>
    );
  }

  return (
    <>
      {status === DataLoadStatus.SUCCESS && data !== null ? (
        <ScrollView
          contentContainerStyle={styles.scrollViewContentContainerStyle}
        >
          <View style={styles.instructionWrapper}>
            <Text>{texts.userMedia.changeTopBanner}</Text>
          </View>
          <AutoscaleImage
            uri={preview}
            style={styles.imagePreview}
            resizeMode="contain"
          />
          <View style={styles.buttonsWrapper}>
            <PremiumFeatureGate
              alwaysClickable={hasPolicy('update_top_banner')}
            >
              <Button
                icon={() => (
                  <Icon
                    name="upload"
                    style={[styles.uploadIcon, { color: colors.text }]}
                  />
                )}
                mode="outlined"
                onPress={handleAddingPhoto}
              >
                {texts.userMedia.uploadTopBanner}
              </Button>
            </PremiumFeatureGate>
            <Button
              loading={isUpdatingData}
              style={styles.saveButton}
              onPress={handleSave}
              mode="contained"
            >
              {texts.edit.save}
            </Button>
          </View>
        </ScrollView>
      ) : status === DataLoadStatus.ERROR ? (
        <Text>{texts.errors.unexpected}</Text>
      ) : (
        <View style={styles.loaderWrapper}>
          <ActivityIndicator />
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    paddingVertical: 24,
  },
  scrollViewContentContainerStyle: {
    alignItems: 'center',
  },
  loaderWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imagePreview: {
    width: 300,
    height: 200,
  },
  saveButton: {
    marginTop: 24,
  },
  buttonsWrapper: {
    marginTop: 20,
    alignSelf: 'stretch',
  },
  uploadIcon: {
    fontSize: 24,
  },
});
