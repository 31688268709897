import React from 'react';
import { Text } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import AutoscaleImage from '../AutoscaleImage';
import { useTranslation } from '../../translations';
import { useTheme } from '../..//theming';

type Props = {
  customPoweredByUrl?: string | null;
};

export default function WhitelabelFooter({ customPoweredByUrl }: Props) {
  const { colors } = useTheme();
  const texts = useTranslation('common', 'whitelabel');
  if (customPoweredByUrl === null || customPoweredByUrl === undefined) {
    return (
      <View style={[styles.footer]}>
        <Text>{texts.whitelabel.poweredBy}</Text>
        <Text style={[{ color: colors.primary }, styles.theTagNetwork]}>
          {texts.common.theTagNetwork}
        </Text>
      </View>
    );
  }
  return (
    <AutoscaleImage uri={customPoweredByUrl} style={[styles.footerImage]} />
  );
}

const styles = StyleSheet.create({
  footerImage: {
    width: '100%',
    resizeMode: 'contain',
  },
  footer: {
    marginTop: 'auto',
    alignItems: 'center',
    paddingVertical: 16,
  },
  theTagNetwork: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    letterSpacing: 0.4,
    marginTop: 4,
  },
});
