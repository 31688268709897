import _axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { Platform } from 'react-native';

import config from '../../../config';
import { reset } from '../../../globalNavigate';
import translations from '../../../translations';
import { stdAlert } from '../../../components/alert';
import { getPhotoInfo } from '../../../components/UserMedia/imageUtils';
import { getVideoFileInfo } from '../../../components/UserMedia/videoUtils';
import { createEmptyBlob } from '../../../utils/createEmptyBlob';

import {
  UserMeta,
  MyProfileMeta,
  ProfileDetails,
  InvitationData,
  OnboardingUserAccountData,
  UserAccountGeneral,
  UserPasswordData,
  DateFields,
  ErrorResponse,
  ScannedProfileMeta,
  EditableProfileDetails,
  SurveyQuestion,
  ResetPasswordData,
  ButtonType,
  EditSurveyButtonData,
  EditButton,
  EditPDFButtonData,
  Whitelabel,
  SavedProfilesTree,
  Policy,
  TemplateSharedProfilesData,
  DescriptionDetails,
} from './types';

async function promiseSequence(promises: Array<() => Promise<void>>) {
  return promises.reduce((acc, curr) => acc.then(curr), Promise.resolve());
}

type Response<S = unknown, E = unknown> = {
  success?: S;
  error?: E;
};

async function prepareUserPhotoData(
  localUri: string,
  filename: string,
  type: string,
): Promise<FormData> {
  const formData = new FormData();
  if (Platform.OS !== 'web') {
    formData.append(
      'picture',
      { uri: localUri, name: filename, type },
      filename,
    );
  } else {
    const blob = await fetch(localUri).then((r) => r.blob());
    formData.append('picture', blob, filename);
  }
  return formData;
}

async function prepareUserVideoData(
  localUri: string,
  filename: string,
  type: string,
): Promise<FormData> {
  const formData = new FormData();
  if (Platform.OS !== 'web') {
    formData.append(
      'video_clip',
      { uri: localUri, name: filename, type },
      filename,
    );
  } else {
    const blob = await fetch(localUri).then((r) => r.blob());
    formData.append('video_clip', blob, filename);
  }
  return formData;
}

async function prepareTopBannerData(
  topBannerUri: string,
  filename: string,
  type: string,
): Promise<FormData> {
  const formData = new FormData();
  if (Platform.OS !== 'web') {
    formData.append(
      'logo',
      { uri: topBannerUri, name: filename, type },
      filename,
    );
  } else {
    const blob = await fetch(topBannerUri).then((r) => r.blob());
    formData.append('logo', blob, filename);
  }
  return formData;
}

async function prepareSurveyAnswersData(
  questionsWithAnswers: SurveyQuestion[],
): Promise<FormData> {
  const formData = new FormData();
  await Promise.all(
    questionsWithAnswers.map(async (questionWithAnswer) => {
      formData.append(
        `responses[][question_id]`,
        questionWithAnswer.id.toString(),
      );
      formData.append(
        'responses[][question_type]',
        questionWithAnswer.question_type,
      );
      if (questionWithAnswer.question_type === 'text') {
        formData.append('responses[][answer]', questionWithAnswer.answer ?? '');
      } else if (questionWithAnswer.question_type === 'menu') {
        formData.append(
          'responses[][answer][]',
          questionWithAnswer.answer ?? '',
        );
      } else if (
        questionWithAnswer.question_type === 'file' &&
        questionWithAnswer.answer !== null &&
        questionWithAnswer.answer !== ''
      ) {
        const [uri, name] = questionWithAnswer.answer.split('_-_');
        const blob = await fetch(uri).then((r) => r.blob());
        if (Platform.OS !== 'web') {
          formData.append(
            'responses[][answer]',
            { uri, name, type: blob.type },
            name,
          );
        } else {
          formData.append('responses[][answer]', blob, name);
        }
      }
    }),
  );
  return formData;
}

function isSurvey(
  buttonData: EditButton,
  buttonType: NonNullable<ButtonType['data_type']>,
): buttonData is { data: EditSurveyButtonData; id: number } {
  return buttonType === 'survey';
}

function isNotSurvey(
  buttonData: EditButton,
  buttonType: NonNullable<ButtonType['data_type']>,
): buttonData is { data: string; id: number } {
  return buttonType !== 'survey';
}

function isPDFButton(
  buttonData: EditButton,
  buttonType: NonNullable<ButtonType['data_type']>,
): buttonData is { data: EditPDFButtonData; id: number } {
  return buttonType === 'pdf';
}

async function prepareDataForSavingButtons(
  buttonsData: EditButton[],
  buttonsType: NonNullable<ButtonType['data_type']>,
) {
  const formData = new FormData();
  await promiseSequence(
    buttonsData.map((button) => {
      return async () => {
        formData.append('buttons[][button_id]', button.id.toString());
        formData.append('buttons[][data_type]', buttonsType);
        if (isSurvey(button, buttonsType)) {
          formData.append('buttons[][data][email]', button.data.email);
          if (button.data.phone !== undefined) {
            formData.append('buttons[][data][phone]', button.data.phone);
          }
        } else if (isNotSurvey(button, buttonsType)) {
          if (isPDFButton(button, buttonsType)) {
            const blob = await fetch(button.data.uri).then((r) => r.blob());
            const filename = button.data.name;
            if (Platform.OS !== 'web') {
              formData.append('buttons[][data]', {
                uri: button.data.uri,
                type: blob.type,
                name: filename,
              });
            } else {
              formData.append('buttons[][data]', blob, filename);
            }
          } else {
            formData.append('buttons[][data]', button.data);
          }
        }
      };
    }),
  );
  return formData;
}

async function prepareDataForSavingDescriptionButton(
  descriptionButtonData: DescriptionDetails,
) {
  const formData = new FormData();
  if (descriptionButtonData.id !== null) {
    formData.append('id', descriptionButtonData.id.toString());
  }
  if (descriptionButtonData?.elements) {
    await promiseSequence(
      descriptionButtonData.elements.map((element) => {
        return async () => {
          if (element.id !== null) {
            formData.append('elements[][id]', element.id.toString());
          }
          formData.append('elements[][data_type]', element.data_type);

          // If element is not edited, do not pass that property to `formData`,
          // so that BE doesn't modify the element.
          if (element.edited === true) {
            formData.append('elements[][edited]', element.edited.toString());
          }

          if (
            element.edited === false &&
            (element.data_type === 'video' || element.data_type === 'photo')
          ) {
            // If element is kind of photo or video and is not edited, there is
            // no need to fetch the blob again and upload the media one more time.
            // However there is still a need to fill the `formData` so `emptyBlob` is passed.
            const emptyBlob = createEmptyBlob(element.data_type);

            formData.append('elements[][data]', emptyBlob);
          } else if (element.data_type === 'video') {
            const videoInfo = getVideoFileInfo(element.data);
            if (videoInfo !== null) {
              const blob = await fetch(element.data).then((r) => r.blob());
              const { filename } = videoInfo;
              if (Platform.OS !== 'web') {
                formData.append(
                  'elements[][data]',
                  { uri: element.data, name: filename, type: blob.type },
                  filename,
                );
              } else {
                formData.append(
                  'elements[][data]',
                  blob,
                  `description-${blob.type.replace('/', '.')}`,
                );
              }
            }
          } else if (element.data_type === 'photo') {
            const photoInfo = getPhotoInfo(element.data);
            if (photoInfo !== null) {
              const blob = await fetch(element.data).then((r) => r.blob());
              const { filename } = photoInfo;
              if (Platform.OS !== 'web') {
                if (photoInfo !== null) {
                  formData.append(
                    'elements[][data]',
                    { uri: element.data, name: filename, type: blob.type },
                    filename,
                  );
                }
              } else {
                formData.append('elements[][data]', blob, filename);
              }
            }
          } else {
            formData.append('elements[][data]', element.data);
          }
        };
      }),
    );
  }

  return formData;
}

export const api = (() => {
  const axios = _axios.create({
    baseURL: config.apiURL,
    timeout: 30000,
  });

  return {
    initialize({
      logout,
      getToken,
    }: {
      getToken: () => string | null;
      logout: () => void;
    }) {
      axios.interceptors.request.use((config: AxiosRequestConfig) => {
        const authToken = getToken();
        if (authToken !== null) {
          config.headers.authorization = `Bearer ${authToken}`;
        }
        return config;
      });

      axios.interceptors.response.use(undefined, (error: AxiosError) => {
        console.log("22222");
        console.log(error);
        console.log(error.response);
        console.log(error.response?.status);
        if (error.response?.status === 401) {
          stdAlert(translations.t('errors.unauthorized'));
          logout();
          reset('Welcome');
        } else if (
          error.response?.status === 422 &&
          error.response?.data.messages !== undefined
        ) {
          stdAlert(error.response.data.messages.join('\n'));
        } else if (error.response?.status !== 404) {
          stdAlert(translations.t('errors.unexpected'));
        }
        throw error;
      });

      axios.interceptors.response.use((value: AxiosResponse<DateFields>) => {
        if (Array.isArray(value.data)) {
          const possibleDateFields: Array<keyof DateFields> = [
            'created_at',
            'saved_at',
            'scanned_at',
          ];
          return {
            ...value,
            data: value.data.map((v) => {
              const fieldToUpdate = possibleDateFields.find(
                (field) => v[field] !== undefined,
              );
              if (fieldToUpdate !== undefined) {
                return {
                  ...v,
                  [fieldToUpdate]: v[fieldToUpdate] * 1000,
                };
              }
              return v;
            }),
          };
        }
        return value;
      });
    },

    async confirmShare(shareUuid: string): Promise<
      Response<{
        data: {
          id: string;
          url_to_share: string;
          status: 'ALREADY_CONFIRMED' | 'SUCCESS';
        };
      }>
    > {
      try {
        const response = await axios.post(
          `/account/profiles/shares/${shareUuid}/confirm`,
        );
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },
    async exchangeShareUuidForProfileId(
      shareUuid: string,
    ): Promise<Response<{ data: { id: number } }>> {
      try {
        const response = await axios.get(`/shares/${shareUuid}/profile`);
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },

    async generateShareLinkForLoggedInUser(
      profileId: number,
    ): Promise<Response<{ data: { id: number; url_to_share: string } }>> {
      try {
        const response = await axios.post(
          `/account/profiles/${profileId}/share`,
        );
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },

    async generateShareLinkForUnloggedUser(
      profileId: number,
    ): Promise<Response<{ data: { id: number; url_to_share: string } }>> {
      try {
        const response = await axios.post(`/profiles/${profileId}/share`);
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },
    async getToken(
      email: string,
      password: string,
    ): Promise<Response<{ token: string }>> {
      try {
        const response = await axios.post('/sessions', {
          email,
          password,
        });
        return { success: { token: response.data.token } };
      } catch (e) {
        return { error: e };
      }
    },

    async getAccountData(): Promise<Response<UserMeta>> {
      try {
        const response = await axios.get('/account');
        return { success: response.data };
      } catch (e) {
        return { error: e };
      }
    },

    async checkIfResetPasswordTokenValid(
      resetPasswordToken: string,
    ): Promise<Response<boolean>> {
      try {
        const response = await axios.get(
          `/password/${resetPasswordToken}/check_reset_password_token`,
        );
        const successCode = 200;
        return { success: response.data === successCode };
      } catch (e) {
        return { error: e };
      }
    },

    async updateUserPhoto({
      photoUri,
    }: {
      photoUri: string;
    }): Promise<Response<boolean>> {
      try {
        const photoInfo = getPhotoInfo(photoUri);
        if (photoInfo !== null) {
          const { filename, type } = photoInfo;
          const preparedData = await prepareUserPhotoData(
            photoUri,
            filename,
            type,
          );
          const response = await axios.post('/account/assets', preparedData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
          const successCode = 201;
          return { success: response.data === successCode };
        }
        return { success: false };
      } catch (e) {
        return { error: e };
      }
    },

    async saveSurvey(
      surveyId: number,
      profileId: number,
      formResult: {
        questionsWithAnswers: SurveyQuestion[];
        referral?: { email: string; phoneNum: string; name: string };
      },
      isReferral = false,
    ): Promise<Response<boolean>> {
      try {
        const preparedData = await prepareSurveyAnswersData(
          formResult.questionsWithAnswers,
        );
        if (isReferral && formResult.referral !== undefined) {
          preparedData.append('is_referral', 'true');
          preparedData.append('referral_name', formResult.referral.name);
          preparedData.append('referral_email', formResult.referral.email);
          preparedData.append('referral_phone', formResult.referral.phoneNum);
        }
        const response = await axios.post(
          `/profiles/${profileId}/surveys/${surveyId}/responses`,
          preparedData,
          {
            headers: { 'content-type': 'multipart/form-data' },
          },
        );
        const successCode = 201;
        return { success: response.data === successCode };
      } catch (e) {
        return { error: e };
      }
    },
    async saveProfileButtons(
      profileID: number,
      buttonsData: EditButton[],
      buttonsType: NonNullable<ButtonType['data_type']>,
    ): Promise<Response<boolean>> {
      try {
        const preparedData = await prepareDataForSavingButtons(
          buttonsData,
          buttonsType,
        );

        const response = await axios.patch(
          `/account/profiles/${profileID}/buttons`,
          preparedData,
          {
            headers: { 'content-type': 'multipart/form-data' },
          },
        );
        const successCode = 200;
        return { success: response.data === successCode };
      } catch (e) {
        return { error: e };
      }
    },

    async updateProfileVideoLink(
      profileID: number,
      videoLink: string,
    ): Promise<Response<boolean>> {
      try {
        const response = await axios.post(
          `/account/profiles/${profileID}/assets`,
          {
            video: videoLink,
          },
        );
        const successCode = 201;
        return { success: response.data === successCode };
      } catch (e) {
        return { error: e };
      }
    },

    async updateUserVideo({
      videoUri,
    }: {
      videoUri: string;
    }): Promise<Response<boolean>> {
      try {
        const videoInfo = getVideoFileInfo(videoUri);
        if (videoInfo !== null) {
          const { filename, type } = videoInfo;
          const preparedData = await prepareUserVideoData(
            videoUri,
            filename,
            type,
          );
          const response = await axios.post('/account/assets', preparedData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
          const successCode = 201;
          return { success: response.data === successCode };
        }
        return { success: false };
      } catch (e) {
        return { error: e };
      }
    },

    async updateUserVideoLink({
      videoLink,
    }: {
      videoLink: string;
    }): Promise<Response<boolean>> {
      try {
        const response = await axios.post('/account/assets', {
          video: videoLink,
        });
        const successCode = 201;
        return { success: response.data === successCode };
      } catch (e) {
        return { error: e };
      }
    },

    async markProfileAsFavourite(
      profileID: number,
    ): Promise<Response<boolean>> {
      try {
        const response = await axios.post(
          `/account/profiles/${profileID}/mark_as_favorite`,
        );
        const successCode = 201;
        return { success: response.data === successCode };
      } catch (e) {
        return { error: e };
      }
    },

    async getProfileBranding(
      profileID: number,
    ): Promise<Response<{ data: { template_white_label: Whitelabel } }>> {
      try {
        const response = await axios.get(`/profiles/${profileID}/branding`);
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },

    async getProfilePolicies(
      profileID: number,
    ): Promise<Response<{ data: Policy[] }>> {
      try {
        const response = await axios.get(
          `/account/profiles/${profileID}/policies`,
        );
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },

    async getProfilesList(): Promise<
      Response<{ data: Array<MyProfileMeta> }, ErrorResponse>
    > {
      try {
        const response = await axios.get('/account/profiles');
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },

    async getTemplateSharedProfiles(
      templateId: string,
    ): Promise<Response<{ data: TemplateSharedProfilesData }, ErrorResponse>> {
      try {
        const response = await axios.get(`templates/${templateId}/profiles`);
        const createdAt = response.data.created_at;
        return {
          success: {
            data: {
              ...response.data,
              created_at: createdAt != null ? createdAt * 1000 : null,
            },
          },
        };
      } catch (e) {
        return { error: e };
      }
    },

    async getScannedProfilesList(): Promise<
      Response<{ data: Array<ScannedProfileMeta> }>
    > {
      try {
        const response = await axios.get('/account/scanned_profiles');
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },

    async getSavedProfilesList(
      folderId?: string,
    ): Promise<Response<{ data: SavedProfilesTree }>> {
      try {
        let url = '/account/saved_profiles';
        if (typeof folderId !== 'undefined') {
          url += `?folder_id=${folderId}`;
        }
        const response = await axios.get(url);
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },

    async moveProfileToFolder(
      profileId?: string,
      folderId?: string,
    ): Promise<Response<{ data: SavedProfilesTree }>> {
      try {
        const response = await axios.post(
          `/account/saved_profiles/${profileId}/move`,
          {
            folder_id: folderId,
          },
        );
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },

    async saveProfile(profileID: number): Promise<Response<boolean>> {
      try {
        const response = await axios.post(
          `/account/profiles/${profileID}/save`,
        );
        const successCode = 201;
        return { success: response.status === successCode };
      } catch (e) {
        return { error: e };
      }
    },
    async getProfileDetails(
      profileID: number,
    ): Promise<Response<{ data: ProfileDetails }>> {
      try {
        const response = await axios.get(`/profiles/${profileID}`);
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },
    async getProfileBarcode(
      profileID: number,
    ): Promise<Response<{ data: { barcode_url: string } }>> {
      try {
        const response = await axios.get(`/profiles/${profileID}/barcode`, {
          timeout: 10 * 1000,
        });
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },
    async saveBarcodeScan(barcodeUuid: string): Promise<
      Response<{
        data: { id: number; status: 'ALREADY_SCANNED_TODAY' | 'SUCCESS' };
      }>
    > {
      try {
        const response = await axios.post(
          `/account/barcodes/${barcodeUuid}/scan`,
        );
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },
    async updateProfileDetails(
      profileID: number,
      profileData: EditableProfileDetails,
    ): Promise<Response<boolean>> {
      try {
        const response = await axios.patch(`/account/profiles/${profileID}`, {
          profile_id: profileID,
          ...profileData,
        });
        const successCode = 200;
        return {
          success: response.data === successCode,
        };
      } catch (e) {
        return { error: e };
      }
    },
    async updateProfilePhoto(
      profileID: number,
      photoUri: string,
    ): Promise<Response<boolean>> {
      try {
        const photoInfo = getPhotoInfo(photoUri);
        if (photoInfo !== null) {
          const { filename, type } = photoInfo;
          const preparedData = await prepareUserPhotoData(
            photoUri,
            filename,
            type,
          );
          const response = await axios.post(
            `/account/profiles/${profileID}/assets`,
            preparedData,
            {
              headers: { 'content-type': 'multipart/form-data' },
            },
          );
          const successCode = 201;
          return { success: response.data === successCode };
        }
        return { success: false };
      } catch (e) {
        return { error: e };
      }
    },
    async updateProfileTopBanner(
      profileID: number,
      topBannerUri: string,
    ): Promise<Response<boolean>> {
      try {
        const photoInfo = getPhotoInfo(topBannerUri);
        if (photoInfo !== null) {
          const { filename, type } = photoInfo;
          const topBannerPreparedData = await prepareTopBannerData(
            topBannerUri,
            filename,
            type,
          );
          const response = await axios.post(
            `/account/profiles/${profileID}/top_banner`,
            topBannerPreparedData,
          );
          const successCode = 201;
          return { success: response.data === successCode };
        }
        return { success: false };
      } catch (e) {
        return { error: e };
      }
    },
    async updateProfileVideo(
      profileID: number,
      videoUri: string,
    ): Promise<Response<boolean>> {
      try {
        const videoInfo = getVideoFileInfo(videoUri);
        if (videoInfo !== null) {
          const { filename, type } = videoInfo;
          const preparedData = await prepareUserVideoData(
            videoUri,
            filename,
            type,
          );
          const response = await axios.post(
            `/account/profiles/${profileID}/assets`,
            preparedData,
            {
              headers: { 'content-type': 'multipart/form-data' },
            },
          );
          const successCode = 201;
          return { success: response.data === successCode };
        }
        return { success: false };
      } catch (e) {
        return { error: e };
      }
    },
    async getInvitationData(
      invitationHash: string,
    ): Promise<Response<{ data: InvitationData }>> {
      try {
        const response = await axios.get(
          `/onboarding/templates/${invitationHash}`,
        );
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },
    async updateOnboardedUserPhoto({
      photoUri,
      profileID,
    }: {
      photoUri: string;
      profileID: number;
    }): Promise<Response<boolean>> {
      try {
        const photoInfo = getPhotoInfo(photoUri);

        if (photoInfo !== null) {
          const { filename, type } = photoInfo;
          const preparedData = await prepareUserPhotoData(
            photoUri,
            filename,
            type,
          );

          const response = await axios.post(
            `/onboarding/profiles/${profileID}/assets`,
            preparedData,
            {
              headers: {
                'content-type': 'multipart/form-data',
              },
            },
          );
          const successCode = 201;
          return { success: response.data === successCode };
        }
        return { success: false };
      } catch (e) {
        return { error: e };
      }
    },
    async createProfile(invitationHash: string): Promise<Response<boolean>> {
      try {
        const response = await axios.post(
          `/onboarding/templates/${invitationHash}/enrollments`,
          { hash_identificator: invitationHash },
        );
        return {
          success: Boolean(response.data),
        };
      } catch (e) {
        return { error: e };
      }
    },
    async createUserAccount(
      userCreateAccountData: OnboardingUserAccountData,
      invitationHash: string,
    ): Promise<
      Response<{ data: { user_id: number; profile_id: number; token: string } }>
    > {
      try {
        const response = await axios.post(
          `/onboarding/templates/${invitationHash}/registrations`,
          { ...userCreateAccountData, hash_identificator: invitationHash },
        );
        return { success: { data: response.data } };
      } catch (e) {
        console.log(e);
        return { error: e };
      }
    },

    async createSavedProfilesFolder(
      name: string,
      parentFolderId?: string,
    ): Promise<
      Response<{ data: { user_id: number; profile_id: number; token: string } }>
    > {
      try {
        const response = await axios.post(`/account/saved_profiles/folders`, {
          name,
          parent_folder_id: parentFolderId,
        });
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },

    async deleteSavedProfilesFolder(
      folderId: string,
    ): Promise<
      Response<{ data: { user_id: number; profile_id: number; token: string } }>
    > {
      try {
        const response = await axios.delete(
          `/account/saved_profiles/folders/${folderId}`,
        );
        return { success: { data: response.data } };
      } catch (e) {
        return { error: e };
      }
    },

    async updateUserAccountData<T>(
      userData: T extends UserAccountGeneral
        ? UserAccountGeneral
        : UserPasswordData,
    ): Promise<Response<boolean>> {
      try {
        const response = await axios.patch(`/account`, userData);
        const successCode = 200;
        return { success: response.data === successCode };
      } catch (e) {
        return { error: e };
      }
    },

    async updateOnboardedUserVideo({
      videoUri,
      profileID,
    }: {
      videoUri: string;
      profileID: number;
    }): Promise<Response<boolean>> {
      try {
        const videoInfo = getVideoFileInfo(videoUri);

        if (videoInfo !== null) {
          const { filename, type } = videoInfo;
          const preparedData = await prepareUserVideoData(
            videoUri,
            filename,
            type,
          );

          const response = await axios.post(
            `/onboarding/profiles/${profileID}/assets`,
            preparedData,
            {
              headers: {
                'content-type': 'multipart/form-data',
              },
            },
          );
          const successCode = 201;
          return { success: response.data === successCode };
        }
        return { success: false };
      } catch (e) {
        return { error: e };
      }
    },
    async updateOnboardedUserVideoLink({
      videoLink,
      profileID,
    }: {
      videoLink: string;
      profileID: number;
    }): Promise<Response<boolean>> {
      try {
        const response = await axios.post(
          `/onboarding/profiles/${profileID}/assets`,
          { video: videoLink },
        );
        const successCode = 201;
        return { success: response.data === successCode };
      } catch (e) {
        return { error: e };
      }
    },
    async sendResetPasswordInstructions(
      email: string,
    ): Promise<Response<boolean>> {
      try {
        const response = await axios.post('/password/forgot', { email });
        const successCode = 201;
        return { success: response.data === successCode };
      } catch (e) {
        return { error: e };
      }
    },
    async resetPassword(
      resetPasswordData: ResetPasswordData,
    ): Promise<Response<boolean>> {
      try {
        const response = await axios.put('/password/reset', resetPasswordData);
        const successCode = 200;
        return { success: response.data === successCode };
      } catch (e) {
        return { error: e };
      }
    },
    async saveDescription(
      data: DescriptionDetails,
      profileID: number,
    ): Promise<Response<boolean>> {
      try {
        const preparedData = await prepareDataForSavingDescriptionButton(data);

        const response = await axios.put(
          `/account/profiles/${profileID}/description_button`,
          preparedData,
          {
            headers: { 'content-type': 'multipart/form-data' },
            timeout: 0,
          },
        );

        const successCode = 200;
        return { success: response.data === successCode };
      } catch (e) {
        return { error: e };
      }
    },
  };
})();

export default api;
