import React from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';
import AutoscaleImage from '../../components/AutoscaleImage';

import { useTheme } from '../../theming';
import Header from './Header';

type Props = {
  titleBackground: string | null;
  title: React.ReactText | null;
  logoBanner: string | null;
  style?: StyleProp<ViewStyle>;
};

export default function LogoHeader({
  titleBackground,
  title,
  logoBanner,
  style,
}: Props) {
  const { colors } = useTheme();

  return (
    <View
      style={[{ backgroundColor: colors.background }, styles.container, style]}
    >
      <AutoscaleImage uri={logoBanner} style={styles.logoBanner} />
      <Header background={titleBackground}>{title}</Header>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  logoBanner: {
    width: '100%',
    resizeMode: 'contain',
  },
});
