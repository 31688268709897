import { Action } from 'overmind';
import {
  UserAccountGeneral,
  UserPasswordData,
  ResetPasswordData,
} from '../effects/api/types';

export const setUserName: Action<string, void> = ({ state }, name) => {
  if (state.user.meta) {
    state.user.meta.name = name;
  }
};

export const login: Action<
  { email: string; password: string },
  Promise<boolean>
> = async ({ state, effects }, { email, password }) => {
  const loginResult = await effects.api.getToken(email, password);
  if (loginResult.success) {
    state.user.token = loginResult.success.token;
    return true;
  }
  return false;
};

export const fetchAndSetUserAccountData: Action<
  void,
  Promise<boolean>
> = async ({ state, effects, actions }) => {
  const accountDetails = await effects.api.getAccountData();
  actions.profiles.getMyProfilesList();
  if (accountDetails.success) {
    state.user.meta = accountDetails.success;
    return true;
  }
  return false;
};

export const updateUserGeneralData: Action<
  { userGeneralData: UserAccountGeneral },
  Promise<boolean>
> = async ({ effects, actions }, { userGeneralData }) => {
  const updateResult =
    await effects.api.updateUserAccountData<UserAccountGeneral>(
      userGeneralData,
    );

  if (updateResult.success === true) {
    const result = await actions.user.fetchAndSetUserAccountData();
    if (result) {
      return true;
    }
  }

  return false;
};

export const updateUserPasswordData: Action<
  { userPasswordData: UserPasswordData },
  Promise<boolean>
> = async ({ effects }, { userPasswordData }) => {
  const updateResult =
    await effects.api.updateUserAccountData<UserPasswordData>(userPasswordData);
  return updateResult.success === true;
};

export const updateUserPhoto: Action<
  { photoUri: string },
  Promise<boolean>
> = async ({ effects }, { photoUri }) => {
  const updateResult = await effects.api.updateUserPhoto({ photoUri });
  return updateResult.success === true;
};

export const updateUserVideo: Action<
  { videoUri: string },
  Promise<boolean>
> = async ({ effects }, { videoUri }) => {
  const updateResult = await effects.api.updateUserVideo({ videoUri });
  return updateResult.success === true;
};

export const updateUserVideoLink: Action<
  { videoLink: string },
  Promise<boolean>
> = async ({ effects }, { videoLink }) => {
  const updateResult = await effects.api.updateUserVideoLink({ videoLink });
  return updateResult.success === true;
};

export const updateOnboardedUserPhoto: Action<
  { photoUri: string; profileID: number },
  Promise<boolean>
> = async ({ effects }, { photoUri, profileID }) => {
  const updateResult = await effects.api.updateOnboardedUserPhoto({
    photoUri,
    profileID,
  });
  if (updateResult.success === true) {
    return true;
  }

  return false;
};

export const updateOnboardedUserVideo: Action<
  { videoUri: string; profileID: number },
  Promise<boolean>
> = async ({ effects }, { videoUri, profileID }) => {
  const updateResult = await effects.api.updateOnboardedUserVideo({
    videoUri,
    profileID,
  });
  if (updateResult.success === true) {
    return true;
  }

  return false;
};

export const updateOnboardedUserVideoLink: Action<
  { videoLink: string; profileID: number },
  Promise<boolean>
> = async ({ effects }, { videoLink, profileID }) => {
  const updateResult = await effects.api.updateOnboardedUserVideoLink({
    videoLink,
    profileID,
  });
  if (updateResult.success === true) {
    return true;
  }

  return false;
};

export const sendResetPasswordInstructions: Action<
  { email: string },
  Promise<boolean>
> = async ({ effects }, { email }) => {
  const sendResult = await effects.api.sendResetPasswordInstructions(email);
  return sendResult.success === true;
};

export const isResetPasswordTokenValid: Action<
  { resetPasswordToken: string },
  Promise<boolean>
> = async ({ effects }, { resetPasswordToken }) => {
  const checkResult = await effects.api.checkIfResetPasswordTokenValid(
    resetPasswordToken,
  );
  return checkResult.success === true;
};

export const resetPassword: Action<
  { resetPasswordData: ResetPasswordData },
  Promise<boolean>
> = async ({ effects }, { resetPasswordData }) => {
  const resetResult = await effects.api.resetPassword(resetPasswordData);
  return resetResult.success === true;
};

export const logout: Action<void, void> = ({ actions, state }) => {
  state.user.token = null;
  state.user.meta = null;
  actions.whitelabel.reset();
};
