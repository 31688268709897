import React from 'react';
import { View, StyleSheet } from 'react-native';
import QRReader from 'react-qr-reader';
import ScannerProps from './ScannerProps';

export default function Scanner({
  onScanned,
  onPermissionRequested,
}: ScannerProps) {
  const handlePermissionNotGranted = () => {
    onPermissionRequested(false);
  };

  const handlePermissionGranted = () => {
    onPermissionRequested(true);
  };
  return (
    <View style={[styles.fillSpace]}>
      <QRReader
        showViewFinder={false}
        style={QRReaderContainerStyles}
        onError={handlePermissionNotGranted}
        onScan={(result) => {
          if (result !== null) {
            onScanned(result);
          }
        }}
        onLoad={handlePermissionGranted}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  fillSpace: {
    flex: 1,
  },
});

const QRReaderContainerStyles = {
  overflow: 'hidden',
};
