import { DataLoadStatus } from '../types';

import { MyProfileMeta } from '../effects/api/types';

export type TemplateState = {
  templateId: string | null;
  topBanner: string | null;
  listTitle: string | null;
  profiles: { data: Array<MyProfileMeta>; status: DataLoadStatus };
};

export const state: TemplateState = {
  templateId: null,
  topBanner: null,
  listTitle: null,
  profiles: {
    data: [],
    status: DataLoadStatus.UNKNOWN,
  },
};
