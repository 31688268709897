import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import { Text, TextInput, ActivityIndicator } from 'react-native-paper';

import { useTranslation } from '../translations';
import ScreenWrapper from '../components/ScreenWrapper';
import Button from '../components/Button';
import { NavigationProp } from '../MainNavigator';
import Graphics from '../../assets/undraw_login.svg';
import { useStore, useActions } from '../store';
import WhitelabelFooter from '../components/Whitelabel/Footer';
import { stdAlert } from '../components/alert';

const testAccount = {
  email: '',
  password: '',
};
const isDevelopment = process.env.NODE_ENV === 'development';

type Props = {
  navigation: NavigationProp<'Welcome'>;
};

export default function WelcomeScreen({ navigation }: Props) {
  const texts = useTranslation('common', 'welcome', 'login');
  const [email, setEmail] = useState(isDevelopment ? testAccount.email : '');
  const [password, setPassword] = useState(
    isDevelopment ? testAccount.password : '',
  );
  const [isCheckingIfLoggedIn, setIsCheckingIfLoggedIn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const {
    user: { login: loginAction },
  } = useActions();
  const {
    user: { token },
  } = useStore();
  useEffect(() => {
    if (token !== null) {
      navigation.replace('Dashboard');
    }
    setIsCheckingIfLoggedIn(false);
  }, []);

  const handleSignUpPress = () => {
    alert(texts.welcome.noLinkSignUp);
  };

  const handleLoginPress = async () => {
    setIsLoading(true);
    const result = await loginAction({ email, password });
    setIsLoading(false);
    if (!result) {
      stdAlert(texts.login.invalidCredentials);
    } else {
      navigation.replace('Dashboard');
    }
  };

  const handleForgotPasswordPress = () => {
    navigation.navigate('ForgotPassword');
  };

  if (isCheckingIfLoggedIn) {
    return (
      <ScreenWrapper style={styles.wrapper}>
        <ActivityIndicator />
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper style={styles.wrapper}>
      <ScrollView
        style={styles.fullWidth}
        contentContainerStyle={styles.scrollViewContentContainerStyles}
      >
        <View style={styles.content}>
          <View style={styles.welcomeTextWrapper}>
            <Text style={styles.welcomeText}>{texts.welcome.welcomeText}</Text>
            <Text style={[styles.welcomeText, styles.loginText]}>
              {texts.welcome.loginToYourAccount}
            </Text>
          </View>
          <View style={styles.graphicsWrapper}>
            <Graphics width={300} height={200} />
          </View>
          <View>
            <TextInput
              mode="outlined"
              value={email}
              onChangeText={setEmail}
              label={texts.login.emailLabel}
              textContentType="emailAddress"
              keyboardType="email-address"
              autoCapitalize="none"
              style={styles.inputHeight}
            />
            <TextInput
              mode="outlined"
              value={password}
              onChangeText={setPassword}
              textContentType="password"
              secureTextEntry={true}
              label={texts.login.passwordLabel}
              autoCapitalize="none"
              style={styles.input}
            />
            <TouchableWithoutFeedback onPress={handleForgotPasswordPress}>
              <Text style={styles.forgotPasswordLabel}>
                {texts.welcome.forgotPassword}
              </Text>
            </TouchableWithoutFeedback>
            <Button
              loading={isLoading}
              mode="contained"
              onPress={handleLoginPress}
              style={styles.button}
            >
              {texts.common.login}
            </Button>
          </View>
          <View style={styles.orDividerWrapper}>
            <Text>{texts.common.orDivider}</Text>
          </View>
          <Button mode="outlined" onPress={handleSignUpPress}>
            {texts.common.signUp}
          </Button>
        </View>
        <WhitelabelFooter />
      </ScrollView>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollViewContentContainerStyles: {
    flexGrow: 1,
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  content: {
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  welcomeTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  welcomeText: {
    fontSize: 16,
    lineHeight: 25,
  },
  loginText: {
    opacity: 0.8,
  },
  inputHeight: {
    maxHeight: 56,
  },
  input: {
    maxHeight: 56,
    marginTop: 20,
  },
  graphicsWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  button: {
    marginTop: 20,
  },
  orDividerWrapper: {
    paddingVertical: 8,
    alignItems: 'center',
  },
  forgotPasswordLabel: {
    opacity: 0.8,
    fontSize: 12,
    textAlign: 'center',
    textDecorationLine: 'underline',
    alignSelf: 'center',
    marginVertical: 10,
  },
});
