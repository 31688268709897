import React, { useEffect } from 'react';
import { nonGoogleFonts } from '../../theming';

import * as templates from './templates';

import { Props } from './index';

export default function StyledWebView({
  html,
  font,
  width,
  colors,
  onLoad,
}: Props) {
  useEffect(() => {
    const headTag = document.querySelector('head');
    const fontsLink = document.createElement('link');
    fontsLink.setAttribute('rel', 'stylesheet');
    if (!nonGoogleFonts.includes(font)) {
      fontsLink.setAttribute('href', templates.googleFontLink(font as string));
    }
    const styles = document.createElement('style');
    styles.innerHTML = templates.globalStyles({
      width,
      fontFamily: font,
      backgroundColor: colors?.background,
      textColor: colors?.text,
    });

    headTag?.appendChild(fontsLink);
    headTag?.appendChild(styles);

    if (onLoad) {
      onLoad();
    }
    return () => {
      headTag?.removeChild(fontsLink);
      headTag?.removeChild(styles);
    };
  }, []);
  return (
    <div
      style={{ width: '100%' }}
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
}
