import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Corner } from './Corner';

type Props = {
  children?: React.ReactNode;
  hideCorners: boolean;
};

export default function ScannerFrame({ children, hideCorners }: Props) {
  return (
    <View style={styles.frameSize}>
      {children}
      {!hideCorners ? (
        <>
          <Corner position="top-right" />
          <Corner position="top-left" />
          <Corner position="bottom-right" />
          <Corner position="bottom-left" />
        </>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  frameSize: {
    width: '100%',
    height: '55%',
  },
});
