import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { useTranslation as useTranslationLib } from 'react-i18next';

import en from './en';

export type Translations = typeof en;

export const fallbackLng = 'en';
export const resources = {
  en: {
    translation: en,
  },
};

const i18nSettings = {
  lng: 'en',
  fallbackLng: 'en',
  resources,
  debug: __DEV__,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  returnObjects: true,
};

i18n.use(initReactI18next).init(i18nSettings);

export const useTranslation = <T extends keyof Translations>(
  ...screens: Array<T>
) => {
  const { t } = useTranslationLib();
  const translations = {} as Translations;
  screens.forEach((key) => {
    translations[key] = t(key);
  });
  return translations as Omit<Translations, Exclude<keyof Translations, T>>;
};

export default i18n;
