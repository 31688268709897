import { Action } from 'overmind';
import {
  OnboardingUserAccountData,
  InvitationData,
} from '../effects/api/types';

export const getInvitationData: Action<
  string,
  Promise<InvitationData | undefined>
> = async ({ effects }, invitationHash) => {
  const invitationData = await effects.api.getInvitationData(invitationHash);
  if (invitationData.success) {
    return invitationData.success.data;
  }
  return undefined;
};

export const registerUser: Action<
  { userData: OnboardingUserAccountData; invitationHash: string },
  Promise<boolean>
> = async ({ state, effects }, { userData, invitationHash }) => {
  const createAccountResult = await effects.api.createUserAccount(
    userData,
    invitationHash,
  );
  if (createAccountResult.success) {
    const { profile_id: profileID, token } = createAccountResult.success.data;
    state.user.token = token;
    state.profiles.mainProfileID = profileID;
    const markResult = await effects.api.markProfileAsFavourite(profileID);
    if (markResult.success === true) {
      return true;
    }
  }
  return false;
};
