import React, { useEffect, useState } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';

import ScreenWrapper from '../components/ScreenWrapper';
import Tiles from '../components/Tiles';

import { useTheme } from '../theming';
import { useStore, useActions } from '../store';
import { NavigationProp } from '../MainNavigator';
import { useTranslation } from '../translations';
import useAppSize from '../hooks/useAppSize';
import UserImage from '../components/UserImage';
import DashboardDetailsItem from '../components/DashboardDetailsItem';
import SocialMediaIcon from '../components/SocialMediaIcon';
import WhitelabelFooter from '../components/Whitelabel/Footer';

type Props = {
  navigation: NavigationProp<'Dashboard'>;
};

const isTruthy = (value: string | null | undefined) => Boolean(value);

export default function Dashboard({ navigation }: Props) {
  const texts = useTranslation('common', 'dashboard', 'homeScreen');
  const { spacings, colors } = useTheme();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const {
    user: { meta, token },
    whitelabel,
  } = useStore();
  const { user } = useActions();
  const appSize = useAppSize();

  useEffect(() => {
    (async () => {
      if (token !== null) {
        const result = await user.fetchAndSetUserAccountData();
        if (result) {
          setIsUserLoggedIn(true);
          return;
        }
      }
      setIsUserLoggedIn(false);
      navigation.navigate('Welcome');
    })();
  }, []);

  function getImageSize() {
    return (appSize.width - 2 * spacings.content.horizontal) * 0.5;
  }

  const imageSize = getImageSize();

  return (
    <ScreenWrapper style={styles.container}>
      {isUserLoggedIn ? (
        <ScrollView
          style={styles.fullWidth}
          contentContainerStyle={styles.scrollViewContentContainerStyles}
        >
          <View style={styles.photoInfoWrapper}>
            <View style={styles.accountNameWrapper}>
              <View style={styles.placeholder} />
              <Text style={styles.accountNameText}>
                {meta?.name} {meta?.last_name}
              </Text>
              <View style={styles.accountDetailsWrapper}>
                <DashboardDetailsItem icon="email" detailsText={meta?.email} />
                <DashboardDetailsItem icon="phone" detailsText={meta?.phone} />
                <DashboardDetailsItem icon="web" detailsText={meta?.website} />
                <View style={styles.socialMediaIconWrapper}>
                  {isTruthy(meta?.social_facebook) ? (
                    <SocialMediaIcon
                      style={styles.socialMediaIcon}
                      name="facebook"
                    />
                  ) : null}
                  {isTruthy(meta?.social_instagram) ? (
                    <SocialMediaIcon
                      style={styles.socialMediaIcon}
                      name="instagram"
                    />
                  ) : null}
                  {isTruthy(meta?.social_youtube) ? (
                    <SocialMediaIcon
                      style={styles.socialMediaIcon}
                      name="youtube"
                    />
                  ) : null}
                  {isTruthy(meta?.social_twitter) ? (
                    <SocialMediaIcon
                      style={styles.socialMediaIcon}
                      name="twitter"
                    />
                  ) : null}
                  {isTruthy(meta?.social_linkedin) ? (
                    <SocialMediaIcon
                      style={styles.socialMediaIcon}
                      name="linkedin"
                    />
                  ) : null}
                  {isTruthy(meta?.social_vimeo) ? (
                    <SocialMediaIcon
                      style={styles.socialMediaIcon}
                      name="vimeo"
                    />
                  ) : null}
                </View>
              </View>
              <View style={styles.whatDoYouWantWrapper}>
                <Text style={styles.whatDoYouWantText}>
                  {texts.homeScreen.whatDoYouWant}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.imageWrapper,
                { width: imageSize, height: imageSize },
              ]}
            >
              {meta !== null ? (
                <UserImage
                  picture={meta.picture_url}
                  video={meta.video}
                  videoClip={meta.video_clip_url}
                  mediaType={
                    (Boolean(meta.picture_url) && 'picture') ||
                    (Boolean(meta.video) && 'video') ||
                    (Boolean(meta.video_clip_url) && 'video_clip') ||
                    null
                  }
                />
              ) : null}
            </View>
          </View>
          <Tiles.Grid>
            <Tiles.Item
              style={{ backgroundColor: colors.primary }}
              textStyle={{ color: colors.black }}
              iconStyle={{ color: colors.black }}
              title={texts.dashboard.myProfiles}
              icon="account-card-details-outline"
              onPress={() => {
                navigation.navigate('MyProfiles');
              }}
            />
            <Tiles.Item
              title={texts.dashboard.editUserInfo}
              icon="account-edit"
              onPress={() => navigation.navigate('EditUserInfo')}
            />
            <Tiles.Item
              title={texts.dashboard.scan}
              icon="qrcode-scan"
              onPress={() => navigation.navigate('QRScanner')}
            />
            <Tiles.Item
              title={texts.homeScreen.setPhotoVideo}
              icon="camera-plus"
              onPress={() => navigation.navigate('ChangeUserPhotoVideo')}
            />
            <Tiles.Item
              title={texts.dashboard.savedProfiles}
              icon="account-check"
              onPress={() => navigation.navigate('SavedProfiles')}
            />
            <Tiles.Item
              title={texts.dashboard.scannedProfiles}
              icon="credit-card-scan-outline"
              onPress={() => navigation.navigate('ScannedProfiles')}
            />
          </Tiles.Grid>
          <WhitelabelFooter
            customPoweredByUrl={whitelabel.customPoweredByUrl}
          />
        </ScrollView>
      ) : (
        <View style={styles.loaderWrapper}>
          <ActivityIndicator />
        </View>
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  loaderWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  accountNameText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  imageWrapper: {
    paddingLeft: 8,
  },
  fullWidth: {
    width: '100%',
  },
  placeholder: {
    flex: 1,
  },
  whatDoYouWantText: {
    fontSize: 12,
  },
  whatDoYouWantWrapper: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  accountNameWrapper: {
    height: '100%',
  },
  photoInfoWrapper: {
    paddingVertical: 16,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  scrollViewContentContainerStyles: {
    flexGrow: 1,
  },
  accountDetailsWrapper: {
    paddingTop: 4,
  },
  socialMediaIcon: {
    marginHorizontal: 2,
  },
  socialMediaIconWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
  },
});
