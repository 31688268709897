import React, { useContext, useState } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';

import Graphics from '../../../../assets/undraw_change_user_info.svg';
import { EditableProfileDetails } from '../../../store/effects/api/types';
import { useTranslation } from '../../../translations';
import { DataLoadStatus } from '../../../store/types';
import ScreenWrapper from '../../../components/ScreenWrapper';
import { stdAlert } from '../../../components/alert';
import DataContext from '../DataContext';
import EditProfileInfoForm from './EditProfileInfoForm';

export default function EditProfileInfoScreen() {
  const { data, status, updateProfileData } = useContext(DataContext.Context);
  const texts = useTranslation('edit', 'errors');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (updatedProfileData: EditableProfileDetails) => {
    setIsProcessing(true);
    const result = await updateProfileData(updatedProfileData);
    setIsProcessing(false);
    if (result === true) {
      stdAlert(texts.edit.updateSuccess);
      return;
    }
    if (result === false) {
      stdAlert(texts.errors.unexpected);
      return;
    }
  };

  return (
    <ScreenWrapper style={styles.container} withKeyboardAwareScrollView>
      {status === DataLoadStatus.SUCCESS && data !== null ? (
        <ScrollView>
          <View style={styles.instructionWrapper}>
            <Text>{texts.edit.editProfileInfo}</Text>
          </View>
          <View style={styles.graphicsWrapper}>
            <Graphics width={200} height={150} />
          </View>
          <View>
            <EditProfileInfoForm
              isProcessing={isProcessing}
              onSubmit={handleSubmit}
              initialProfileData={data}
            />
          </View>
        </ScrollView>
      ) : status === DataLoadStatus.ERROR ? (
        <Text>{texts.errors.unexpected}</Text>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  graphicsWrapper: {
    alignItems: 'center',
  },
  instructionWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
});
