import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { NavigationContext } from '@react-navigation/native';

import { NavigationProp } from '../index';
import { stdAlert } from '../../../components/alert';
import { openLink } from '../../../utils/buttonActions';
import { ButtonDataType } from '../../../store/effects/api/types';
import ButtonBase from './ButtonBase';

type Props = {
  background_type: string | null;
  background_url: string | null;
  button_background_color: string | null;
  button_event?: {
    ical_file_url: string | null;
  };
  label: string | null;
  data_type: ButtonDataType | null;
  data: string | null;
  index: number;
  style?: StyleProp<ViewStyle>;
};

class Button extends React.PureComponent<Props> {
  static contextType = NavigationContext;
  context!: React.ContextType<typeof NavigationContext>;

  comingSoon = () => stdAlert('Coming Soon!');

  getHandler = () => {
    const { button_event, data_type, data, index } = this.props;
    const navigation = this.context as NavigationProp<'DBC-Home'>;
    switch (data_type) {
      case 'scanner':
        return () => navigation.navigate('QRScanner');
      case 'social':
        return () =>
          navigation.navigate('DBC-SocialMedia', { buttonIndex: index });
      case 'gallery':
        return () => navigation.navigate('DBC-Gallery', { buttonIndex: index });
      case 'information':
        return () =>
          navigation.navigate('DBC-InfoPage', {
            buttonIndex: index,
          });
      case 'sub_page':
        return () =>
          navigation.navigate('DBC-SubPage', {
            buttonIndex: index,
          });
      case 'survey':
        return () => {
          navigation.navigate('DBC-Survey', { buttonIndex: index });
        };
      case 'contact':
        return () => {
          navigation.navigate('DBC-ContactPage', { buttonIndex: index });
        };
      case 'description':
        return () => {
          navigation.navigate('DBC-Description', { buttonIndex: index });
        };
    }

    // Other types require to have data defined
    if (data === null) {
      return this.comingSoon;
    }

    switch (data_type) {
      case 'url': {
        /* TODO: Since client said that we have to remove `profile` button, because users can use website url button for that,
          would be good to handle that situation and make navigation in the app instead of opening the link
          but ont the other hand, if we setup linking correctly, navigation should handle that well, without additional effort.
          If we decide to handle navigation, we should use navigation.push instead of navigation.navigate, 
          otherwise it will just replace route param and if user go back, he will return to profile dashboard instead of previously displayed profile.
        */
        return () => openLink(data);
      }
      case 'email':
        return () => openLink(`mailto:${data}`);
      case 'phone':
        return () => openLink(`tel:${data}`);
      case 'sms':
        return () => openLink(`sms:${data}`);
      case 'location':
        return () => openLink(`http://maps.google.com/maps?q=${data}`);
      case 'pdf':
        return () => openLink(data);
      case 'date':
        return () => openLink(button_event?.ical_file_url as string);
      case 'profile':
        return () => {
          // we need to push to create a new screen, otherwise we will just replace params of existing screen
          navigation.push('DigitalBusinessCardStack', {
            profileID: parseInt(data),
          });
        };
      default:
        return this.comingSoon;
    }
  };

  render() {
    const {
      background_url,
      label,
      style,
      button_background_color,
      background_type,
    } = this.props;
    return (
      <ButtonBase
        backgroundUrl={background_url}
        backgroundColor={
          background_type === '0' ? null : button_background_color
        }
        label={label}
        onPress={this.getHandler()}
        style={style}
      />
    );
  }
}
export default Button;
