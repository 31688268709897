import React, { useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import { RouteProp as NavigationRouteProp } from '@react-navigation/core';

import { Platform } from 'react-native';
import {
  DashboardScreen,
  SurveyReportsScreen,
  ScanReportsScreen,
  QRScannerScreen,
  OnboardingScreen,
  WelcomeScreen,
  ProfileScreen,
  MyProfilesListScreen,
  SavedProfilesListScreen,
  InvitationsScreen,
  EditUserInfoScreen,
  ChangeUserAccountPhotoVideoScreen,
  ScannedProfilesListScreen,
  DigitalBusinessCardScreen,
  SharedProfileScreen,
  NotFoundScreen,
  ForgotPasswordScreen,
  TemplateProfiles,
} from './screens';

import {
  navigationRef as globalNavigationRef,
  isAppMountedRef,
} from './globalNavigate';
import { useTheme, ThemeProvider } from './theming';
import LogoutButton from './components/LogoutButton';
import Icon from './components/Icon';
import { useStore, useActions } from './store';

export type MainNavigatorParams = {
  Dashboard: undefined;
  MyProfiles: undefined;
  TemplateProfiles: {
    templateId: string;
  };
  SavedProfiles: undefined;
  ScannedProfiles: undefined;
  QRScanner: undefined;
  SurveyReports: undefined;
  ScanReports: undefined;
  Login: undefined;
  DigitalBusinessCardStack: {
    profileID: number;
  };
  ProfileStack: {
    profileID: number;
  };
  ForgotPassword: undefined;
  OnboardingStack: {
    invitationHash: string;
  };
  Invitations: {
    invitationHash: string;
  };
  SharedProfile: {
    uuid: string;
  };
  Welcome: undefined;
  EditUserInfo: undefined;
  ChangeUserPhotoVideo: undefined;
  NotFound: undefined;
};

const DBCScreensConfig = {
  'DBC-Home': '',
  'DBC-Survey': {
    path: 'survey',
    parse: { buttonIndex: Number },
  },
  'DBC-QRCode': 'qr-code',
  'DBC-Gallery': {
    path: 'gallery',
    parse: { buttonIndex: Number },
  },
  'DBC-InfoPage': {
    path: 'information',
    parse: { buttonIndex: Number },
  },
  'DBC-SubPage': { path: 'sub-page', parse: { buttonIndex: Number } },
  'DBC-SocialMedia': 'social-media',
  'DBC-ContactPage': {
    path: 'contact-page',
    parse: { buttonIndex: Number },
  },
  'DBC-Phone': {
    path: 'phone',
    parse: { buttonIndex: Number },
  },
  'DBC-Email': {
    path: 'email',
    parse: { buttonIndex: Number },
  },
  'DBC-Website': { path: 'website', parse: { buttonIndex: Number } },
  'DBC-PDF': { path: 'pdf', parse: { buttonIndex: Number } },
  'DBC-Date': { path: 'date', parse: { buttonIndex: Number } },
  'DBC-Description': { path: 'description', parse: { buttonIndex: Number } },
};

const linking = {
  prefixes: [
    'https://app.thetagnetwork.com/',
    'http://35.203.144.40:3001/',
    'http://35.203.144.40:3000/',
    'https://the-tag-network-react-native.herokuapp.com/',
    'exps://the-tag-network-react-native.herokuapp.com/',
    'http://the-tag-network-react-native.herokuapp.com/',
    'http://app.thetagnetwork.com/',
    'exps://app.thetagnetwork.com/',
    'exp://app.thetagnetwork.com/',
    'exp://expo.io/@callstack-test/tag-mobile-app/--/',
    // dev ios
    'exp://127.0.0.1:19000/--/',
    // dev android - your IP will be different
    'exp://192.168.43.36:19000/--/',
    'exp://192.168.1.4:19000/--/',
    'exp://192.168.0.129:19000/--/',
  ],
  config: {
    screens: {
      ForgotPassword: {
        path: 'account/password',
        screens: {
          ForgotPasswordHome: '',
          ResetPassword: '/reset',
        },
      },
      Dashboard: 'dashboard',
      Profile: 'profile',
      MyProfiles: 'my-profiles',
      TemplateProfiles: 'template-profiles/:templateId',
      SharedProfile: 'profiles/share',
      SavedProfiles: 'saved-profiles',
      ScannedProfiles: 'scanned-profiles',
      Invitations: 'invitations/:invitationHash',
      OnboardingStack: {
        path: 'onboarding/:invitationHash',
        screens: {
          OnboardingWelcome: '',
          SignUp: 'sign-up',
          PhotoVideo: 'photo-video',
        },
      },
      EditUserInfo: 'edit',
      QRScanner: 'qr-scanner',
      SurveyReports: 'reports/survey',
      ScanReports: 'reports/scan',
      DigitalBusinessCardStack: {
        initialRouteName: Platform.OS !== 'web' ? 'DBC-Home' : undefined,
        path: 'profile-preview/:profileID',
        parse: {
          profileID: Number,
        },
        screens: {
          ...DBCScreensConfig,
        },
      },
      ProfileStack: {
        initialRouteName: 'ProfileHome',
        path: 'profile/:profileID',
        parse: {
          profileID: Number,
        },
        screens: {
          ProfileHome: '',
          EditProfileInfo: 'edit',
          ChangeProfilePhotoVideo: 'change-photo-video',
          EditProfileButtonsStack: {
            initialRouteName: 'EditProfileButtonsHome',
            path: 'edit-buttons',
            screens: {
              EditProfileButtonsHome: '',
              EditEmailButtons: 'email',
              EditPhoneButtons: 'phone',
              EditWebsiteButtons: 'web',
              EditPDFButtons: 'pdf',
              EditSurveyButtons: 'survey',
              EditReferralButtons: 'referral',
              EditDescriptionButtons: 'description',
            },
          },
        },
      },
      Welcome: 'welcome',
      ChangeUserPhotoVideo: 'change-photo-video',
      NotFound: '*',
    },
  },
};

export type Screens = keyof MainNavigatorParams;

export type NavigationProp<S extends Screens> = StackNavigationProp<
  MainNavigatorParams,
  S
>;

export type RouteProp<S extends Screens> = NavigationRouteProp<
  MainNavigatorParams,
  S
>;

export type ScreenProps<S extends Screens> = {
  navigation: NavigationProp<S>;
  route: RouteProp<S>;
};

const Stack = createStackNavigator<MainNavigatorParams>();

/**
 * Not that we cannot easily use declarative routing, because we need to implement universal linking
 * It will be easier to manipulate navigation state imperatively
 */

function MainNavigator() {
  const theme = useTheme();
  const {
    user: { token, meta },
    whitelabel,
  } = useStore();
  const { profiles } = useActions();
  const isUserLoggedIn = token !== null;
  useEffect(() => {
    isAppMountedRef.current = true;

    return () => {
      isAppMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isUserLoggedIn) {
      const favouriteProfileID = meta?.favorite_profile_id;
      if (typeof favouriteProfileID === 'number') {
        profiles.fetchAndSetProfileBranding(favouriteProfileID);
      }
    }
  }, [token, meta?.favorite_profile_id]);

  const appLinking = isUserLoggedIn
    ? {
        ...linking,
        config: { ...linking.config, initialRouteName: 'Dashboard' },
      }
    : linking;
  return (
    <NavigationContainer linking={appLinking} ref={globalNavigationRef}>
      <ThemeProvider
        theme={{
          ...theme,
          colors: {
            ...theme.colors,
            primary:
              typeof whitelabel.whiteLabelPrimaryColor === 'string'
                ? whitelabel.whiteLabelPrimaryColor
                : theme.colors.primary,
          },
        }}
      >
        <Stack.Navigator
          screenOptions={{
            headerBackTitleVisible: false,
            headerShown: true,
            headerStyle: {
              borderBottomWidth: 0, // removes bottom border from header on the web
              backgroundColor: theme.colors.black,
              shadowColor: 'transparent', // removes bottom border from header on IOS
            },
            headerTintColor: theme.colors.text,
          }}
        >
          <Stack.Screen
            name="Welcome"
            options={{ headerShown: false }}
            component={WelcomeScreen}
          />
          <Stack.Screen
            name="Dashboard"
            component={DashboardScreen}
            options={{
              headerTitle: 'Home',
              headerTitleAlign: 'left',
              headerRight: () => <LogoutButton />,
            }}
          />
          <Stack.Screen
            name="ForgotPassword"
            options={{ headerShown: false }}
            component={ForgotPasswordScreen}
          />
          <Stack.Screen
            name="EditUserInfo"
            options={{
              title: 'Edit user info',
            }}
            component={EditUserInfoScreen}
          />
          <Stack.Screen
            name="ChangeUserPhotoVideo"
            options={{
              headerTitle: 'User Photo/Video',
            }}
            component={ChangeUserAccountPhotoVideoScreen}
          />
          <Stack.Screen
            name="Invitations"
            options={{ headerShown: false }}
            component={InvitationsScreen}
          />
          <Stack.Screen
            name="ProfileStack"
            options={{ headerShown: false }}
            component={ProfileScreen}
          />
          <Stack.Screen
            name="DigitalBusinessCardStack"
            options={{ headerShown: false }}
            component={DigitalBusinessCardScreen}
          />
          <Stack.Screen
            name="SharedProfile"
            options={{ headerShown: false }}
            component={SharedProfileScreen}
          />
          <Stack.Screen
            name="MyProfiles"
            options={{
              headerTitle: 'My profiles',
            }}
            component={MyProfilesListScreen}
          />
          <Stack.Screen
            name="TemplateProfiles"
            options={{ headerShown: false }}
            component={TemplateProfiles}
          />
          <Stack.Screen
            name="SavedProfiles"
            options={{
              headerRightContainerStyle: {
                flexDirection: 'row',
                alignItems: 'center',
              },
              headerTitle: 'Saved profiles',
            }}
            component={SavedProfilesListScreen}
          />
          <Stack.Screen
            name="ScannedProfiles"
            options={{
              headerTitle: 'Scanned profiles',
            }}
            component={ScannedProfilesListScreen}
          />
          <Stack.Screen
            name="OnboardingStack"
            options={{ headerShown: false }}
            component={OnboardingScreen}
          />
          <Stack.Screen
            name="QRScanner"
            options={{
              headerTitle: 'Scanner',
              headerBackImage: ({ tintColor }) => (
                <Icon
                  name="close"
                  color={tintColor}
                  size={22}
                  style={Platform.select({
                    ios: { padding: 8 },
                    web: { padding: 8 },
                  })}
                />
              ),
            }}
            component={QRScannerScreen}
          />
          <Stack.Screen name="SurveyReports" component={SurveyReportsScreen} />
          <Stack.Screen name="ScanReports" component={ScanReportsScreen} />
          <Stack.Screen
            name="NotFound"
            options={{ headerShown: false }}
            component={NotFoundScreen}
          />
        </Stack.Navigator>
      </ThemeProvider>
    </NavigationContainer>
  );
}

export default MainNavigator;
