import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { TextInput } from 'react-native-paper';

import { useTranslation } from '../../translations';
import Button from '../../components/Button';
import { stdAlert } from '../../components/alert';
import { UserPasswordData } from '../../store/effects/api/types';

type Props = {
  isProcessing: boolean;
  onSubmit: (userPasswordData: UserPasswordData) => Promise<boolean>;
};

export default function PasswordForm({ isProcessing, onSubmit }: Props) {
  const texts = useTranslation('forms', 'edit');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  function validateRequiredFields() {
    return (
      password.trim().length !== 0 &&
      passwordConfirmation.trim().length !== 0 &&
      currentPassword.trim().length !== 0
    );
  }

  function resetFields() {
    setPassword('');
    setPasswordConfirmation('');
    setCurrentPassword('');
  }

  function validatePasswordConfirmation() {
    return passwordConfirmation === password;
  }

  const handleSubmit = async () => {
    if (!isProcessing) {
      const areRequiredFieldsFilled = validateRequiredFields();
      const arePasswordsTheSame = validatePasswordConfirmation();
      if (!areRequiredFieldsFilled) {
        stdAlert(texts.forms.fillAllFields);
        return;
      }

      if (!arePasswordsTheSame) {
        stdAlert(texts.forms.passwordsNotMatch);
        return;
      }
      const userPasswordData: UserPasswordData = {
        password,
        password_confirmation: passwordConfirmation,
        current_password: currentPassword,
      };
      const result = await onSubmit(userPasswordData);
      if (result === true) {
        resetFields();
      }
    }
  };

  return (
    <>
      <TextInput
        mode="outlined"
        value={password}
        onChangeText={setPassword}
        textContentType="oneTimeCode"
        secureTextEntry={true}
        label={texts.forms.newPasswordLabel}
        autoCapitalize="none"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={passwordConfirmation}
        onChangeText={setPasswordConfirmation}
        textContentType="oneTimeCode"
        secureTextEntry={true}
        label={texts.forms.newPasswordConfirmLabel}
        autoCapitalize="none"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={currentPassword}
        onChangeText={setCurrentPassword}
        textContentType="oneTimeCode"
        secureTextEntry={true}
        label={texts.forms.currentPasswordLabel}
        autoCapitalize="none"
        style={styles.input}
      />
      <Button
        loading={isProcessing}
        mode="contained"
        onPress={handleSubmit}
        style={styles.button}
      >
        {texts.edit.save}
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  input: {
    marginVertical: 5,
  },
  button: {
    marginTop: 20,
  },
});
