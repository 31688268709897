import React, { useState } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Text } from 'react-native-paper';
import * as DocumentPicker from 'expo-document-picker';

import { useTheme } from '../theming';
import IconButton from '../components/IconButton';
import Button from '../components/Button';
import { useTranslation } from '../translations';

export type FileUploaded = {
  name: string;
  uri: string;
};

type Props = {
  onChooseFile: (file: FileUploaded) => void;
  onResetPress: () => void;
  type?: string;
  initialFile?: FileUploaded;
};

export default function ChooseFile({
  onChooseFile,
  onResetPress,
  type,
  initialFile,
}: Props) {
  const [file, setFile] = useState<FileUploaded | null>(initialFile ?? null);
  const texts = useTranslation('forms');
  const { colors } = useTheme();

  const handleOnChooseFilePress = async () => {
    const result = await DocumentPicker.getDocumentAsync(
      type !== undefined ? { type } : undefined,
    );
    if (result.type === 'success') {
      setFile(result);
      onChooseFile({
        uri: result.uri,
        name: result.name,
      });
    }
  };

  const handleOnResetFilePress = () => {
    setFile(null);
    onResetPress();
  };

  return (
    <View style={[styles.chooseFileWrapper, { borderColor: colors.text }]}>
      <Button mode="contained" onPress={handleOnChooseFilePress}>
        {texts.forms.chooseFile}
      </Button>
      <View style={styles.fileDescWrapper}>
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={file !== null ? styles.fileNameText : undefined}
        >
          {file !== null
            ? `${file.name.slice(0, file.name.lastIndexOf('.'))}`
            : null}
        </Text>
        <Text style={file === null ? styles.noFileChosenText : undefined}>
          {file !== null
            ? file.name.slice(file.name.lastIndexOf('.'))
            : texts.forms.noChosenFile}
        </Text>
        {file !== null ? (
          <IconButton
            icon="close"
            color={colors.text}
            onPress={handleOnResetFilePress}
          />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  noFileChosenText: {
    paddingLeft: Platform.OS === 'web' ? 16 : 0,
    paddingTop: Platform.OS === 'web' ? 0 : 8,
  },
  fileNameText: {
    paddingLeft: Platform.OS === 'web' ? 16 : 0,
    flexShrink: 1,
  },
  chooseFileWrapper: {
    marginTop: 8,
    flexDirection: Platform.OS === 'web' ? 'row' : 'column',
    alignItems: 'center',
    borderWidth: 1,
    padding: 16,
  },
  fileDescWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
