import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

import Graphics from '../../../../assets/undraw_login.svg';
import Tiles from '../../../components/Tiles';
import { useTranslation } from '../../../translations';
import PremiumFeatureGate from '../../../components/PremiumFeatureGate';
import DataContext from '../DataContext';
import EditButtonsScreenWrapper from './EditButtonsScreenWrapper';
import { NavigationProp } from './index';

type Props = {
  navigation: NavigationProp<'EditProfileButtonsHome'>;
};

const EDITABLE_BUTTONS = [
  'email',
  'phone',
  'survey',
  'referral',
  'url',
  'pdf',
] as const;

export default function EditProfileButtonsHomeScreen({ navigation }: Props) {
  const texts = useTranslation('editProfileButtons');
  const { hasPolicy } = useContext(DataContext.Context);

  return (
    <EditButtonsScreenWrapper>
      {(data) => {
        const existingButtons = EDITABLE_BUTTONS.reduce(
          (buttons, buttonType) => {
            if (buttons[buttonType] === true) {
              return buttons;
            } else {
              return {
                ...buttons,
                [buttonType]:
                  data.buttons.filter((button) => {
                    if (buttonType === 'referral') {
                      return (
                        button.data_type === 'survey' &&
                        button.survey?.is_referral === true
                      );
                    }
                    return button.data_type === buttonType;
                  }).length > 0,
              };
            }
          },
          {
            email: false,
            phone: false,
            survey: false,
            url: false,
            pdf: false,
            referral: false,
          },
        );
        return (
          <>
            <View style={styles.instructionWrapper}>
              <Text style={styles.instructionText}>
                {texts.editProfileButtons.editButtonsHomeInstruction}
              </Text>
            </View>
            <View style={styles.graphicsWrapper}>
              <Graphics width={300} height={200} />
            </View>
            <Tiles.Grid>
              {existingButtons.email === true ? (
                <Tiles.Item
                  title={texts.editProfileButtons.emailButton}
                  icon="email-edit"
                  onPress={() => {
                    navigation.navigate('EditEmailButtons');
                  }}
                />
              ) : null}
              {existingButtons.phone === true ? (
                <Tiles.Item
                  title={texts.editProfileButtons.phoneButton}
                  icon="contact-phone"
                  onPress={() => {
                    navigation.navigate('EditPhoneButtons');
                  }}
                />
              ) : null}
              {existingButtons.url === true ? (
                <Tiles.Item
                  title={texts.editProfileButtons.website}
                  icon="web"
                  onPress={() => {
                    navigation.navigate('EditWebsiteButtons');
                  }}
                />
              ) : null}
              {existingButtons.pdf === true ? (
                <Tiles.Item
                  title={texts.editProfileButtons.pdf}
                  icon="file-pdf"
                  onPress={() => {
                    navigation.navigate('EditPDFButtons');
                  }}
                />
              ) : null}
              {existingButtons.survey === true ? (
                <Tiles.Item
                  title={texts.editProfileButtons.survey}
                  icon="checkbox-multiple-marked"
                  onPress={() => {
                    navigation.navigate('EditSurveyButtons');
                  }}
                />
              ) : null}
              {existingButtons.referral ? (
                <Tiles.Item
                  title={texts.editProfileButtons.referral}
                  icon="account-arrow-right"
                  onPress={() => {
                    navigation.navigate('EditReferralButtons');
                  }}
                />
              ) : null}
              {
                <View style={styles.premiumGate}>
                  <PremiumFeatureGate
                    alwaysClickable={hasPolicy('update_description_button')}
                  >
                    <Tiles.Item
                      title={texts.editProfileButtons.description}
                      icon="account"
                      onPress={() => {
                        navigation.navigate('EditDescriptionButtons');
                      }}
                    />
                  </PremiumFeatureGate>
                </View>
              }
            </Tiles.Grid>
          </>
        );
      }}
    </EditButtonsScreenWrapper>
  );
}

const styles = StyleSheet.create({
  graphicsWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  instructionText: {
    opacity: 0.8,
    textAlign: 'center',
  },
  instructionWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  premiumGate: {
    borderRadius: 8,
    borderTopRightRadius: 4,
    overflow: 'hidden',
    flexDirection: 'row',
  },
});
