import React, { useContext, useEffect, useState } from 'react';
import { View, Image, StyleSheet, ScrollView } from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';

import ScreenWrapper from '../../components/ScreenWrapper';
import UserImage from '../../components/UserImage';
import useAppSize from '../../hooks/useAppSize';
import { useCompanyPositionLabel } from '../../hooks/useCompanyPositionLabel';
import { useTranslation } from '../../translations';
import { DataLoadStatus } from '../../store/types';
import { useTheme } from '../../theming';

import DataContext from '../Profile/DataContext';

export default function QRCodeScreen() {
  const { spacings } = useTheme();
  const texts = useTranslation('errors', 'common', 'share');
  const [barcodeUrl, setBarcodeUrl] = useState('');
  const [isLoadingBarcode, setIsLoadingBarcode] = useState(true);
  const { data, status, getProfileQRCodeUrl } = useContext(DataContext.Context);
  const appSize = useAppSize();
  const companyLabel = useCompanyPositionLabel({
    company: data?.company,
    profileRole: data?.profile_role,
  });

  useEffect(() => {
    if (status === DataLoadStatus.SUCCESS && data !== null) {
      (async () => {
        setIsLoadingBarcode(true);
        const response = await getProfileQRCodeUrl();
        if (response) {
          setBarcodeUrl(response.QRCodeUrl);
        }
        setIsLoadingBarcode(false);
      })();
    }
  }, [status, data]);

  const profileImageSize = appSize.width * 0.25;
  const QRCodeImageSize = appSize.width - 2 * spacings.content.horizontal;
  return (
    <ScreenWrapper noPadding style={styles.container}>
      {status === DataLoadStatus.SUCCESS &&
      data !== null &&
      isLoadingBarcode === false ? (
        <ScrollView
          style={styles.fullWidth}
          contentContainerStyle={styles.scrollViewContentContainerStyles}
        >
          <ScreenWrapper.Content>
            <View style={styles.profilePreviewWrapper}>
              <View
                style={{ width: profileImageSize, height: profileImageSize }}
              >
                <UserImage
                  picture={data.picture_url}
                  video={data.video}
                  videoClip={data.video_clip_url}
                  mediaType={data.picture_or_video}
                />
              </View>
              <View style={styles.profileDetailsWrapper}>
                <Text style={styles.profileTitleText}>{data.title}</Text>
                {companyLabel !== null ? (
                  <View style={styles.profileRoleWrapper}>
                    <Text style={styles.companyRoleText}>{companyLabel}</Text>
                  </View>
                ) : null}
              </View>
            </View>
            <View style={styles.instructionWrapper}>
              <Text style={styles.instructionText}>{texts.share.QRCode}</Text>
            </View>
            <View
              style={{
                width: QRCodeImageSize,
                height: QRCodeImageSize,
              }}
            >
              <Image
                style={styles.QRCodeimage}
                source={{
                  uri: barcodeUrl !== '' ? barcodeUrl : undefined,
                }}
                accessibilityIgnoresInvertColors
              />
            </View>
          </ScreenWrapper.Content>
        </ScrollView>
      ) : status === DataLoadStatus.ERROR ? (
        <View>
          <Text>{texts.errors.unexpected}</Text>
        </View>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: 24,
  },
  fullWidth: {
    width: '100%',
  },
  scrollViewContentContainerStyles: {
    flexGrow: 1,
  },
  QRCodeimage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  profilePreviewWrapper: {
    flexDirection: 'row',
  },
  profileTitleText: {
    fontSize: 24,
  },
  profileDetailsWrapper: {
    paddingHorizontal: 16,
    justifyContent: 'center',
  },
  profileRoleWrapper: {
    paddingTop: 4,
  },
  companyRoleText: {
    fontSize: 14,
  },
  instructionWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 40,
  },
  instructionText: {
    opacity: 0.8,
    fontSize: 18,
    textAlign: 'center',
  },
});
