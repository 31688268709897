import React, { useEffect, useState } from 'react';
import { StyleProp, ImageStyle, StyleSheet, Image } from 'react-native';

import { Video } from 'expo-av';
import { getImageSrc } from './UserMedia/imageUtils';
// @ts-ignore
import defaultAvatarImg from './../../assets/avatar.png';

type Props = {
  style?: StyleProp<ImageStyle>;
  picture: string | null;
  video: string | null;
  videoClip: string | null;
  mediaType?: 'picture' | 'video' | 'video_clip' | null;
};

export default function UserImage({
  style,
  picture,
  video,
  videoClip,
  mediaType,
}: Props) {
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      switch (mediaType) {
        case 'picture':
          return setImgSrc(picture);
        case 'video': {
          const uri = await getImageSrc({
            pictureUrl: null,
            video,
            pictureOrVideo: 'video',
          });
          return Boolean(uri) && setImgSrc(uri!);
        }

        case 'video_clip':
          return setVideoSrc(videoClip);
      }
    })();
  }, [mediaType, picture, video, videoClip]);
  if (videoSrc !== null) {
    return (
      <Video
        source={{ uri: `${videoSrc}` }}
        rate={1.0}
        volume={1.0}
        isMuted
        resizeMode="cover"
        shouldPlay
        isLooping
        style={[styles.video, style]}
      />
    );
  }

  if (imgSrc !== null) {
    return (
      <Image
        source={{
          uri: `${imgSrc}?version=${new Date().getTime()}`,
        }} // weird chrome caching
        style={[styles.image, style]}
        accessibilityIgnoresInvertColors
      />
    );
  }

  return (
    <Image
      source={defaultAvatarImg}
      style={[styles.image, style]}
      accessibilityIgnoresInvertColors
    />
  );
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderRadius: 16,
  },
  video: {
    width: '100%',
    height: '100%',
    borderRadius: 16,
  },
});
