import React from 'react';
import {
  SafeAreaView,
  StyleProp,
  ViewStyle,
  StyleSheet,
  View,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useTheme } from '../theming';
import useWebWideScreenStyles from '../hooks/useWebWideScreenStyles';

type Props = {
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  noPadding?: boolean;
  withKeyboardAwareScrollView?: boolean;
};

function Wrapper({
  children,
  withKeyboardAwareScrollView,
}: {
  children: React.ReactNode;
  withKeyboardAwareScrollView: boolean;
}) {
  const { top, bottom } = useSafeAreaInsets();
  if (withKeyboardAwareScrollView) {
    return (
      <KeyboardAwareScrollView extraScrollHeight={top + bottom}>
        {children}
      </KeyboardAwareScrollView>
    );
  } else {
    return (
      <KeyboardAvoidingView
        style={styles.container}
        behavior={Platform.select({ ios: 'padding', android: undefined })}
      >
        {children}
      </KeyboardAvoidingView>
    );
  }
}

function ScreenWrapper({
  style,
  children,
  noPadding,
  withKeyboardAwareScrollView = false,
}: Props) {
  const { colors, spacings } = useTheme();
  const webStyles = useWebWideScreenStyles();

  return (
    <SafeAreaView
      style={[
        styles.container,
        { backgroundColor: colors.background },
        webStyles.container,
      ]}
    >
      <Wrapper withKeyboardAwareScrollView={withKeyboardAwareScrollView}>
        <View
          style={[
            { backgroundColor: colors.background },
            styles.content,
            noPadding !== true
              ? { paddingHorizontal: spacings.content.horizontal }
              : {},
            webStyles.content,
            style,
          ]}
        >
          {children}
        </View>
      </Wrapper>
    </SafeAreaView>
  );
}

ScreenWrapper.Content = function Content({
  children,
  style,
}: Omit<Props, 'noPadding'>) {
  const { spacings } = useTheme();

  return (
    <View
      style={[
        styles.content,
        { paddingHorizontal: spacings.content.horizontal },
        style,
      ]}
    >
      {children}
    </View>
  );
};

export default ScreenWrapper;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
});
