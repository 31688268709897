import React, { useState } from 'react';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { useTheme } from '../../../../theming';
import useAppSize from '../../../../hooks/useAppSize';
import AutoscaleImage from '../../../../components/AutoscaleImage';

import Modal from './Modal';

type GalleryImage = {
  image_url: string | null;
  id: number;
  caption: string | null;
};

type Props = {
  images: GalleryImage[] | undefined;
};

export default function Gallery({ images }: Props) {
  const { spacings } = useTheme();
  const appSize = useAppSize();
  const [imageToOpen, setImageToOpen] = useState<GalleryImage | null>(null);

  function getImageSize() {
    return (appSize.width - 2 * spacings.content.horizontal) * 0.5;
  }

  const imageSize = getImageSize();
  return (
    <View style={styles.container}>
      {images?.map((image) => (
        <TouchableOpacity
          onPress={() => setImageToOpen(image)}
          key={image.id}
          style={[styles.imageWrapper, { width: imageSize, height: imageSize }]}
        >
          <Image
            style={styles.image}
            source={{
              uri: image.image_url !== null ? image.image_url : undefined,
            }}
            accessibilityIgnoresInvertColors
          />
        </TouchableOpacity>
      ))}
      <Modal
        onHide={() => setImageToOpen(null)}
        isVisible={imageToOpen !== null}
      >
        <View style={styles.imageModalWrapper}>
          <AutoscaleImage
            uri={
              imageToOpen?.image_url !== null
                ? imageToOpen?.image_url
                : undefined
            }
          />
          {typeof imageToOpen?.caption === 'string' ? (
            <Text>{imageToOpen.caption}</Text>
          ) : null}
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderRadius: 16,
  },
  imageModalWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageWrapper: {
    padding: 8,
  },
});
