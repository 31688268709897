import React, { useEffect, CSSProperties } from 'react';
import { StyleSheet } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import useWebWideScreenStyles from '../../../../hooks/useWebWideScreenStyles';
import { Props } from './Modal';

export default function GalleryModal({ onHide, isVisible, children }: Props) {
  const webStyles = useWebWideScreenStyles();
  // prevent scrolling while modal is open
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isVisible]);

  const visibility = isVisible ? 'visible' : 'hidden';
  return (
    <Portal>
      <div style={{ ...modalWrapperStyles, visibility }}>
        <Modal
          visible={isVisible}
          contentContainerStyle={
            webStyles.content === undefined
              ? [
                  styles.modalContentContainer,
                  { width: '80vw', maxHeight: '80vw' },
                ]
              : styles.modalContentContainer
          }
          onDismiss={onHide}
        >
          {children}
        </Modal>
      </div>
    </Portal>
  );
}

const modalWrapperStyles: CSSProperties = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const styles = StyleSheet.create({
  modalContentContainer: {
    width: '40vw',
    maxHeight: '40vw',
    elevation: 0,
    alignSelf: 'center',
  },
});
