import React, { useState, useEffect, useRef } from 'react';
import {
  StyleSheet,
  Platform,
  View,
  TextInput as RNTextInput,
  TouchableOpacity,
} from 'react-native';
import { Text, TextInput, IconButton } from 'react-native-paper';
import { Video } from 'expo-av';
import Constants from 'expo-constants';
import * as ImagePicker from 'expo-image-picker';

import Button from '../../../../components/Button';
import { useTranslation } from '../../../../translations';
import { checkCameraRollPermissionForIOS } from '../../../../components/UserMedia/imageUtils';
import { getVideoThumbnail } from '../../../../components/UserMedia/videoUtils';
import AutoscaleImage from '../../../../components/AutoscaleImage';
import { binaryAlert } from '../../../../components/alert';
import { createLocalIdGenerator } from '../../../../utils/createLocalIdGenerator';
import {
  DescriptionDetails,
  DescriptionDataType,
} from '../../../../store/effects/api/types';
import { useTheme } from '../../../../theming';

type Element = {
  id: number | null;
  data_type: DescriptionDataType;
  data: string | null;
  localId: number;
  order: number | null;
  edited: boolean;
};

type Section = {
  label: string;
  isSelected: boolean;
  onPress: () => void;
  children: React.ReactNode;
};

type Props = {
  isProcessing: boolean;
  initialDescriptionData: DescriptionDetails | null;
  onSubmit: (sendData: DescriptionDetails) => void;
};

const VideoURLPreview = ({ link }: { link: string }) => {
  const [imgPreview, setImagePreview] = useState<string | null>(null);

  useEffect(() => {
    const getVideoURLPreview = async () => {
      const thumbnail = await getVideoThumbnail(link);

      if (thumbnail !== '') {
        setImagePreview(thumbnail);
      } else {
        setImagePreview(null);
      }
    };

    getVideoURLPreview();
  }, [link]);

  if (imgPreview === null) {
    return null;
  }

  return (
    <AutoscaleImage
      uri={imgPreview}
      style={styles.mediaPreview}
      resizeMode="contain"
    />
  );
};

const Section = ({ label, isSelected, onPress, children }: Section) => {
  return (
    <View style={[styles.spacing, isSelected && styles.selected]}>
      <View style={styles.sectionHeader}>
        <Text>{label}</Text>
        <IconButton icon="trash-can" onPress={onPress} />
      </View>
      {children}
    </View>
  );
};

const generateLocalId = createLocalIdGenerator();

export default function EditDescriptionsForm({
  isProcessing,
  initialDescriptionData,
  onSubmit,
}: Props) {
  const texts = useTranslation(
    'editProfileButtons',
    'userMedia',
    'editDescriptionButtons',
  );
  const { colors } = useTheme();

  const inputRefs = useRef<{ [key: string]: RNTextInput | null }>({});

  const [hasPermission, setHasPermission] = useState<boolean | null>(
    Constants?.platform?.ios !== undefined ? null : true,
  );

  const [elements, setElements] = useState<Element[]>(
    initialDescriptionData?.elements
      ?.map((element) => {
        return { ...element, localId: generateLocalId(), edited: false };
      })
      .sort((a, b) => {
        return a.order - b.order;
      }) ?? [],
  );
  const [selectedElement, setSelectedElement] = useState<number | null>(null);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const editElement = (itemId: number, data: string) => {
    const editedElements = elements.map((element) => {
      if (element.localId === itemId) {
        return { ...element, data, edited: true };
      }
      return element;
    });

    setElements(editedElements);
  };

  const handleChangeVideoLink = async (itemId: number, video_url: string) => {
    editElement(itemId, video_url);
  };

  const handleAddingPhoto = async (itemId: number) => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );

    if (grantedPermission === true) {
      setHasPermission(true);
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.cancelled) {
        editElement(itemId, result.uri);
      }
    } else {
      setHasPermission(false);
    }
  };

  const handleAddingVideo = async (itemId: number) => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission === true) {
      setHasPermission(true);
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Videos,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.cancelled) {
        editElement(itemId, result.uri);
      }
    } else {
      setHasPermission(false);
    }
  };

  const onButtonPress = (type: DescriptionDataType) => {
    setElements([
      ...elements,
      {
        localId: generateLocalId(),
        data_type: type,
        data: null,
        id: null,
        order: null,
        edited: true,
      },
    ]);
  };

  const onDeletePress = (item: Element) => {
    setSelectedElement(item.localId);
    // It occurs that displaying an alert on web is blocking
    // React render changes, hence there is a need to wrap it in
    // `setTimeout`.
    setTimeout(() => {
      binaryAlert(
        texts.editDescriptionButtons.removeSectionQuestion,
        '',
        () => {
          setElements(
            elements.filter((element) => element.localId !== item.localId),
          );
          setSelectedElement(null);
        },
        () => {
          setSelectedElement(null);
        },
      );
    }, 0);
  };

  const handleOnSavePress = () => {
    const sendData = {
      id: initialDescriptionData?.id ?? null,
      elements: elements.filter((element) => {
        return element.data !== null;
      }),
    };

    onSubmit(sendData as DescriptionDetails);
  };

  const getActionButton = (
    position: 'left' | 'right',
    type: DescriptionDataType,
    label: string,
  ) => {
    const isLeft = position === 'left';

    return (
      <View
        style={isLeft ? styles.leftButtonWrapper : styles.rightButtonWrapper}
      >
        <Button
          mode="contained"
          onPress={() => onButtonPress(type)}
          labelStyle={{ color: colors.black }}
        >
          {label}
        </Button>
      </View>
    );
  };

  return (
    <>
      <View style={styles.buttonsRow}>
        {getActionButton('left', 'text', texts.editDescriptionButtons.addText)}
        {getActionButton(
          'right',
          'video_url',
          texts.editDescriptionButtons.insertLink,
        )}
      </View>
      <View style={[styles.buttonsRow, styles.spacing]}>
        {getActionButton(
          'left',
          'photo',
          texts.editDescriptionButtons.addPhoto,
        )}
        {getActionButton(
          'right',
          'video',
          texts.editDescriptionButtons.addVideo,
        )}
      </View>
      <View>
        {elements.map((item) => {
          const isSelected = selectedElement === item.localId;
          const isWeb = Platform.OS === 'web';

          if (item.data_type === 'text') {
            const textArea = (
              <TextInput
                mode="outlined"
                multiline
                value={item.data ?? ''}
                onChangeText={(value) => editElement(item.localId, value)}
                placeholder={texts.editProfileButtons.descriptionPlaceholder}
                numberOfLines={isWeb ? 10 : 1}
                style={!isWeb && styles.descriptionInput}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
                key={item.localId}
                ref={(e) => (inputRefs.current[item.localId] = e)}
              />
            );

            return (
              <Section
                isSelected={isSelected}
                key={item.localId}
                onPress={() => onDeletePress(item)}
                label={texts.editProfileButtons.description}
              >
                {isWeb ? (
                  textArea
                ) : (
                  <TouchableOpacity
                    activeOpacity={1}
                    onPress={() => inputRefs.current[item.localId]?.focus()}
                  >
                    <View pointerEvents={!isInputFocused ? 'none' : 'auto'}>
                      {textArea}
                    </View>
                  </TouchableOpacity>
                )}
              </Section>
            );
          } else if (item.data_type === 'video_url') {
            return (
              <Section
                isSelected={isSelected}
                key={item.localId}
                onPress={() => onDeletePress(item)}
                label={texts.userMedia.insertLink}
              >
                {item.data !== null && <VideoURLPreview link={item.data} />}
                <TextInput
                  mode="outlined"
                  value={item.data ?? ''}
                  placeholder={
                    texts.editDescriptionButtons.insertLinkPlaceholder
                  }
                  onChangeText={(value) =>
                    handleChangeVideoLink(item.localId, value)
                  }
                  style={!isWeb && styles.linkInput}
                  dense
                />
              </Section>
            );
          } else if (item.data_type === 'photo') {
            return (
              <Section
                isSelected={isSelected}
                key={item.localId}
                onPress={() => onDeletePress(item)}
                label={texts.userMedia.uploadPhoto}
              >
                {hasPermission === false ? (
                  <Text>{texts.userMedia.noCameraPermission}</Text>
                ) : (
                  <View>
                    {item.data !== null && (
                      <AutoscaleImage
                        uri={item.data}
                        style={styles.mediaPreview}
                        resizeMode="contain"
                      />
                    )}
                    <Button
                      mode="outlined"
                      onPress={() => {
                        handleAddingPhoto(item.localId);
                      }}
                    >
                      {item.data !== null
                        ? texts.editDescriptionButtons.changePhoto
                        : texts.userMedia.uploadPhoto}
                    </Button>
                  </View>
                )}
              </Section>
            );
          } else if (item.data_type === 'video') {
            return (
              <Section
                isSelected={isSelected}
                key={item.localId}
                onPress={() => onDeletePress(item)}
                label={texts.userMedia.uploadVideo}
              >
                {hasPermission === false ? (
                  <Text>{texts.userMedia.noCameraPermission}</Text>
                ) : (
                  <View>
                    {item.data !== null && (
                      <Video
                        source={{ uri: item.data }}
                        rate={1.0}
                        volume={1.0}
                        isMuted
                        resizeMode="cover"
                        shouldPlay
                        isLooping
                        style={styles.mediaPreview}
                      />
                    )}
                    <Button
                      mode="outlined"
                      onPress={() => {
                        handleAddingVideo(item.localId);
                      }}
                    >
                      {item.data !== null
                        ? texts.editDescriptionButtons.changeVideo
                        : texts.userMedia.uploadVideo}
                    </Button>
                  </View>
                )}
              </Section>
            );
          }
        })}
        <Button
          loading={isProcessing}
          style={styles.spacing}
          mode="contained"
          onPress={handleOnSavePress}
        >
          {texts.editProfileButtons.save}
        </Button>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  spacing: {
    marginTop: 16,
  },
  mediaPreview: {
    width: 300,
    height: 200,
    alignSelf: 'center',
    marginBottom: 16,
  },
  descriptionInput: {
    maxHeight: 150,
  },
  buttonsRow: {
    flex: 1,
    flexDirection: 'row',
  },
  leftButtonWrapper: {
    flex: 1,
    marginRight: 8,
  },
  rightButtonWrapper: {
    flex: 1,
    marginLeft: 8,
  },
  sectionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selected: {
    opacity: 0.5,
  },
  linkInput: {
    height: 40,
  },
});
