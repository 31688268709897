import React, { useEffect, useMemo } from 'react';
import { StyleSheet, Image } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { FlatList } from 'react-native-gesture-handler';
import { Text } from 'react-native-paper';

import { useTranslation } from '../../translations';
import { useActions, useStore } from '../../store';
import ScreenWrapper from '../../components/ScreenWrapper';
import { DataLoadStatus } from '../../store/types';
import { ScreenProps } from '../../MainNavigator';
import { DEFAULT_URL_BANNER } from '../../consts';
import { TemplateProfile } from './TemplateProfile';

type Props = ScreenProps<'TemplateProfiles'>;

export function TemplateProfiles({ route: { params } }: Props) {
  const templateId = params?.templateId;
  const {
    templates: { getTemplateSharedProfiles },
  } = useActions();
  const {
    templates: {
      templateId: responseTemplateId,
      profiles,
      topBanner,
      listTitle,
    },
  } = useStore();
  const texts = useTranslation('common', 'errors');
  useEffect(() => {
    if (templateId) {
      getTemplateSharedProfiles({ templateId });
    }
  }, [getTemplateSharedProfiles, templateId]);
  return (
    <ScreenWrapper noPadding style={styles.container}>
      {useMemo(
        () =>
          responseTemplateId === templateId &&
          profiles.data.length > 0 &&
          profiles.status === DataLoadStatus.SUCCESS ? (
            <ScreenWrapper.Content style={styles.content}>
              {topBanner !== null ? (
                <Image
                  source={{
                    uri: topBanner !== null ? topBanner : DEFAULT_URL_BANNER,
                  }}
                  accessibilityIgnoresInvertColors
                  style={styles.banner}
                />
              ) : null}
              {listTitle !== null ? (
                <Text style={styles.title}>{listTitle}</Text>
              ) : null}
              <FlatList
                ItemSeparatorComponent={() => null}
                data={profiles.data}
                keyExtractor={(item) => item.id.toString()}
                ListEmptyComponent={() => <Text>{texts.common.emptyList}</Text>}
                renderItem={({ item }) => <TemplateProfile item={item} />}
                style={styles.listContainer}
              />
            </ScreenWrapper.Content>
          ) : profiles.status === DataLoadStatus.ERROR ? (
            <Text>{texts.errors.unexpected}</Text>
          ) : (
            <ActivityIndicator />
          ),
        [
          listTitle,
          profiles.data,
          profiles.status,
          responseTemplateId,
          templateId,
          texts.common.emptyList,
          texts.errors.unexpected,
          topBanner,
        ],
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    paddingHorizontal: 0,
  },
  listContainer: {
    width: '100%',
  },
  banner: {
    flex: 1,
    resizeMode: 'cover',
    marginBottom: 24,
  },
  title: {
    textAlign: 'center',
    fontSize: 32,
    marginBottom: 8,
    fontWeight: 'bold',
  },
});
