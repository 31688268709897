import React from 'react';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import { RouteProp as NavigationRouteProp } from '@react-navigation/core';

import {
  MainNavigatorParams,
  RouteProp as MainNavigatorRouteProp,
} from '../../MainNavigator';
import { useTheme } from '../../theming';
import PhotoVideoScreen from './PhotoVideoScreen';
import SignUp from './SignUpScreen';
import WelcomeScreen from './Welcome';
import InvitationDataContext from './InvitationDataContext';

export type OnboardingNavigatorParams = {
  OnboardingWelcome: undefined;
  PhotoVideo: undefined;
  SignUp: undefined;
};

type Screens = keyof (OnboardingNavigatorParams & MainNavigatorParams);

export type NavigationProp<S extends Screens> = StackNavigationProp<
  OnboardingNavigatorParams & MainNavigatorParams,
  S
>;

export type RouteProp<S extends Screens> = NavigationRouteProp<
  OnboardingNavigatorParams & MainNavigatorParams,
  S
>;

export type ScreenProps<S extends Screens> = {
  navigation: NavigationProp<S>;
  route: RouteProp<S>;
};

type Props = {
  route: MainNavigatorRouteProp<'OnboardingStack'>;
};

const OnboardingStack = createStackNavigator<OnboardingNavigatorParams>();

function OnboardingNavigator({ route }: Props) {
  const theme = useTheme();
  const { invitationHash } = route.params;

  return (
    <InvitationDataContext.Provider invitationHash={invitationHash}>
      <OnboardingStack.Navigator
        screenOptions={{
          headerBackTitleVisible: false,
          headerShown: true,
          headerStyle: {
            borderBottomWidth: 0, // removes bottom border from header on the web
            backgroundColor: theme.colors.black,
            shadowColor: 'transparent', // removes bottom border from header on IOS
          },
          headerTintColor: theme.colors.text,
        }}
      >
        <OnboardingStack.Screen
          name="OnboardingWelcome"
          options={{ headerShown: false }}
          component={WelcomeScreen}
        />
        <OnboardingStack.Screen
          name="SignUp"
          options={{
            headerTitle: 'Create account 1/2',
          }}
          component={SignUp}
        />
        <OnboardingStack.Screen
          name="PhotoVideo"
          options={{
            headerLeft: () => null,
            headerTitle: 'Create account 2/2',
          }}
          component={PhotoVideoScreen}
        />
      </OnboardingStack.Navigator>
    </InvitationDataContext.Provider>
  );
}

export default OnboardingNavigator;
