import React from 'react';
import { NavigationContainerRef } from '@react-navigation/native';
import { MainNavigatorParams, Screens } from './MainNavigator';

export const isAppMountedRef =
  React.createRef<boolean>() as React.MutableRefObject<boolean>;

export const navigationRef = React.createRef<NavigationContainerRef>();

/*
 * Use this navigate function ONLY in non-react modules like store
 */

export function navigate<S extends Screens>(
  name: S,
  params?: MainNavigatorParams[S],
) {
  if (isAppMountedRef.current && navigationRef.current) {
    navigationRef.current.navigate(name, params);
  }
}

export function reset<S extends Screens>(
  name: S,
  params?: MainNavigatorParams[S],
) {
  if (isAppMountedRef.current && navigationRef.current) {
    navigationRef.current.reset({
      index: 0,
      routes: [{ name, params }],
    });
  }
}
