const env = window.location.href.includes('herokuapp.com')
  ? 'staging'
  : 'production';

export default {
  apiURL:
    env === 'staging'
      ? 'https://app.thetagnetwork.com/api/v1'
      : 'https://app.thetagnetwork.com/api/v1',
};
