import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';

import ScreenWrapper from '../../components/ScreenWrapper';
import { stdAlert } from '../../components/alert';
import { useEffects } from '../../store';
import { useTranslation } from '../../translations';
import { ScreenProps } from '../../MainNavigator';
import Scanner from './Scanner';
import ScannerFrame from './Scanner/ScannerFrame';

type Props = ScreenProps<'QRScanner'>;

export default function QRScannerScreen({ navigation }: Props) {
  const texts = useTranslation('errors', 'QRScanner');
  const { api } = useEffects();
  const [isProcessingScannedBarcode, setIsProcessingScannedBarcode] =
    useState(false);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [codeScanned, setCodeScanned] = useState<boolean>(false);

  useEffect(() => {
    // we have to handle this way because navigation.push is starting transition and scanner had a chance to scan another time.
    // As a result we have loop of screens if user keeps qr code in the scanner frame.
    const focusCallback = () => {
      setIsProcessingScannedBarcode(false);
      setCodeScanned(false);
    };
    const blurUnsub = navigation.addListener('blur', () => {
      navigation.addListener('focus', focusCallback);
    });

    return () => {
      blurUnsub();
      navigation.removeListener('focus', focusCallback);
    };
  }, []);
  const handleOnScanned = async (result: string) => {
    if (!codeScanned) {
      setCodeScanned(true);
      const barcodeUuid = result.slice(result.lastIndexOf('/') + 1);
      setIsProcessingScannedBarcode(true);
      const response = await api.saveBarcodeScan(barcodeUuid);
      if (response.success) {
        const profileID = response.success.data.id;
        if (response.success.data.status === 'ALREADY_SCANNED_TODAY') {
          stdAlert(texts.QRScanner.profileAlreadyScanned);
        }
        navigation.push('DigitalBusinessCardStack', { profileID });
        setCodeScanned(false);
      } else {
        stdAlert(texts.errors.unexpected, undefined, () => {
          setCodeScanned(false);
        });
      }
    }
  };

  const handlePermissionRequested = (hasPermission: boolean) => {
    setHasPermission(hasPermission);
  };

  if (isProcessingScannedBarcode === true) {
    return (
      <ScreenWrapper style={styles.loaderWrapper}>
        <ActivityIndicator />
      </ScreenWrapper>
    );
  }

  if (hasPermission === false) {
    return (
      <ScreenWrapper>
        <View style={styles.contentWrapper}>
          <Text>{texts.QRScanner.noPermissionGranted}</Text>
        </View>
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper>
      <View style={styles.contentWrapper}>
        {hasPermission !== null ? (
          <View style={styles.instructionWrapper}>
            <Text style={styles.instruction}>
              {texts.QRScanner.instruction}
            </Text>
          </View>
        ) : null}
        {hasPermission === null && Platform.OS === 'web' ? ( // avoid activity indicator flickering on mobile
          <ActivityIndicator />
        ) : null}
        <ScannerFrame hideCorners={hasPermission === null}>
          {/* we have to display scanner to check whether permission is granted so we hiding corners to avoid flickering during check */}
          <Scanner
            onScanned={handleOnScanned}
            onPermissionRequested={handlePermissionRequested}
          />
        </ScannerFrame>
      </View>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    marginBottom: 48,
    maxWidth: '40%',
  },
  instruction: {
    textAlign: 'center',
  },
  loaderWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  contentWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
    flex: 1,
  },
});
