import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import {
  DrawerContentScrollView,
  DrawerItem,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import { ActivityIndicator, Text } from 'react-native-paper';

import { useTheme } from '../../../theming';
import { useStore } from '../../../store';
import { ButtonType } from '../../../store/effects/api/types';
import { DataLoadStatus } from '../../../store/types';
import { useTranslation } from '../../../translations';
import DataContext from '../../../screens/Profile/DataContext';
import Icon, { IconName } from '../../../components/Icon';
import Button from '../../../components/Button';
import { openLink } from '../../../utils/buttonActions';
import AutoscaleImage from '../../../components/AutoscaleImage';
import SocialMediaButtons from '../../../components/SocialMediaButtons';

import { NavigationProp } from '../index';

type Props = {
  stackNavigation: NavigationProp<'DrawerHome'>;
} & DrawerContentComponentProps;

type DrawerRouteProps = {
  buttonIndex?: number;
};

export default function DrawerContent({
  state,
  stackNavigation,
  navigation,
  ...props
}: Props) {
  const texts = useTranslation('errors', 'webNavigation');
  const { user } = useStore();
  const { colors } = useTheme();
  const [barcodeUrl, setBarcodeUrl] = useState<string | null>(null);
  const [isLoadingBarcode, setIsLoadingBarcode] = useState(true);
  const activeButtonIndex = (
    state.routes[state.index].params as DrawerRouteProps
  )?.buttonIndex;
  const { data, status, isUserProfile, getProfileQRCodeUrl } = useContext(
    DataContext.Context,
  );
  const QRCodeSize = 304 - 16;

  useEffect(() => {
    if (status === DataLoadStatus.SUCCESS && data !== null) {
      (async () => {
        setIsLoadingBarcode(true);
        const result = await getProfileQRCodeUrl();
        if (result) {
          setBarcodeUrl(result.QRCodeUrl);
        }
        setIsLoadingBarcode(false);
      })();
    }
  }, [status, data, getProfileQRCodeUrl]);

  function getButtonInfo(
    button: ButtonType,
    index: number,
  ): { handler: () => void; icon: IconName } {
    const { data_type } = button;
    switch (data_type) {
      case 'scanner':
        return {
          icon: 'qrcode-scan',
          handler: () => navigation.navigate('QRScanner'),
        };
      case 'social':
        return {
          icon: 'facebook',
          handler: () =>
            navigation.navigate('DBC-SocialMedia', { buttonIndex: index }),
        };
      case 'gallery':
        return {
          icon: 'image-multiple',
          handler: () =>
            navigation.navigate('DBC-Gallery', { buttonIndex: index }),
        };
      case 'information':
        return {
          icon: 'information-outline',
          handler: () =>
            navigation.navigate('DBC-InfoPage', {
              buttonIndex: index,
            }),
        };
      case 'sub_page':
        return {
          icon: 'application',
          handler: () =>
            navigation.navigate('DBC-SubPage', {
              buttonIndex: index,
            }),
        };
      case 'survey': {
        const icon: IconName =
          button.survey?.is_referral !== undefined &&
          button.survey.is_referral === true
            ? 'account-arrow-right'
            : 'checkbox-multiple-marked';
        return {
          icon,
          handler: () => {
            navigation.navigate('DBC-Survey', { buttonIndex: index });
          },
        };
      }
      case 'location':
        return {
          icon: 'map',
          handler: () => {
            openLink(`http://maps.google.com/maps?q=${button.data}`);
          },
        };
      case 'contact':
        return {
          icon: 'account-box-outline',
          handler: () => {
            navigation.navigate('DBC-ContactPage', { buttonIndex: index });
          },
        };
      case 'phone':
        return {
          icon: 'phone',
          handler: () => {
            navigation.navigate('DBC-Phone', { buttonIndex: index });
          },
        };
      case 'email':
        return {
          icon: 'email',
          handler: () => {
            navigation.navigate('DBC-Email', { buttonIndex: index });
          },
        };
      case 'url':
        return {
          icon: 'web',
          handler: () => {
            navigation.navigate('DBC-Website', { buttonIndex: index });
          },
        };
      case 'pdf':
        return {
          icon: 'pdf-box',
          handler: () => {
            navigation.navigate('DBC-PDF', { buttonIndex: index });
          },
        };
      case 'date':
        return {
          icon: 'calendar',
          handler: () => {
            navigation.navigate('DBC-Date', { buttonIndex: index });
          },
        };
      case 'description':
        return {
          icon: 'account',
          handler: () => {
            navigation.navigate('DBC-Description', { buttonIndex: index });
          },
        };
      case 'profile':
        return {
          icon: 'account-card-details-outline',
          handler: () => {
            stackNavigation.push('DigitalBusinessCardStack', {
              profileID: parseInt(button.data),
            });
          },
        };

      default:
        return {
          icon: 'help',
          handler: () => {
            return undefined;
          },
        };
    }
  }

  const parentRoutesLength = stackNavigation
    .dangerouslyGetParent()
    ?.dangerouslyGetState()?.routes?.length;
  const isSubsequentProfile =
    parentRoutesLength !== undefined && parentRoutesLength > 1;

  return (
    <DrawerContentScrollView
      {...props}
      contentContainerStyle={styles.drawerContentContainerStyles}
      style={{ backgroundColor: colors.darkGrey }}
    >
      {status === DataLoadStatus.SUCCESS && data !== null ? (
        <>
          <AutoscaleImage uri={data.logo_url} style={styles.logoBanner} />
          <View
            style={[styles.horizontalBar, { backgroundColor: colors.primary }]}
          />
          <View style={[styles.wrapper, { overflow: 'hidden' }]}>
            {isSubsequentProfile || user.token !== null ? (
              <Button
                style={styles.backButton}
                mode="outlined"
                icon="arrow-left"
                onPress={() => {
                  if (!isSubsequentProfile && user.token !== null) {
                    if (isUserProfile) {
                      stackNavigation.reset({
                        index: 0,
                        routes: [{ name: 'Dashboard' }], // to fix
                      });
                    } else {
                      stackNavigation.goBack();
                    }
                  } else {
                    if (stackNavigation.canGoBack()) {
                      stackNavigation.goBack();
                    } else {
                      stackNavigation.navigate('Welcome');
                    }
                  }
                }}
              >
                {texts.webNavigation.backLabel}
              </Button>
            ) : null}
            <DrawerItem
              style={[styles.drawerItem, styles.drawerItemCentered]}
              label={data.button_label || texts.webNavigation.profilePreview}
              focused={
                activeButtonIndex === undefined &&
                state.routeNames[state.index] == 'DBC-Home'
              }
              labelStyle={[
                styles.drawerItemLabel,
                {
                  color:
                    activeButtonIndex === undefined &&
                    state.routeNames[state.index] == 'DBC-Home'
                      ? colors.black
                      : colors.text,
                },
              ]}
              activeBackgroundColor={colors.primary}
              onPress={() => navigation.navigate('DBC-Home')}
            />
            {data.buttons.map((button, index) => {
              const { handler: handleOnItemPress, icon } = getButtonInfo(
                button,
                index,
              );

              // Do not display `Description` button if there
              // are not any elements within
              if (
                button.data_type === 'description' &&
                button.elements.length < 1
              ) {
                return;
              }

              const isFocused =
                activeButtonIndex !== undefined && index === activeButtonIndex;
              return (
                <DrawerItem
                  key={button.id}
                  focused={isFocused}
                  style={styles.drawerItem}
                  label={button.label}
                  icon={({ size, focused }) => (
                    <Icon
                      name={icon}
                      size={size}
                      color={focused ? colors.black : colors.text}
                    />
                  )}
                  labelStyle={[
                    styles.drawerItemLabel,
                    {
                      color: isFocused ? colors.black : colors.text,
                    },
                  ]}
                  activeBackgroundColor={colors.primary}
                  onPress={handleOnItemPress}
                />
              );
            })}
            {data.show_social_media_links ? (
              <SocialMediaButtons
                socialMedias={{
                  social_facebook: data.account.social_facebook,
                  social_instagram: data.account.social_instagram,
                  social_youtube: data.account.social_youtube,
                  social_twitter: data.account.social_twitter,
                  social_linkedin: data.account.social_linkedin,
                  social_vimeo: data.account.social_vimeo,
                }}
              />
            ) : null}
            <View style={styles.QRCodeWrapper}>
              {isLoadingBarcode ? (
                <View
                  style={[
                    styles.QRCodeLoaderWrapper,
                    { width: QRCodeSize, height: QRCodeSize },
                  ]}
                >
                  <ActivityIndicator />
                </View>
              ) : (
                <Image
                  style={{ width: QRCodeSize, height: QRCodeSize }}
                  source={{ uri: barcodeUrl ?? undefined }}
                  accessibilityIgnoresInvertColors
                />
              )}
            </View>
          </View>
        </>
      ) : status === DataLoadStatus.ERROR ? (
        <Text>{texts.errors.unexpected}</Text>
      ) : (
        <View style={styles.loaderWrapper}>
          <ActivityIndicator />
        </View>
      )}
    </DrawerContentScrollView>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    padding: 16,
    marginTop: 16,
  },
  QRCodeLoaderWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawerContentContainerStyles: {
    flexGrow: 1,
  },
  drawerItem: {
    paddingLeft: 16,
  },
  drawerItemCentered: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  drawerItemLabel: {
    textTransform: 'uppercase',
  },
  backButton: {
    marginBottom: 16,
    marginHorizontal: 10,
  },
  logoBanner: {
    width: '100%',
    resizeMode: 'contain',
  },
  horizontalBar: {
    height: 16,
  },
  QRCodeWrapper: {
    marginTop: 16,
  },
});
