import React from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useTranslation } from '../translations';
import { stdAlert } from './alert';

type Props = {
  alwaysClickable?: boolean;
  available?: boolean;
  children: React.ReactNode;
};

export default function PremiumFeatureGate({
  alwaysClickable = false,
  available = false,
  children,
}: Props) {
  const texts = useTranslation('premiumFeatureGate');

  if (available) {
    return <>{children}</>;
  }

  const view = (
    <View>
      {children}
      {!alwaysClickable && <View style={styles.gate}></View>}
      <Text style={styles.label}>{texts.premiumFeatureGate.overlay}</Text>
    </View>
  );

  if (alwaysClickable) {
    return <View style={styles.root}>{view}</View>;
  }

  return (
    <TouchableOpacity
      onPress={() => {
        stdAlert(texts.premiumFeatureGate.notAvailable);
      }}
      style={styles.root}
    >
      {view}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  gate: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  label: {
    borderRadius: 4,
    color: 'white',
    backgroundColor: 'red',
    fontSize: 14,
    overflow: 'hidden',
    padding: 4,
    position: 'absolute',
    right: 0,
    top: 0,
  },
});
