import React from 'react';
import { TouchableOpacity, StyleSheet, View, Image } from 'react-native';
import { Text } from 'react-native-paper';

import { ProfileMeta } from '../store/effects/api/types';
import { DEFAULT_URL_BANNER } from '../consts';
import useAppSize from '../hooks/useAppSize';
import { useCompanyPositionLabel } from '../hooks/useCompanyPositionLabel';
import UserImage from './UserImage';

type Props = {
  profile: ProfileMeta;
  dateText: string;
  icon?: React.ReactNode;
  tileColor: string;
  onPress: () => void;
  onLongPress?: () => void;
  opacity?: number;
};

export default function ProfilesListItem({
  profile,
  dateText,
  icon,
  tileColor,
  opacity = 1,
  onPress,
  onLongPress,
}: Props) {
  const appSize = useAppSize();
  const userImageSize = 100;
  const userImageTop = userImageSize / 2;
  const userImageLeft = userImageSize / 4;
  return (
    <TouchableOpacity
      style={[
        styles.listItem,
        {
          borderColor: tileColor,
          opacity,
          backgroundColor: tileColor,
        },
      ]}
      onLongPress={() => {
        if (onLongPress !== undefined) {
          onLongPress();
        }
      }}
      onPress={onPress}
    >
      <View>
        <View style={styles.userMediaWrapper}>
          <Image
            source={{
              uri:
                profile.logo_url !== null
                  ? profile.logo_url
                  : DEFAULT_URL_BANNER,
            }}
            accessibilityIgnoresInvertColors
            style={styles.banner}
          />
        </View>
        <View style={styles.row}>
          <View
            style={[
              styles.userImageWrapper,
              {
                top: -userImageTop,
                left: userImageLeft,
                width: userImageSize,
                height: userImageSize,
              },
            ]}
          >
            <UserImage
              style={[styles.userImage, { borderColor: tileColor }]}
              picture={profile.picture_url}
              video={profile.video}
              videoClip={profile.video_clip_url}
              mediaType={profile.picture_or_video}
            />
          </View>
          <View
            style={[
              styles.profileInfoWrapper,
              {
                marginLeft: userImageSize + userImageLeft,
                maxWidth: appSize.width - (userImageSize + userImageLeft + 50),
              }, // 50 is approximately icon size
            ]}
          >
            <Text style={styles.profileName}>{profile.name}</Text>
            <View style={styles.profileDetailsWrapper}>
              <Text>
                {useCompanyPositionLabel({
                  company: profile.company,
                  profileRole: profile.profile_role,
                })}
              </Text>
              <Text>{dateText}</Text>
            </View>
          </View>
          {icon !== undefined ? icon : null}
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  listItem: {
    borderWidth: 1,
    padding: 4,
  },
  row: {
    flexDirection: 'row',
  },
  userMediaWrapper: {
    height: 120,
    flexDirection: 'row',
  },
  banner: {
    flex: 1,
    resizeMode: 'cover',
  },
  userImageWrapper: {
    position: 'absolute',
  },
  userImage: {
    borderWidth: 5,
  },
  profileInfoWrapper: {
    padding: 8,
  },
  profileName: {
    fontSize: 18,
  },
  profileDetailsWrapper: {
    paddingTop: 4,
  },
});
