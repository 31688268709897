import { DescriptionDataType } from '../store/effects/api/types';

export function createEmptyBlob(dataType: DescriptionDataType) {
  const getType = () => {
    switch (dataType) {
      case 'photo':
        return 'image/png';
      case 'video':
        return 'video/mp4';
      default:
        return 'empty';
    }
  };

  return new File([new Blob()], getType(), {
    type: getType(),
  });
}
