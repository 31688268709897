import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import { ScreenProps } from '../MainNavigator';
import { stdAlert, binaryAlert } from '../components/alert';
import ScreenWrapper from '../components/ScreenWrapper';
import { useActions, useStore } from '../store';
import { useTranslation } from '../translations';

type Props = ScreenProps<'Invitations'>;

export default function Invitations({ route: { params }, navigation }: Props) {
  const invitationHash = params.invitationHash;
  const texts = useTranslation('onboarding');
  const {
    onboarding: { getInvitationData },
    profiles: { createProfileLoggedInUser },
  } = useActions();
  const {
    user: { token },
  } = useStore();
  useEffect(() => {
    (async () => {
      const invitationData = await getInvitationData(invitationHash);
      if (invitationData !== undefined) {
        if (token !== null) {
          binaryAlert(
            texts.onboarding.onboardingTitle,
            texts.onboarding.askCreateNewProfile,
            async () => {
              const result = await createProfileLoggedInUser({
                invitationHash,
              });
              if (result === true) {
                navigation.reset({
                  index: 0,
                  routes: [
                    {
                      name: 'Dashboard',
                    },
                  ],
                });
                stdAlert(texts.onboarding.newProfileCreated);
              } else {
                stdAlert(texts.onboarding.alreadyCreatedProfile);
                navigation.navigate('Dashboard');
              }
            },
            () => {
              const { routes } = navigation.dangerouslyGetState();
              const lastRoute = routes[routes.length - 1];
              //user unauthorized was navigated to welcome already
              if (lastRoute.name !== 'Welcome') {
                if (navigation.canGoBack()) {
                  navigation.goBack();
                }
              }
            },
          );
          return;
        } else {
          navigation.reset({
            index: 0,
            routes: [
              {
                name: 'OnboardingStack',
                params: { invitationHash },
                state: {
                  routes: [
                    {
                      name: 'OnboardingWelcome',
                      params: { invitationHash },
                    },
                  ],
                },
              },
            ],
          });
          return;
        }
      }
      stdAlert(texts.onboarding.invitationLinkNotValid);
      navigation.replace('Welcome');
    })();
  }, []);
  return (
    <ScreenWrapper style={styles.container}>
      <ActivityIndicator />
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
});
