import React, { useState } from 'react';
import { StyleSheet, View, Platform, Dimensions, Image } from 'react-native';

import Video from '../../../components/UserMedia/ExternalVideo';
import { stdAlert } from '../../../components/alert';
import { SurveyQuestion } from '../../../store/effects/api/types';
import { useTranslation } from '../../../translations';
import { useEffects } from '../../../store';
import { RouteProp, NavigationProp } from '../index';

import SurveyForm from '../SurveyScreen/SurveyForm';
import ReferralFields from '../SurveyScreen/ReferralFields';
import DataLoaderWrapper from './DataLoaderWrapper';

type Props = {
  route: RouteProp<'DBC-Survey'>;
  navigation: NavigationProp<'DBC-Survey'>;
};

export default function SurveyScreen({ route, navigation }: Props) {
  const texts = useTranslation('errors', 'survey');
  const { api } = useEffects();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [isProcessingAnswers, setIsProcessingAnswers] = useState(false);
  const buttonIndex = route.params?.buttonIndex;

  return (
    <DataLoaderWrapper buttonIndex={buttonIndex}>
      {(surveyButton, profileData) => {
        const handleSubmit = async (result: SurveyQuestion[]) => {
          if (surveyButton?.survey !== undefined) {
            setIsProcessingAnswers(true);
            const saveResult = await api.saveSurvey(
              surveyButton.survey.id,
              profileData.id,
              surveyButton.survey.is_referral === true
                ? {
                    questionsWithAnswers: result,
                    referral: { name, email, phoneNum },
                  }
                : { questionsWithAnswers: result },
              surveyButton.survey.is_referral,
            );
            if (saveResult.success !== undefined) {
              stdAlert(
                surveyButton.survey.thank_you ?? texts.survey.defaultThanksMsg,
                'Survey',
                () => {
                  navigation.replace('DigitalBusinessCardStack', {
                    profileID: profileData.id,
                  });
                },
              );
            } else {
              stdAlert(texts.errors.unexpected);
            }

            setIsProcessingAnswers(false);
          }
        };
        return (
          <>
            {surveyButton.survey?.picture_url !== undefined &&
            surveyButton.survey?.picture_url !== null ? (
              <View style={styles.imageWrapper}>
                <Image
                  style={styles.image}
                  resizeMode="cover"
                  source={{ uri: surveyButton.survey.picture_url }}
                  accessibilityIgnoresInvertColors
                />
              </View>
            ) : null}
            {surveyButton.survey?.video !== undefined &&
            surveyButton.survey?.video !== '' ? (
              <View style={styles.videoWrapper}>
                <Video url={surveyButton.survey.video ?? ''} />
              </View>
            ) : null}
            <View style={styles.formWrapper}>
              {surveyButton.survey?.is_referral === true ? (
                <ReferralFields
                  email={email}
                  name={name}
                  phone={phoneNum}
                  onEmailChange={setEmail}
                  onNameChange={setName}
                  onPhoneChange={setPhoneNum}
                />
              ) : null}
              <SurveyForm
                isProcessing={isProcessingAnswers}
                onSubmit={handleSubmit}
                surveyConfig={surveyButton}
              />
            </View>
          </>
        );
      }}
    </DataLoaderWrapper>
  );
}

const styles = StyleSheet.create({
  formWrapper: {
    marginTop: 20,
  },
  videoWrapper: {
    marginTop: 8,
    width: '100%',
    minHeight: Platform.OS === 'web' ? 300 : Dimensions.get('window').width / 2,
  },
  imageWrapper: {
    marginTop: 8,
    width: '100%',
  },
  image: {
    minHeight: 200,
    width: '100%',
  },
});
