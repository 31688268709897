import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Text } from 'react-native-paper';
import { useTheme } from '../theming';
import Icon, { IconName } from './Icon';

type Props = {
  icon: IconName;
  detailsText: string | null | undefined;
};

export default function DashboardDetailsItem({ icon, detailsText }: Props) {
  const { colors } = useTheme();
  return typeof detailsText === 'string' && detailsText !== '' ? (
    <>
      <View style={styles.accountDetailsItem}>
        <Icon
          name={icon}
          size={12}
          color={colors.text}
          style={styles.accountDetailsItemIcon}
        />
        <Text style={styles.accountDetailsText}>{detailsText}</Text>
      </View>
    </>
  ) : null;
}

const styles = StyleSheet.create({
  accountDetailsWrapper: {
    paddingTop: 4,
  },
  accountDetailsItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  accountDetailsItemIcon: {
    marginRight: 8,
  },
  accountDetailsText: {
    fontSize: 12,
  },
});
