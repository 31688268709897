import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, TextInput } from 'react-native-paper';

import { useTranslation } from '../../../translations';

function capitalizeLabel(label: string) {
  return label.slice(0, 1).toUpperCase() + label.slice(1);
}

type ValueChangeCallback = (value: string) => void;

type Props = {
  onNameChange: ValueChangeCallback;
  onEmailChange: ValueChangeCallback;
  onPhoneChange: ValueChangeCallback;
  name: string;
  email: string;
  phone: string;
};

export default function ReferralFields({
  onNameChange,
  onEmailChange,
  onPhoneChange,
  name,
  email,
  phone,
}: Props) {
  const texts = useTranslation('forms');
  return (
    <>
      <View style={styles.inputWithLabelWrapper}>
        <Text>{capitalizeLabel(texts.forms.nameLabel)}</Text>
        <TextInput
          mode="outlined"
          value={name}
          placeholder={texts.forms.nameLabel}
          onChangeText={onNameChange}
        />
      </View>
      <View style={styles.inputWithLabelWrapper}>
        <Text>{capitalizeLabel(texts.forms.emailLabel)}</Text>
        <TextInput
          mode="outlined"
          textContentType="emailAddress"
          autoCapitalize="none"
          keyboardType="email-address"
          placeholder={texts.forms.emailLabel}
          value={email}
          onChangeText={onEmailChange}
        />
      </View>
      <View style={styles.inputWithLabelWrapper}>
        <Text>{capitalizeLabel(texts.forms.phoneNumLabel)}</Text>
        <TextInput
          mode="outlined"
          value={phone}
          placeholder={texts.forms.phoneNumLabel}
          textContentType="telephoneNumber"
          autoCapitalize="none"
          keyboardType="phone-pad"
          onChangeText={onPhoneChange}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  inputWithLabelWrapper: {
    marginVertical: 5,
  },
});
