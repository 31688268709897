import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { Text } from 'react-native-paper';

import Graphics from '../../../../assets/undraw_email.svg';
import { useTranslation } from '../../../translations';
import { useTheme } from '../../../theming';
import { openLink } from '../../../utils/buttonActions';
import { WebRouteProp } from '../index';
import DataLoaderWrapper from './DataLoaderWrapper';

type Props = {
  route: WebRouteProp<'DBC-Email'>;
};

export default function EmailScreen({ route }: Props) {
  const texts = useTranslation('webNavigation');
  const { colors } = useTheme();
  return (
    <DataLoaderWrapper buttonIndex={route.params?.buttonIndex}>
      {(emailButton) => {
        return (
          <TouchableOpacity
            onPress={() => {
              openLink(`mailto:${emailButton.data}`);
            }}
            style={[styles.wrapper, { backgroundColor: colors.darkGrey }]}
          >
            <View style={styles.graphicsWrapper}>
              <Graphics width={600} height={400} />
            </View>
            <Text style={styles.emailText}>
              {texts.webNavigation.emailText}: {emailButton.data}
            </Text>
          </TouchableOpacity>
        );
      }}
    </DataLoaderWrapper>
  );
}

const styles = StyleSheet.create({
  graphicsWrapper: {
    alignItems: 'center',
  },
  emailText: {
    paddingTop: 40,
    fontSize: 32,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  wrapper: {
    padding: 40,
  },
});
