import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, ScrollView, View, Platform } from 'react-native';
import { Text, TextInput, ActivityIndicator } from 'react-native-paper';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';

import { Video } from 'expo-av';
import { DEFAULT_AVATAR_IMAGE } from '../consts';
import Button from '../components/Button';
import { useTheme } from '../theming';
import ScreenWrapper from '../components/ScreenWrapper';
import { useTranslation } from '../translations';
import Icon from '../components/Icon';
import { useStore, useActions } from '../store';
import { stdAlert } from '../components/alert';
import AutoscaleImage from '../components/AutoscaleImage';
import {
  checkCameraRollPermissionForIOS,
  getImageSrc,
} from '../components/UserMedia/imageUtils';
import { getVideoThumbnail } from '../components/UserMedia/videoUtils';

export default function ChangeUserAccountPhotoVideoScreen() {
  const { colors } = useTheme();
  const texts = useTranslation('userMedia', 'common', 'errors', 'edit');
  const { user } = useStore();
  const { user: userActions } = useActions();
  const [hasPermission, setHasPermission] = useState<boolean | null>(
    Constants?.platform?.ios !== undefined ? null : true,
  );
  const isVideoUpdated = useRef(false);
  const isVideoLinkUpdated = useRef(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [imgUri, setImgUri] = useState<string | null>(null);
  const [videoUri, setVideoUri] = useState<string | null>(null);
  const [initialVideoPreview, setInitialVideoPreview] = useState<string | null>(
    null,
  );
  const [imgPreview, setImgPreview] = useState<string | null>(null);
  const [videoPreview, setVideoPreview] = useState<string | null>(null);
  const [videoLink, setVideoLink] = useState<string>(
    user.meta ? user.meta.video ?? '' : '',
  );

  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    (async () => {
      if (user.meta !== null) {
        setIsPreviewLoading(true);
        const initialPreview = await getImageSrc({
          pictureUrl: user.meta.picture_url,
          video: user.meta.video,
        });
        setImgPreview(initialPreview ?? null);
        setVideoPreview(null);
        if (user.meta.video_clip_url !== null) {
          setImgPreview(null);
          setVideoPreview(user.meta.video_clip_url);
          isVideoUpdated.current = true;
        } else if (user.meta.picture_url !== null) {
          isVideoUpdated.current = false;
          isVideoLinkUpdated.current = false;
          setImgUri(user.meta.picture_url);
        }
        if (
          user.meta.video_clip_url === null &&
          user.meta.picture_url === null &&
          user.meta.video === null
        ) {
          isVideoUpdated.current = false;
          isVideoLinkUpdated.current = false;
          setImgPreview(DEFAULT_AVATAR_IMAGE);
          setVideoPreview(null);
        }
        setIsPreviewLoading(false);
      }
    })();
  }, []);

  const handleAddingPhoto = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );

    if (grantedPermission === true) {
      setHasPermission(true);
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.cancelled) {
        setImgUri(result.uri);
        isVideoUpdated.current = false;
        setImgPreview(result.uri);
        setVideoPreview(null);
        setVideoLink('');
      }
    } else {
      setHasPermission(false);
    }
  };

  const handleTakingPhoto = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission) {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();
      if (status === 'granted') {
        setHasPermission(true);
        const result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });

        if (!result.cancelled) {
          setImgUri(result.uri);
          isVideoUpdated.current = false;
          setImgPreview(result.uri);
          setVideoPreview(null);
          setVideoLink('');
        }
        return;
      }
    }
    setHasPermission(false);
  };

  const handleAddingVideo = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission === true) {
      setHasPermission(true);
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Videos,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.cancelled) {
        setVideoUri(result.uri);
        isVideoUpdated.current = true;
        setImgPreview(null);
        setVideoPreview(result.uri);
        setVideoLink('');
      }
    } else {
      setHasPermission(false);
    }
  };

  const handleTakingVideo = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission) {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();
      if (status === 'granted') {
        setHasPermission(true);
        const result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Videos,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });

        if (!result.cancelled) {
          setVideoUri(result.uri);
          isVideoUpdated.current = true;
          setImgPreview(null);
          setVideoPreview(result.uri);
          setVideoLink('');
        }
        return;
      }
    }
    setHasPermission(false);
  };

  const handleSave = async () => {
    let success = false;
    if (isVideoUpdated.current === true && videoUri !== null) {
      setIsUpdatingData(true);
      if (videoUri !== null) {
        success = await userActions.updateUserVideo({
          videoUri,
        });
      }
    } else if (isVideoLinkUpdated.current === true && videoLink !== null) {
      setIsUpdatingData(true);
      if (videoLink !== null) {
        success = await userActions.updateUserVideoLink({
          videoLink,
        });
      }
    } else if (imgUri !== null) {
      setIsUpdatingData(true);
      success = await userActions.updateUserPhoto({
        photoUri: imgUri,
      });
    }
    if (success) {
      const result = await userActions.fetchAndSetUserAccountData();
      if (result === true) {
        stdAlert(texts.edit.updateSuccess);
        if (isVideoUpdated.current === true) {
          setImgUri(null);
          setVideoUri(null);
          setInitialVideoPreview(imgPreview);
        }
      } else {
        stdAlert(texts.errors.unexpected);
      }
    }
    setIsUpdatingData(false);
  };
  const handleChangeVideoLink = async (newVideoLink: string) => {
    setVideoLink(newVideoLink);
    if (newVideoLink !== '') {
      setIsPreviewLoading(true);
      const thumbnail = await getVideoThumbnail(newVideoLink);
      if (thumbnail === '') {
        setImgPreview(imgUri !== null ? imgUri : null);
        setVideoPreview(null);
        setIsPreviewLoading(false);
        return;
      } else {
        setImgPreview(thumbnail);
        setVideoPreview(null);
        setIsPreviewLoading(false);
        isVideoLinkUpdated.current = true;
        return;
      }
    }
    if (imgUri !== null) {
      setImgPreview(imgUri);
      setVideoPreview(null);
    } else {
      setImgPreview(null);
      setVideoPreview(null);
    }
    isVideoLinkUpdated.current = false;
  };

  if (hasPermission === false) {
    return (
      <ScreenWrapper style={styles.container}>
        {!isPreviewLoading ? (
          <AutoscaleImage
            uri={imgPreview}
            style={styles.imagePreview}
            resizeMode="contain"
          />
        ) : (
          <View style={styles.imagePreviewLoaderWrapper}>
            <ActivityIndicator />
          </View>
        )}
        <Text>{texts.userMedia.noCameraPermission}</Text>
        <View style={styles.buttonsWrapper}>
          <TextInput mode="outlined" label={texts.userMedia.insertLink} />
          <Button style={styles.saveButton} mode="contained">
            {texts.edit.save}
          </Button>
        </View>
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper style={styles.container}>
      <ScrollView
        contentContainerStyle={styles.scrollViewContentContainerStyle}
      >
        <View style={styles.instructionWrapper}>
          <Text>{texts.userMedia.changePhoto}</Text>
        </View>
        {!isPreviewLoading ? (
          <>
            {imgPreview !== null && (
              <AutoscaleImage
                uri={imgPreview ?? initialVideoPreview}
                style={styles.imagePreview}
                resizeMode="contain"
              />
            )}
            {videoPreview !== null && (
              <Video
                source={{ uri: videoPreview }}
                rate={1.0}
                volume={1.0}
                isMuted
                resizeMode="cover"
                shouldPlay
                isLooping
                style={styles.videoPreview}
              />
            )}
          </>
        ) : (
          <View style={styles.imagePreviewLoaderWrapper}>
            <ActivityIndicator />
          </View>
        )}
        <View style={styles.buttonsWrapper}>
          <Button mode="outlined" onPress={handleAddingPhoto}>
            {texts.userMedia.uploadPhoto}
          </Button>
          {!isWeb && (
            <Button
              style={styles.button}
              onPress={handleTakingPhoto}
              mode="outlined"
              icon={() => (
                <Icon
                  name="camera"
                  style={[styles.cameraIcon, { color: colors.text }]}
                />
              )}
            >
              {texts.userMedia.takeNewPhoto}
            </Button>
          )}
          <View style={styles.photoVideoDivider}>
            <Text>{texts.common.orDivider}</Text>
          </View>
          <Button mode="outlined" onPress={handleAddingVideo}>
            {texts.userMedia.uploadVideo}
          </Button>
          {!isWeb && (
            <Button
              style={styles.button}
              onPress={handleTakingVideo}
              mode="outlined"
              icon={() => (
                <Icon
                  name="video"
                  style={[styles.cameraIcon, { color: colors.text }]}
                />
              )}
            >
              {texts.userMedia.takeNewVideo}
            </Button>
          )}
          <View style={styles.photoVideoDivider}>
            <Text>{texts.common.orDivider}</Text>
          </View>
          <TextInput
            mode="outlined"
            value={videoLink}
            onChangeText={handleChangeVideoLink}
            label={texts.userMedia.insertLink}
          />
          <Button
            loading={isUpdatingData}
            style={styles.saveButton}
            onPress={handleSave}
            mode="contained"
          >
            {texts.edit.save}
          </Button>
        </View>
      </ScrollView>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  instructionWrapper: {
    paddingVertical: 24,
  },
  scrollViewContentContainerStyle: {
    alignItems: 'center',
  },
  buttonsWrapper: {
    marginTop: 20,
    alignSelf: 'stretch',
  },
  button: {
    marginTop: 16,
  },
  saveButton: {
    marginTop: 24,
  },
  cameraIcon: {
    fontSize: 24,
  },
  photoVideoDivider: {
    alignItems: 'center',
    padding: 8,
  },
  imagePreview: {
    width: 300,
    height: 200,
  },
  videoPreview: {
    width: 200,
    height: 200,
  },
  imagePreviewLoaderWrapper: {
    width: 300,
    height: 200,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
