import { DataLoadStatus } from '../types';

import {
  MyProfileMeta,
  SavedProfileMeta,
  ScannedProfileMeta,
} from '../effects/api/types';

export type ProfilesState = {
  profilesList: { data: Array<MyProfileMeta>; status: DataLoadStatus };
  savedProfilesList: { data: Array<SavedProfileMeta>; status: DataLoadStatus };
  scannedProfilesList: {
    data: Array<ScannedProfileMeta>;
    status: DataLoadStatus;
  };
  mainProfileID: number | null;
};

export const state: ProfilesState = {
  profilesList: {
    data: [],
    status: DataLoadStatus.UNKNOWN,
  },
  savedProfilesList: {
    data: [],
    status: DataLoadStatus.UNKNOWN,
  },
  scannedProfilesList: {
    data: [],
    status: DataLoadStatus.UNKNOWN,
  },
  mainProfileID: null,
};
