import React from 'react';
import { StyleProp, ViewStyle, View } from 'react-native';

import { DEFAULT_AVATAR_IMAGE } from '../../consts';
import ExternalVideo from './ExternalVideo';
import InternalVideo from './InternalVideo';
import Picture from './Picture';

type Props = {
  video: string | null;
  videoClip: string | null;
  pictureUrl: string | null;
  pictureOrVideo: 'picture' | 'video' | 'video_clip' | null;
  previewOnly?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default function UserMedia({
  video,
  videoClip,
  pictureUrl,
  pictureOrVideo,
  previewOnly,
  style,
}: Props) {
  /*
   * default value from the backend once neither picture nor video is set by user is :
   * {video: '', picture_or_video: 'video'}
   * thats why we need those checks here :/
   */
  if (
    pictureOrVideo === null ||
    (pictureOrVideo === 'picture' &&
      (Boolean(pictureUrl) === null || pictureUrl === '')) ||
    (pictureOrVideo === 'video' && (video === null || video === ''))
  ) {
    return (
      <View style={style}>
        <Picture url={DEFAULT_AVATAR_IMAGE} />
      </View>
    );
  }
  return (
    <View style={style}>
      {pictureOrVideo === 'picture' ? (
        <Picture url={pictureUrl as string} />
      ) : pictureOrVideo === 'video' ? (
        <ExternalVideo url={video as string} previewOnly={previewOnly} />
      ) : (
        <InternalVideo url={videoClip as string} />
      )}
    </View>
  );
}
