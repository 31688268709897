import React, { useState } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { TextInput, Checkbox } from 'react-native-paper';

import { SURVEY_FILE_DATA_SEPARATOR } from '../../../consts';
import { SurveyQuestion } from '../../../store/effects/api/types';
import ChooseFile, { FileUploaded } from '../../../components/ChooseFile';
import { useTheme } from '../../../theming';
import { useTranslation } from '../../../translations';

type Props = {
  questionType: SurveyQuestion['question_type'];
  questionId: number;
  onAnswered: (answerData: { answer: string; id: number }) => void;
  questionData?: { answer: string; options?: string[] };
};

type CheckboxOption = {
  name: string;
  status: 'checked' | 'unchecked' | 'indeterminate';
};

const checkboxToggleMap: {
  [key in CheckboxOption['status']]: CheckboxOption['status'];
} = {
  checked: Platform.OS === 'android' ? 'unchecked' : 'indeterminate',
  unchecked: 'checked',
  indeterminate: 'checked',
};

export default function AnswerField({
  questionData,
  questionType,
  onAnswered,
  questionId,
}: Props) {
  const texts = useTranslation('forms');
  const { colors } = useTheme();
  const [checkboxes, setCheckboxes] = useState<CheckboxOption[] | undefined>(
    questionData?.options?.map((option) => ({
      name: option,
      status: checkboxToggleMap.checked, // unchecked or indeterminate,
    })),
  );

  const handleOnChooseFile = (file: FileUploaded) => {
    const { uri, name } = file;
    onAnswered({
      id: questionId,
      answer: [uri, name].filter(Boolean).join(SURVEY_FILE_DATA_SEPARATOR),
    });
  };

  const handleOnResetFilePress = () => {
    onAnswered({ id: questionId, answer: '' });
  };

  const handleOnCheckboxPress = (index: number) => {
    setCheckboxes((oldCheckboxesState) => {
      const updatedCheckboxes = oldCheckboxesState?.map<CheckboxOption>(
        (oldCheckbox, i) => {
          if (i === index) {
            return {
              ...oldCheckbox,
              status: checkboxToggleMap[oldCheckbox.status], //toggle
            };
          }
          return oldCheckbox;
        },
      );
      onAnswered({
        answer:
          updatedCheckboxes
            ?.filter((checkbox) => checkbox.status === 'checked')
            .map((checkbox) => checkbox.name)
            .join(', ') ?? '',
        id: questionId,
      });
      return updatedCheckboxes;
    });
  };

  let component = null;
  switch (questionType) {
    case 'text': {
      component = (
        <TextInput
          mode="outlined"
          value={questionData?.answer ?? ''}
          placeholder={texts.forms.answerPlaceholder}
          onChangeText={(result: string) => {
            onAnswered({ answer: result, id: questionId });
          }}
        />
      );
      break;
    }
    case 'menu': {
      component = (
        <View
          style={[styles.menuQuestionWrapper, { borderColor: colors.text }]}
        >
          {checkboxes?.map((option, i) => (
            <Checkbox.Item
              theme={{
                colors: { primary: colors.text },
              }}
              color={colors.primary}
              style={styles.checkboxItem}
              key={i}
              status={option.status}
              label={option.name}
              onPress={() => {
                handleOnCheckboxPress(i);
              }}
            />
          )) ?? null}
        </View>
      );
      break;
    }
    case 'file':
      component = (
        <ChooseFile
          onChooseFile={handleOnChooseFile}
          onResetPress={handleOnResetFilePress}
        />
      );
      break;
    default:
      component = null;
  }
  return component;
}

const styles = StyleSheet.create({
  checkboxItem: {
    paddingHorizontal: 0, // padding are reversed when 'row-reverse' is used so we are reseting it on both sides
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },

  menuQuestionWrapper: {
    borderWidth: 1,
    marginTop: 8,
  },
});
