import React, { useState, useEffect, useContext, useRef } from 'react';
import { StyleSheet, ScrollView, View, Platform } from 'react-native';
import { Text, TextInput, ActivityIndicator } from 'react-native-paper';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import { Video } from 'expo-av';

import Button from '../../../components/Button';
import { useTheme } from '../../../theming';

import { useTranslation } from '../../../translations';
import Icon from '../../../components/Icon';
import { DataLoadStatus } from '../../../store/types';
import { stdAlert } from '../../../components/alert';
import AutoscaleImage from '../../../components/AutoscaleImage';
import {
  checkCameraRollPermissionForIOS,
  getImageSrc,
} from '../../../components/UserMedia/imageUtils';
import { DEFAULT_AVATAR_IMAGE, DEFAULT_VIDEO_THUMBNAIL } from '../../../consts';
import DataContext from '../DataContext';
import PremiumFeatureGate from '../../../components/PremiumFeatureGate';
import { getVideoThumbnail } from '../../../components/UserMedia/videoUtils';

export default function ChangeProfilePhotoVideoScreen() {
  const {
    data,
    status,
    updateProfilePhoto,
    updateProfileVideo,
    updateProfileVideoLink,
    hasPolicy,
  } = useContext(DataContext.Context);
  const isVideoUpdated = useRef(false);
  const isVideoLinkUpdated = useRef(false);
  const { colors } = useTheme();
  const texts = useTranslation('userMedia', 'common', 'errors', 'edit');
  const [hasPermission, setHasPermission] = useState<boolean | null>(
    Constants?.platform?.ios !== undefined ? null : true,
  );
  const [initialVideoPreview, setInitialVideoPreview] = useState<string | null>(
    null,
  );
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [imgUri, setImgUri] = useState<string | null>(null);
  const [videoUri, setVideoUri] = useState<string | null>(null);
  const [imgPreview, setImgPreview] = useState<string | null>(null);
  const [videoPreview, setVideoPreview] = useState<string | null>(null);
  const [videoLink, setVideoLink] = useState<string>(
    data ? data.video ?? '' : '',
  );

  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    (async () => {
      if (status === DataLoadStatus.SUCCESS && data !== null) {
        setIsPreviewLoading(true);
        const initialPreview = await getImageSrc({
          pictureUrl: data.picture_url,
          video: data.video,
          pictureOrVideo: data.picture_or_video,
        });
        setImgPreview(initialPreview ?? null);
        setVideoPreview(null);
        if (data.picture_or_video === 'video_clip') {
          if (data.video_clip_url !== null) {
            setImgPreview(null);
            setVideoPreview(data.video_clip_url);
            isVideoUpdated.current = true;
          } else if (data.picture_url !== null) {
            setImgUri(data.picture_url);
            setVideoPreview(null);
            isVideoUpdated.current = false;
            isVideoLinkUpdated.current = false;
          } else if (data.picture_url === null && data.video === null) {
            setImgPreview(DEFAULT_AVATAR_IMAGE);
            setVideoPreview(null);
          }
        } else if (data.picture_or_video === 'picture') {
          if (data.picture_url !== null) {
            setImgUri(data.picture_url);
            setVideoPreview(null);
            isVideoUpdated.current = false;
            isVideoLinkUpdated.current = false;
          } else if (data.video !== null) {
            setInitialVideoPreview(initialPreview ?? DEFAULT_VIDEO_THUMBNAIL);
            isVideoUpdated.current = true;
          } else if (data.picture_url === null && data.video === null) {
            setImgPreview(DEFAULT_AVATAR_IMAGE);
            setVideoPreview(null);
          }
        }
        setIsPreviewLoading(false);
      }
    })();
  }, [status, data]);

  const handleAddingPhoto = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission === true) {
      setHasPermission(true);
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.cancelled) {
        setImgUri(result.uri);
        isVideoUpdated.current = false;
        setImgPreview(result.uri);
        setVideoPreview(null);
        setVideoLink('');
      }
    } else {
      setHasPermission(false);
    }
  };

  const handleTakingPhoto = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission) {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();
      if (status === 'granted') {
        setHasPermission(true);
        const result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });

        if (!result.cancelled) {
          setImgUri(result.uri);
          isVideoUpdated.current = false;
          setImgPreview(result.uri);
          setVideoPreview(null);
          setVideoLink('');
        }
        return;
      }
    }
    setHasPermission(false);
  };

  const handleAddingVideo = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission === true) {
      setHasPermission(true);
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Videos,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.cancelled) {
        setVideoUri(result.uri);
        isVideoUpdated.current = true;
        setImgPreview(null);
        setVideoPreview(result.uri);
        setVideoLink('');
      }
    } else {
      setHasPermission(false);
    }
  };

  const handleTakingVideo = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission) {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();
      if (status === 'granted') {
        setHasPermission(true);
        const result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Videos,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });

        if (!result.cancelled) {
          setVideoUri(result.uri);
          isVideoUpdated.current = true;
          setImgPreview(null);
          setVideoPreview(result.uri);
          setVideoLink('');
        }
        return;
      }
    }
    setHasPermission(false);
  };

  const handleSave = async () => {
    let success = false;
    if (isVideoUpdated.current === true && videoUri !== null) {
      setIsUpdatingData(true);
      if (videoUri !== null) {
        success = await updateProfileVideo(videoUri);
      }
    } else if (isVideoLinkUpdated.current === true && videoLink !== null) {
      setIsUpdatingData(true);
      if (videoLink !== null) {
        success = await updateProfileVideoLink(videoLink);
      }
    } else if (imgUri !== null) {
      setIsUpdatingData(true);
      success = await updateProfilePhoto(imgUri);
    }
    if (success) {
      stdAlert(texts.edit.updateSuccess);
      if (isVideoUpdated.current === true) {
        setImgUri(null);
        setVideoUri(null);
        setInitialVideoPreview(imgPreview);
      }
    } else {
      stdAlert(texts.errors.unexpected);
    }

    setIsUpdatingData(false);
  };

  const handleChangeVideoLink = async (newVideoLink: string) => {
    setVideoLink(newVideoLink);
    if (newVideoLink !== '') {
      setIsPreviewLoading(true);
      const thumbnail = await getVideoThumbnail(newVideoLink);
      if (thumbnail === '') {
        setImgPreview(imgUri !== null ? imgUri : null);
        setVideoPreview(null);
        setIsPreviewLoading(false);
        return;
      } else {
        setImgPreview(thumbnail);
        setVideoPreview(null);
        setIsPreviewLoading(false);
        isVideoLinkUpdated.current = true;
        return;
      }
    }
    if (imgUri !== null) {
      setImgPreview(imgUri);
      setVideoPreview(null);
    } else {
      setImgPreview(null);
      setVideoPreview(null);
    }
    isVideoLinkUpdated.current = false;
  };

  if (hasPermission === false) {
    return (
      <>
        {!isPreviewLoading ? (
          <AutoscaleImage
            uri={imgPreview ?? initialVideoPreview}
            style={styles.imagePreview}
            resizeMode="contain"
          />
        ) : (
          <View style={styles.imagePreviewLoaderWrapper}>
            <ActivityIndicator />
          </View>
        )}
        <Text>{texts.userMedia.noCameraPermission}</Text>
        <View style={styles.buttonsWrapper}>
          <TextInput mode="outlined" label={texts.userMedia.insertLink} />
          <Button
            style={styles.saveButton}
            onPress={handleSave}
            mode="contained"
          >
            {texts.edit.save}
          </Button>
        </View>
      </>
    );
  }

  return (
    <>
      {status === DataLoadStatus.SUCCESS && data !== null ? (
        <ScrollView
          contentContainerStyle={styles.scrollViewContentContainerStyle}
        >
          <View style={styles.instructionWrapper}>
            <Text>{texts.userMedia.changePhoto}</Text>
          </View>
          {!isPreviewLoading ? (
            <>
              {imgPreview !== null && (
                <AutoscaleImage
                  uri={imgPreview ?? initialVideoPreview}
                  style={styles.imagePreview}
                  resizeMode="contain"
                />
              )}
              {videoPreview !== null && (
                <Video
                  source={{ uri: videoPreview }}
                  rate={1.0}
                  volume={1.0}
                  isMuted
                  resizeMode="cover"
                  shouldPlay
                  isLooping
                  style={{ width: 200, height: 200 }}
                />
              )}
            </>
          ) : (
            <View style={styles.imagePreviewLoaderWrapper}>
              <ActivityIndicator />
            </View>
          )}
          <View style={styles.buttonsWrapper}>
            <Button mode="outlined" onPress={handleAddingPhoto}>
              {texts.userMedia.uploadPhoto}
            </Button>
            {!isWeb && (
              <Button
                style={styles.button}
                onPress={handleTakingPhoto}
                mode="outlined"
                icon={() => (
                  <Icon
                    name="camera"
                    style={[styles.cameraIcon, { color: colors.text }]}
                  />
                )}
              >
                {texts.userMedia.takeNewPhoto}
              </Button>
            )}
            <View style={styles.photoVideoDivider}>
              <Text>{texts.common.orDivider}</Text>
            </View>
            <PremiumFeatureGate alwaysClickable={hasPolicy('update_video')}>
              <Button mode="outlined" onPress={handleAddingVideo}>
                {texts.userMedia.uploadVideo}
              </Button>
            </PremiumFeatureGate>
            {!isWeb && (
              <>
                <View style={styles.button} />
                <PremiumFeatureGate alwaysClickable={hasPolicy('update_video')}>
                  <Button
                    onPress={handleTakingVideo}
                    mode="outlined"
                    icon={() => (
                      <Icon
                        name="video"
                        style={[styles.cameraIcon, { color: colors.text }]}
                      />
                    )}
                  >
                    {texts.userMedia.takeNewVideo}
                  </Button>
                </PremiumFeatureGate>
              </>
            )}
            <View style={styles.photoVideoDivider}>
              <Text>{texts.common.orDivider}</Text>
            </View>
            <TextInput
              mode="outlined"
              value={videoLink}
              onChangeText={handleChangeVideoLink}
              label={texts.userMedia.insertLink}
            />
            <Button
              loading={isUpdatingData}
              style={styles.saveButton}
              onPress={handleSave}
              mode="contained"
            >
              {texts.edit.save}
            </Button>
          </View>
        </ScrollView>
      ) : status === DataLoadStatus.ERROR ? (
        <Text>{texts.errors.unexpected}</Text>
      ) : (
        <ActivityIndicator />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    paddingVertical: 24,
  },
  scrollViewContentContainerStyle: {
    alignItems: 'center',
  },
  buttonsWrapper: {
    marginTop: 20,
    alignSelf: 'stretch',
  },
  button: {
    marginTop: 16,
  },
  saveButton: {
    marginTop: 24,
  },
  cameraIcon: {
    fontSize: 24,
  },
  photoVideoDivider: {
    alignItems: 'center',
    padding: 8,
  },
  imagePreview: {
    width: 300,
    height: 200,
  },

  imagePreviewLoaderWrapper: {
    width: 300,
    height: 200,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
