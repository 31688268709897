import React, { useEffect, useState, useRef } from 'react';
import { Image, LayoutChangeEvent } from 'react-native';

type Props = Omit<React.ComponentProps<typeof Image>, 'source'> & {
  uri?: string | null;
};

/**
 * The component display an image with 100% size of the container and height adjusted to image aspect ratio
 */

export default function AutoscaleImage({ style, uri, ...rest }: Props) {
  const isMounted = useRef(false);
  const [size, setSize] = useState<{ width: number; height: number } | null>(
    null,
  );

  const [layoutWidth, setLayoutWidth] = useState<number | null>(null);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
    if (uri !== undefined && uri !== null) {
      Image.getSize(
        uri,
        (width: number, height: number) => {
          if (isMounted.current) {
            setSize({ width, height });
          }
        },
        () => undefined,
      );
    }
    return () => {
      isMounted.current = false;
    };
  }, [uri]);

  const handleLayout = (event: LayoutChangeEvent) => {
    setLayoutWidth(event.nativeEvent.layout.width);
  };

  if (uri === undefined || uri === null) {
    return null;
  }

  const heightStyle =
    layoutWidth !== null && size !== null
      ? { height: (size.height / size.width) * layoutWidth }
      : undefined;
  return (
    <Image
      style={[{ width: '100%' }, heightStyle, style]}
      // Do not display the image until size is computed to avoid flickering
      source={{ uri: heightStyle !== undefined ? uri : undefined }}
      {...rest}
      onLayout={handleLayout}
      accessibilityIgnoresInvertColors
    />
  );
}
