import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { TextInput } from 'react-native-paper';

import Button from '../../components/Button';
import { stdAlert } from '../../components/alert';
import { useTranslation } from '../../translations';

export type ResetPasswordFormData = {
  password: string;
  passwordConfirmation: string;
};

type Props = {
  onSubmit: (resetPasswordData: ResetPasswordFormData) => void;
  isProcessing: boolean;
};

export default function ResetPasswordForm({ onSubmit, isProcessing }: Props) {
  const texts = useTranslation('forgotPassword', 'forms');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

  function validatePasswordConfirmation() {
    return newPassword === newPasswordConfirmation;
  }

  const handleSavePress = () => {
    const arePasswordsTheSame = validatePasswordConfirmation();
    if (!arePasswordsTheSame) {
      stdAlert(texts.forms.passwordsNotMatch);
      return;
    }
    onSubmit({
      password: newPassword,
      passwordConfirmation: newPasswordConfirmation,
    });
  };

  return (
    <>
      <TextInput
        mode="outlined"
        secureTextEntry={true}
        textContentType="oneTimeCode"
        autoCapitalize="none"
        style={styles.input}
        label={texts.forgotPassword.newPasswordLabel}
        value={newPassword}
        onChangeText={setNewPassword}
      />
      <TextInput
        mode="outlined"
        autoCapitalize="none"
        secureTextEntry={true}
        textContentType="oneTimeCode"
        style={styles.input}
        label={texts.forgotPassword.newPasswordConfirmLabel}
        value={newPasswordConfirmation}
        onChangeText={setNewPasswordConfirmation}
      />
      <Button
        loading={isProcessing}
        style={styles.buttonSave}
        mode="contained"
        onPress={handleSavePress}
      >
        {texts.forgotPassword.save}
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  buttonSave: {
    marginVertical: 20,
  },
  input: {
    marginVertical: 5,
  },
});
