import React from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';

import { SocialMedias } from '../store/effects/api/types';
import { useTheme } from '../theming';
import { openLink } from '../utils/buttonActions';

import IconButton from './IconButton';

type SocialMediasWithIndex = SocialMedias & { [key: string]: string | null };

type Props = {
  socialMedias: SocialMediasWithIndex;
  iconSize?: number;
  style?: StyleProp<ViewStyle>;
};

const ICON_SIZE = 22;
const PADDING_SIZE = 4;

type SocialMediasName =
  | 'facebook'
  | 'instagram'
  | 'twitter'
  | 'youtube'
  | 'linkedin'
  | 'vimeo';

export default function SocialMediaButtons({
  socialMedias,
  iconSize = ICON_SIZE,
  style,
}: Props) {
  const { colors } = useTheme();
  return (
    <View style={[styles.socialButtonsWrapper, style]}>
      {Object.keys(socialMedias).map((socialMediaKey) => {
        if (socialMedias[socialMediaKey] === null) {
          return null;
        }
        const socialMediaName = socialMediaKey
          .split('social_')
          .pop() as SocialMediasName;
        return (
          <IconButton
            key={socialMediaName}
            icon={socialMediaName}
            onPress={() => {
              openLink(socialMedias[socialMediaKey] as string);
            }}
            color={colors.black}
            style={[
              styles.socialMediaButton,
              {
                backgroundColor: colors.text,
                borderRadius: (iconSize + PADDING_SIZE * 2) / 2,
                padding: PADDING_SIZE,
              },
            ]}
            size={iconSize}
          />
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  socialButtonsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  socialMediaButton: {
    marginHorizontal: 4,
  },
});
