import React, { useContext } from 'react';
import {
  StyleSheet,
  View,
  ScrollView,
  ViewStyle,
  StyleProp,
} from 'react-native';
import { ActivityIndicator, Text } from 'react-native-paper';

import ScreenWrapper from '../../../components/ScreenWrapper';
import DataContext from '../../../screens/Profile/DataContext';
import { ButtonType, ProfileDetails } from '../../../store/effects/api/types';
import { DataLoadStatus } from '../../../store/types';
import { useTranslation } from '../../../translations';
import Header from '../Header';

type Props = {
  children: (
    buttonData: ButtonType,
    profileDetails: ProfileDetails,
  ) => React.ReactNode;
  buttonIndex?: number;
  style?: StyleProp<ViewStyle>;
};

export default function DataLoaderWrapper({ children, buttonIndex }: Props) {
  const texts = useTranslation('errors');
  const { data, status } = useContext(DataContext.Context);
  const buttonData =
    buttonIndex !== undefined ? data?.buttons[buttonIndex] : undefined;
  return (
    <ScreenWrapper noPadding>
      {status === DataLoadStatus.SUCCESS &&
      data !== null &&
      buttonData !== undefined ? (
        <ScrollView
          style={styles.fullWidth}
          contentContainerStyle={styles.scrollViewContentContainerStyles}
          showsVerticalScrollIndicator={false}
        >
          <Header
            background={buttonData.background_url ?? data.title_bg_image_url}
          >
            {buttonData.label}
          </Header>
          <ScreenWrapper.Content style={[styles.fullWidth, styles.container]}>
            {children(buttonData, data)}
          </ScreenWrapper.Content>
        </ScrollView>
      ) : status === DataLoadStatus.ERROR ? (
        <Text>{texts.errors.unexpected}</Text>
      ) : (
        <View style={styles.loaderWrapper}>
          <ActivityIndicator />
        </View>
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  loaderWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  scrollViewContentContainerStyles: {
    flexGrow: 1,
  },
  container: {
    paddingHorizontal: 0,
  },
});
