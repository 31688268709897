import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

import Graphics from '../../../../../assets/undraw_email.svg';

import { stdAlert } from '../../../../components/alert';
import { useTranslation } from '../../../../translations';
import EditButtonsScreenWrapper from '../EditButtonsScreenWrapper';

import EditEmailsForm from './EditEmailsForm';
import type { EmailButtonType } from './EditEmailsForm';

export default function EditEmailButtonsScreen() {
  const texts = useTranslation('editProfileButtons', 'errors');

  return (
    <EditButtonsScreenWrapper>
      {(data, saveProfileButtons) => {
        const handleOnSubmit = async (emailsButtonsData: EmailButtonType[]) => {
          const preparedData = emailsButtonsData.map((emailButtonData) => {
            return {
              id: emailButtonData.id,
              data: emailButtonData.email,
            };
          });
          const result = await saveProfileButtons(preparedData, 'email');
          if (result) {
            stdAlert(texts.editProfileButtons.updateSuccess);
          } else {
            stdAlert(texts.errors.unexpected);
          }
        };
        const emailButtons = data.buttons.filter(
          (button) => button.data_type === 'email',
        );
        return (
          <>
            <View style={styles.instructionWrapper}>
              <Text style={styles.instructionText}>
                {texts.editProfileButtons.editEmailButtonsInstruction}
              </Text>
            </View>
            <View style={styles.graphicsWrapper}>
              <Graphics width={300} height={200} />
            </View>
            <EditEmailsForm
              onSubmit={handleOnSubmit}
              initialEmailsData={emailButtons.map(({ id, data, label }) => ({
                id,
                buttonName: label,
                email: data,
              }))}
            />
          </>
        );
      }}
    </EditButtonsScreenWrapper>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  graphicsWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  instructionText: {
    opacity: 0.8,
    textAlign: 'center',
  },
});
