import { Action } from 'overmind';
import { DataLoadStatus } from '../../store/types';

export const getTemplateSharedProfiles: Action<
  { templateId: string },
  Promise<void>
> = async ({ state, effects }, { templateId }) => {
  const { templates } = state;
  templates.profiles.status = DataLoadStatus.LOADING;
  const profilesListResult = await effects.api.getTemplateSharedProfiles(
    templateId,
  );
  if (profilesListResult.success) {
    templates.templateId = templateId;
    templates.listTitle = profilesListResult.success.data.list_title;
    templates.topBanner = profilesListResult.success.data.top_banner_url;
    templates.profiles.data = profilesListResult.success.data.profiles;
    templates.profiles.status = DataLoadStatus.SUCCESS;
    return;
  }
  templates.profiles.status = DataLoadStatus.ERROR;
};
