import React, { useContext, useState, useRef, useEffect } from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Animated,
  Easing,
  Platform,
} from 'react-native';

import { ActivityIndicator } from 'react-native-paper';

import StyledWebView from '../../components/StyledWebView';
import * as templates from '../../components/StyledWebView/templates';

import ScreenWrapper from '../../components/ScreenWrapper';
import { ButtonType } from '../../store/effects/api/types';
import { useTheme } from '../../theming';
import DataContext from '../Profile/DataContext';
import LogoHeader from './LogoHeader';
import { RouteProp } from './index';

const prepareContent = (
  infoPage: ButtonType,
  paddingVertical: number,
  paddingHorizontal: number,
) => {
  return `
    ${
      infoPage.data !== null
        ? templates.content({
            content: infoPage.data,
            paddingHorizontal,
            paddingVertical,
          })
        : ''
    }
    ${
      typeof infoPage?.information_data?.info_video === 'string'
        ? templates.iframeVideo({ src: infoPage?.information_data?.info_video })
        : ''
    }
    ${
      typeof infoPage?.information_data?.info_video_text === 'string'
        ? templates.content({
            content: infoPage?.information_data?.info_video_text,
            paddingHorizontal,
            paddingVertical,
          })
        : ''
    }
    ${
      typeof infoPage?.information_data?.info_image_url === 'string'
        ? templates.image({ src: infoPage?.information_data?.info_image_url })
        : ''
    }
    ${
      typeof infoPage?.information_data?.info_image_text === 'string'
        ? templates.content({
            content: infoPage?.information_data?.info_image_text,
            paddingHorizontal,
            paddingVertical,
          })
        : ''
    }
  `;
};

type Props = {
  route: RouteProp<'DBC-InfoPage'>;
};

export default function InfoPage({ route }: Props) {
  const opacity = useRef<Animated.Value>(new Animated.Value(0));
  const { data } = useContext(DataContext.Context);
  const infoPageData = data?.buttons[route.params.buttonIndex];
  const { colors, spacings } = useTheme();
  const [webviewLoaded, setWebviewLoaded] = useState(false);

  useEffect(() => {
    if (webviewLoaded) {
      Animated.timing(opacity.current, {
        duration: 400,
        easing: Easing.inOut(Easing.quad),
        toValue: 1,
        useNativeDriver: true,
      }).start();
    }
  }, [webviewLoaded]);

  return (
    <ScreenWrapper style={styles.container} noPadding>
      {data !== null && infoPageData !== undefined ? (
        <>
          <Animated.ScrollView
            style={[styles.fullWidth, { opacity: opacity.current }]}
            contentContainerStyle={[styles.center]}
          >
            <LogoHeader
              title={infoPageData.label}
              titleBackground={
                infoPageData.background_url ?? data.title_bg_image_url
              }
              logoBanner={data.logo_url}
            />
            <View style={[styles.fullWidth, styles.center]}>
              <StyledWebView
                width={
                  Platform.OS === 'web'
                    ? '100%'
                    : `${Dimensions.get('window').width}px`
                }
                font={data.font}
                colors={colors}
                html={prepareContent(
                  infoPageData,
                  spacings.content.horizontal,
                  spacings.content.vertical,
                )}
                onLoad={() => {
                  setWebviewLoaded(true);
                }}
              />
            </View>
          </Animated.ScrollView>
          {webviewLoaded === false ? (
            <View
              style={[
                styles.webviewLoadingIndicator,
                { backgroundColor: colors.background },
              ]}
            >
              <ActivityIndicator />
            </View>
          ) : null}
        </>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  center: {
    alignItems: 'center',
  },
  webviewLoadingIndicator: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
