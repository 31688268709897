import React, { useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Text } from 'react-native-paper';

import { useActions } from '../../store';
import { useTranslation } from '../../translations';
import Graphics from '../../../assets/undraw_new_password.svg';
import { stdAlert } from '../../components/alert';
import { UserPasswordData } from '../../store/effects/api/types';
import PasswordForm from './PasswordForm';

export default function PasswordScreen() {
  const texts = useTranslation('edit', 'errors');
  const { user } = useActions();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (userPasswordData: UserPasswordData) => {
    setIsLoading(true);
    const result = await user.updateUserPasswordData({
      userPasswordData: userPasswordData,
    });
    setIsLoading(false);
    if (result === true) {
      stdAlert(texts.edit.updateSuccess);
      return true;
    } else {
      stdAlert(texts.errors.unexpected);
      return false;
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView
        style={styles.scrollViewStyles}
        contentContainerStyle={styles.scrollViewContentContainerStyles}
      >
        <View style={styles.instructionWrapper}>
          <Text>{texts.edit.editPassword}</Text>
        </View>
        <View style={styles.graphicsWrapper}>
          <Graphics width={200} height={150} />
        </View>
        <View style={styles.passwordFormWrapper}>
          <PasswordForm isProcessing={isLoading} onSubmit={handleSubmit} />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  instructionWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  scrollViewStyles: {
    width: '100%',
  },
  scrollViewContentContainerStyles: {
    justifyContent: 'center',
  },
  passwordFormWrapper: {
    paddingTop: 24,
    alignSelf: 'stretch',
  },
  graphicsWrapper: {
    alignItems: 'center',
  },
});
