import React, { useState, useEffect } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';

import Graphics from '../../../assets/undraw_new_password.svg';
import ScreenWrapper from '../../components/ScreenWrapper';
import { stdAlert } from '../../components/alert';
import { useTranslation } from '../../translations';
import { useActions } from '../../store';

import ResetPasswordForm, { ResetPasswordFormData } from './ResetPasswordForm';
import { RouteProp, NavigationProp } from './index';

type Props = {
  route: RouteProp<'ResetPassword'>;
  navigation: NavigationProp<'ResetPassword'>;
};

export default function ResetPasswordScreen({ route, navigation }: Props) {
  const texts = useTranslation('forgotPassword', 'errors');
  const {
    params: { reset_password_token },
  } = route;
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState<boolean | null>(null);
  const { user: userActions } = useActions();

  useEffect(() => {
    (async () => {
      const isResetTokenValid = await userActions.isResetPasswordTokenValid({
        resetPasswordToken: reset_password_token,
      });
      if (!isResetTokenValid) {
        setIsLinkExpired(true);
      } else {
        setIsLinkExpired(false);
      }
    })();
  }, [reset_password_token]);

  const handleOnSubmit = async (
    resetPasswordFormData: ResetPasswordFormData,
  ) => {
    setIsProcessing(true);
    const result = await userActions.resetPassword({
      resetPasswordData: {
        password: resetPasswordFormData.password,
        password_confirmation: resetPasswordFormData.passwordConfirmation,
        reset_password_token,
      },
    });
    if (result) {
      stdAlert(texts.forgotPassword.resetPasswordSuccess);
      navigation.replace('Welcome');
    } else {
      stdAlert(texts.errors.unexpected);
    }
    setIsProcessing(false);
  };
  return (
    <ScreenWrapper noPadding>
      {isLinkExpired === false ? (
        <ScrollView
          style={styles.scrollViewStyles}
          contentContainerStyle={styles.scrollViewContentContainerStyles}
        >
          <ScreenWrapper.Content>
            <View style={styles.instructionWrapper}>
              <Text style={styles.instructionText}>
                {texts.forgotPassword.resetPasswordInstruction}
              </Text>
            </View>
            <View style={styles.graphicsWrapper}>
              <Graphics width={300} height={200} />
            </View>
            <ResetPasswordForm
              onSubmit={handleOnSubmit}
              isProcessing={isProcessing}
            />
          </ScreenWrapper.Content>
        </ScrollView>
      ) : isLinkExpired === true ? (
        <Text>Reset link expired</Text>
      ) : (
        <View style={styles.loaderWrapper}>
          <ActivityIndicator />
        </View>
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  instructionText: {
    opacity: 0.8,
    textAlign: 'center',
  },
  scrollViewStyles: {
    width: '100%',
  },
  scrollViewContentContainerStyles: {
    flexGrow: 1,
  },
  graphicsWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  loaderWrapper: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});
