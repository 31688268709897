import Constants from 'expo-constants';
import * as ImagePicker from 'expo-image-picker';

import { getVideoThumbnail } from './videoUtils';

export const getImageSrc = async (
  userData: {
    pictureUrl: string | null;
    video: string | null;
    pictureOrVideo?: 'picture' | 'video' | 'video_clip' | null;
  } | null,
) => {
  if (userData === null) {
    return undefined;
  }
  const { pictureUrl, video, pictureOrVideo } = userData;
  if (
    pictureOrVideo === null ||
    pictureOrVideo === undefined ||
    pictureOrVideo === 'video'
  ) {
    if (video !== null && video !== '') {
      return await getVideoThumbnail(video);
    }
    if (pictureUrl !== null) {
      return pictureUrl;
    }
  } else if (pictureOrVideo === 'picture') {
    if (pictureUrl !== null) {
      return pictureUrl;
    }
    if (video !== null) {
      return await getVideoThumbnail(video);
    }
  }

  return undefined;
};

export async function checkCameraRollPermissionForIOS(
  permissionChecked: boolean,
) {
  if (!permissionChecked && Constants?.platform?.ios) {
    const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
    return status === 'granted';
  }
  return true;
}

export function getPhotoInfo(
  photoUri: string,
): { type: string; filename: string } | null {
  const filename = photoUri.split('/').pop();

  if (filename !== undefined) {
    const match = /\.(\w+)$/.exec(filename);
    const type = match ? `image/${match[1]}` : `image`;
    return { type, filename };
  }

  return null;
}
