import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Image,
  ViewStyle,
  StyleProp,
} from 'react-native';
import { Text, useTheme } from 'react-native-paper';

type Props = {
  backgroundUrl: string | null;
  backgroundColor: string | null;
  label: string | null;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
};

export default function ButtonBase({
  onPress,
  label,
  backgroundColor,
  backgroundUrl,
  style,
}: Props) {
  const theme = useTheme();

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.button,
        {
          backgroundColor: backgroundColor ?? theme.colors.background,
          borderRadius: theme.roundness,
        },
        style,
      ]}
    >
      <>
        <Image
          source={{ uri: backgroundUrl !== null ? backgroundUrl : undefined }}
          style={[
            styles.buttonBackground,
            {
              borderRadius: theme.roundness,
            },
          ]}
          accessibilityIgnoresInvertColors
        />
        <Text style={[styles.label, theme.fonts.medium]}>{label}</Text>
      </>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    borderWidth: 1,
    borderColor: 'white',
  },
  buttonBackground: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
  label: {
    textAlign: 'center',
    letterSpacing: 1,
    marginVertical: 17,
    zIndex: 1,
    fontSize: 14,
    textTransform: 'uppercase',
  },
});
