import React from 'react';
import { useNavigation } from '@react-navigation/core';

import { useTranslation } from '../../translations';
import { ScannedProfileMeta } from '../../store/effects/api/types';
import { useTheme } from '../../theming';
import { NavigationProp } from '../../MainNavigator';
import { getFormattedDate } from '../../utils/date';
import ProfilesListItem from '../../components/ProfilesListItem';

type Props = {
  item: ScannedProfileMeta;
};

export default function ScannedProfilesListItem({ item: profile }: Props) {
  const texts = useTranslation('profiles');
  const { colors } = useTheme();
  const navigation = useNavigation<NavigationProp<'ScannedProfiles'>>();

  const scannedAtDate = new Date(profile.scanned_at);
  const formattedDate = getFormattedDate(scannedAtDate);
  const dateText = `${texts.profiles.scannedDate}:  ${formattedDate}`;

  const handleOnPress = () => {
    navigation.navigate('DigitalBusinessCardStack', {
      profileID: profile.id,
    });
  };

  return (
    <ProfilesListItem
      profile={profile}
      onPress={handleOnPress}
      tileColor={colors.black}
      dateText={dateText}
    />
  );
}
