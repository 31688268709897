import { Action } from 'overmind';
import { Whitelabel } from '../effects/api/types';

export const setWhitelabel: Action<Whitelabel, void> = (
  { state },
  whitelabelData,
) => {
  state.whitelabel.customBgUrl = whitelabelData?.custom_bg_url;
  state.whitelabel.customPoweredByUrl = whitelabelData?.custom_powered_by_url;
  state.whitelabel.customTopBannerUrl = whitelabelData?.custom_top_banner_url;
  state.whitelabel.whiteLabelPrimaryColor =
    whitelabelData.white_label_primary_color;
};

export const reset: Action<void, void> = ({ state }) => {
  state.whitelabel = {
    customBgUrl: null,
    customPoweredByUrl: null,
    whiteLabelPrimaryColor: null,
    customTopBannerUrl: null,
  };
};
