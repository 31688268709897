import React, { createContext, useState, useEffect } from 'react';

import { InvitationData } from '../../store/effects/api/types';
import { DataLoadStatus } from '../../store/types';
import { useActions } from '../../store';
import { ThemeProvider, useTheme } from '../../theming';

type ContextData = {
  data: InvitationData | null;
  status: DataLoadStatus;
  invitationHash: string;
};

const InvitationDataContext = createContext<ContextData>({
  data: null,
  status: DataLoadStatus.UNKNOWN,
  invitationHash: '',
});

function Provider({
  children,
  invitationHash,
}: {
  children: React.ReactNode;
  invitationHash: string;
}) {
  const theme = useTheme();
  const [invitationData, setInvitationData] = useState<InvitationData | null>(
    null,
  );
  const [fetchState, setFetchState] = useState<DataLoadStatus>(
    DataLoadStatus.UNKNOWN,
  );
  const {
    onboarding: { getInvitationData },
  } = useActions();

  useEffect(() => {
    (async () => {
      setFetchState(DataLoadStatus.LOADING);
      const result = await getInvitationData(invitationHash);
      if (result !== undefined) {
        setInvitationData(result);
      }
      setFetchState(DataLoadStatus.SUCCESS);
    })();
  }, [invitationHash]);

  const themeWithWhitelabelColors = {
    ...theme,
    colors: {
      ...theme.colors,
      primary:
        invitationData?.template_white_label?.white_label_primary_color ??
        theme.colors.primary,
    },
  };

  return (
    <InvitationDataContext.Provider
      value={{
        data: invitationData,
        status: fetchState,
        invitationHash,
      }}
    >
      <ThemeProvider theme={themeWithWhitelabelColors}>
        {children}
      </ThemeProvider>
    </InvitationDataContext.Provider>
  );
}

export default {
  Provider,
  Context: InvitationDataContext,
};
