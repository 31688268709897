export const stdAlert = (
  title: string,
  desc?: string,
  onPress: () => void = () => undefined,
) => {
  alert(`${title}\n${desc === undefined ? '' : desc}`); // web alert is blocking
  if (onPress !== undefined) onPress();
};

export const binaryAlert = (
  title: string,
  desc?: string,
  onPositivePress: () => void = () => undefined,
  onNegativePress: () => void = () => undefined,
) => {
  const res = window.confirm(`${title}\n${desc === undefined ? '' : desc}`);
  if (res) onPositivePress();
  else onNegativePress();
};
