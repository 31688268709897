import React from 'react';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';

import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { useTheme } from '../../theming';
import { useTranslation } from '../../translations';

import { openLink } from 'utils/buttonActions';

type Props = {
  style: StyleProp<ViewStyle>;
  QRCodeUrl: string;
  loading: boolean;
};

export default function DownloadQRCodeButton({
  style,
  QRCodeUrl,
  loading,
}: Props) {
  const texts = useTranslation('profileHome');
  const { colors } = useTheme();
  const handleOnPress = () => {
    if (!loading) {
      openLink(QRCodeUrl);
    }
  };
  return (
    <Button
      loading={loading}
      contentStyle={styles.contentButton}
      onPress={handleOnPress}
      style={style}
      mode="outlined"
      icon={() => (
        <Icon name="qrcode" style={[styles.icon, { color: colors.text }]} />
      )}
    >
      {texts.profileHome.downloadQRCode}
    </Button>
  );
}

const styles = StyleSheet.create({
  contentButton: {
    paddingVertical: 4,
  },
  icon: {
    fontSize: 20,
  },
});
