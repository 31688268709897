import { OnInitialize, rehydrate, json } from 'overmind';

export const onInitialize: OnInitialize = async (
  { state, effects, actions },
  instance,
) => {
  //initialize api effect with token getter

  effects.api.initialize({
    getToken: () => state.user.token,
    logout: actions.user.logout,
  });

  // persist state in local storage
  instance.reaction(
    (state) => state,
    () => {
      // we access state from initialize params, because for some reason value passed to this function is undefined
      effects.persistGate.set(json(state));
    },
    {
      nested: true,
    },
  );
  // rehydrate state on initialize
  try {
    const previousState = await effects.persistGate.get();
    rehydrate(state, previousState || {});
  } catch (e) {
    console.error('State rehydration failed', e);
  }
};
