import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, TextInput } from 'react-native-paper';

import { useTheme } from '../../../../theming';
import Button from '../../../../components/Button';
import { useTranslation } from '../../../../translations';

type WebsiteURLButtonType = {
  websiteURL: string;
  buttonName: string;
  id: number;
};

export type { WebsiteURLButtonType };

type WebsitesData = {
  [key: number]: WebsiteURLButtonType & { changed: boolean };
};

type Props = {
  onSubmit: (websitesButtonsData: WebsiteURLButtonType[]) => void;
  initialWebsitesData: WebsiteURLButtonType[];
};

export default function EditWebsitesForm({
  initialWebsitesData,
  onSubmit,
}: Props) {
  const texts = useTranslation('editProfileButtons');
  const { colors } = useTheme();
  const [websitesData, setWebsitesData] = useState<WebsitesData>(
    initialWebsitesData.reduce((allWebsitesData, curr) => {
      return {
        ...allWebsitesData,
        [curr.id]: { ...curr, changed: false },
      };
    }, {}),
  );

  const handleOnSavePress = () => {
    const changedWebsitesButtonData = Object.values(websitesData).filter(
      (websiteButtonData) => websiteButtonData.changed,
    );
    onSubmit(changedWebsitesButtonData);
  };

  const handleOnChange = (id: number, newWebURL: string) => {
    setWebsitesData((oldWebsitesData) => {
      return {
        ...oldWebsitesData,
        [id]: {
          ...oldWebsitesData[id],
          websiteURL: newWebURL,
          changed: true,
        },
      };
    });
  };

  return (
    <>
      {Object.values(websitesData).map(({ websiteURL, buttonName, id }) => {
        return (
          <View key={id} style={[styles.section, { borderColor: colors.text }]}>
            <Text>{buttonName}</Text>
            <TextInput
              mode="outlined"
              value={websiteURL}
              placeholder={texts.editProfileButtons.websitePlaceholder}
              autoCapitalize="none"
              onChangeText={(newWebsiteURL) => {
                handleOnChange(id, newWebsiteURL);
              }}
            />
          </View>
        );
      })}
      <Button
        style={styles.buttonSave}
        mode="contained"
        onPress={handleOnSavePress}
      >
        {texts.editProfileButtons.save}
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  section: {
    marginVertical: 5,
  },
  buttonSave: {
    marginTop: 20,
  },
});
