/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from '../translations';

interface Params {
  company?: string | null;
  profileRole?: string | null;
}

export function useCompanyPositionLabel({
  company,
  profileRole,
}: Params): string | null {
  const texts = useTranslation('common');
  if (!company && !profileRole) {
    return null;
  }

  return company
    ? `${profileRole} ${texts.common.at} ${company}`
    : `${profileRole}`;
}
