import React from 'react';
import { StyleSheet, View, Image, Dimensions, Platform } from 'react-native';
import { Text } from 'react-native-paper';

import { useTheme } from '../../../theming';
import { WebRouteProp } from '../index';
import ExternalVideo from '../../../components/UserMedia/ExternalVideo';
import InternalVideo from '../../../components/UserMedia/InternalVideo';
import DataLoaderWrapper from './DataLoaderWrapper';

type Props = {
  route: WebRouteProp<'DBC-Description'>;
};

export default function DescriptionScreen({ route }: Props) {
  const { colors, spacings } = useTheme();

  return (
    <DataLoaderWrapper buttonIndex={route.params?.buttonIndex}>
      {({ elements }) => {
        return (
          <View
            style={[
              {
                backgroundColor: colors.darkGrey,
                paddingHorizontal: spacings.content.horizontal,
                paddingVertical: spacings.content.vertical,
              },
            ]}
          >
            <View>
              {elements !== null &&
                Array.isArray(elements) &&
                [...elements]
                  .sort((a, b) => a.order - b.order)
                  .map(({ data, data_type, id }) => {
                    if (data === null) {
                      return;
                    }

                    const Video =
                      data_type === 'video' ? InternalVideo : ExternalVideo;

                    if (data_type === 'text') {
                      return (
                        <Text style={styles.text} key={`${id}`}>
                          {data}
                        </Text>
                      );
                    } else if (data_type === 'photo') {
                      return (
                        <View style={styles.mediaWrapper} key={`${id}`}>
                          <Image
                            source={{
                              uri: data,
                            }}
                            accessibilityIgnoresInvertColors
                            resizeMode="cover"
                            style={styles.media}
                          />
                        </View>
                      );
                    } else if (
                      data_type === 'video' ||
                      data_type === 'video_url'
                    ) {
                      return (
                        <View style={styles.mediaWrapper} key={`${id}`}>
                          <Video url={data} videoStyles={styles.media} />
                        </View>
                      );
                    }
                  })}
            </View>
          </View>
        );
      }}
    </DataLoaderWrapper>
  );
}

const styles = StyleSheet.create({
  text: {
    paddingBottom: 20,
  },
  media: {
    flex: 1,
    minHeight: Platform.OS === 'web' ? 300 : Dimensions.get('window').width / 2,
  },
  mediaWrapper: {
    width: '100%',
    paddingBottom: 20,
  },
});
