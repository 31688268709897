import React from 'react';
import { StyleSheet } from 'react-native';

import AutoscaleImage from '../AutoscaleImage';

type Props = { url: string };

export default function Picture({ url }: Props) {
  return (
    <AutoscaleImage
      style={styles.picture}
      uri={url}
      accessibilityIgnoresInvertColors
    />
  );
}

const styles = StyleSheet.create({
  picture: {
    flex: 1,
    resizeMode: 'contain',
  },
});
