import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

import Button from '../../../../components/Button';
import { useTranslation } from '../../../../translations';
import ChooseFile, { FileUploaded } from '../../../../components/ChooseFile';

type PDFButtonType = {
  pdfUri?: string;
  pdfName?: string;
  buttonName: string;
  id: number;
};

export type { PDFButtonType };

type PDFsData = {
  [key: number]: PDFButtonType & { changed: boolean };
};

type Props = {
  initialPDFsData: PDFButtonType[];
  onSubmit: (PDFButtonsData: PDFButtonType[]) => void;
};

export default function EditPDFsForm({ initialPDFsData, onSubmit }: Props) {
  const texts = useTranslation('editProfileButtons');
  const [PDFsData, setPDFsData] = useState<PDFsData>(
    initialPDFsData.reduce((allPDFsData, curr) => {
      return {
        ...allPDFsData,
        [curr.id]: { ...curr, changed: false },
      };
    }, {}),
  );

  const handleOnSavePress = () => {
    const changedPDFButtonsData = Object.values(PDFsData).filter(
      (PDFButtonData) => PDFButtonData.changed,
    );
    onSubmit(changedPDFButtonsData);
  };

  const handleOnChange = (id: number, newPDF: FileUploaded) => {
    setPDFsData((oldPDFsData) => {
      return {
        ...oldPDFsData,
        [id]: {
          ...oldPDFsData[id],
          pdfUri: newPDF.uri,
          pdfName: newPDF.name,
          changed: true,
        },
      };
    });
  };

  const handleOnReset = (id: number) => {
    setPDFsData((oldPDFsData) => {
      return {
        ...oldPDFsData,
        [id]: {
          ...oldPDFsData[id],
          pdfUri: '',
          pdfName: '',
          changed: true,
        },
      };
    });
  };

  return (
    <>
      {Object.values(PDFsData).map(({ pdfUri, pdfName, buttonName, id }) => {
        return (
          <View key={id} style={styles.section}>
            <Text>{buttonName}</Text>
            <ChooseFile
              onChooseFile={(file) => {
                handleOnChange(id, file);
              }}
              onResetPress={() => handleOnReset(id)}
              type="application/pdf"
              initialFile={
                pdfName !== undefined && pdfUri !== undefined
                  ? { uri: pdfUri, name: pdfName }
                  : undefined
              }
            />
          </View>
        );
      })}
      <Button
        style={styles.buttonSave}
        mode="contained"
        onPress={handleOnSavePress}
      >
        {texts.editProfileButtons.save}
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  section: {
    marginVertical: 8,
  },
  buttonSave: {
    marginTop: 20,
  },
});
