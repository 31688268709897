import React, { useContext } from 'react';
import { StyleSheet, ScrollView, Image } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import ScreenWrapper from '../../../components/ScreenWrapper';

import LogoHeader from '../LogoHeader';
import DataContext from '../../Profile/DataContext';
import { RouteProp } from '../index';

import Gallery from './Gallery';

type Props = {
  route: RouteProp<'DBC-Gallery'>;
};

export default function GalleryScreen({ route }: Props) {
  const { data } = useContext(DataContext.Context);
  const galleryData = data?.buttons[route.params.buttonIndex];

  return (
    <ScreenWrapper style={styles.container} noPadding>
      {data !== null && galleryData !== undefined ? (
        <>
          {data.main_bg_image_url !== null ? (
            <Image
              source={{
                uri: data.main_bg_image_url,
              }}
              accessibilityIgnoresInvertColors
              style={styles.backgroundImage}
            />
          ) : null}
          <ScrollView style={styles.fullWidth}>
            <LogoHeader
              title={galleryData.label}
              titleBackground={
                galleryData.background_url ?? data.title_bg_image_url
              }
              logoBanner={data.logo_url}
            />
            <ScreenWrapper.Content style={styles.fullWidth}>
              <Gallery images={galleryData.gallery_images} />
            </ScreenWrapper.Content>
          </ScrollView>
        </>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    resizeMode: 'repeat',
  },
});
