import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

import Graphics from '../../../../../assets/undraw_phone.svg';
import { useTranslation } from '../../../../translations';
import EditButtonsScreenWrapper from '../EditButtonsScreenWrapper';
import { stdAlert } from '../../../../components/alert';

import EditPhonesForm from './EditPhonesForm';
import type { PhoneButtonType } from './EditPhonesForm';

export default function EditPhoneButtonsScreen() {
  const texts = useTranslation('editProfileButtons', 'errors');
  return (
    <EditButtonsScreenWrapper>
      {(data, saveProfileButtons) => {
        const handleOnSubmit = async (phonesButtonsData: PhoneButtonType[]) => {
          const preparedData = phonesButtonsData.map((phoneButtonData) => {
            return {
              data: phoneButtonData.phone,
              id: phoneButtonData.id,
            };
          });
          const result = await saveProfileButtons(preparedData, 'phone');
          if (result) {
            stdAlert(texts.editProfileButtons.updateSuccess);
          } else {
            stdAlert(texts.errors.unexpected);
          }
        };
        const phoneButtons = data.buttons.filter(
          (button) => button.data_type === 'phone',
        );
        return (
          <>
            <View style={styles.instructionWrapper}>
              <Text style={styles.instructionText}>
                {texts.editProfileButtons.editPhoneButtonsInstruction}
              </Text>
            </View>
            <View style={styles.graphicsWrapper}>
              <Graphics width={300} height={200} />
            </View>
            <EditPhonesForm
              onSubmit={handleOnSubmit}
              initialPhonesData={phoneButtons.map(({ id, data, label }) => ({
                id,
                buttonName: label,
                phone: data,
              }))}
            />
          </>
        );
      }}
    </EditButtonsScreenWrapper>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  graphicsWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  instructionText: {
    opacity: 0.8,
    textAlign: 'center',
  },
});
