import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

import Graphics from '../../../../../assets/undraw_website.svg';
import { useTranslation } from '../../../../translations';
import { stdAlert } from '../../../../components/alert';
import EditButtonsScreenWrapper from '../EditButtonsScreenWrapper';

import EditWebsitesForm from './EditWebsitesForm';
import type { WebsiteURLButtonType } from './EditWebsitesForm';

export default function EditWebsiteButtonsScreen() {
  const texts = useTranslation('editProfileButtons', 'errors');
  return (
    <EditButtonsScreenWrapper>
      {(data, saveProfileButtons) => {
        const handleOnSubmit = async (
          websitesButtonsData: WebsiteURLButtonType[],
        ) => {
          const preparedData = websitesButtonsData.map((websiteButtonData) => {
            return {
              id: websiteButtonData.id,
              data: websiteButtonData.websiteURL,
            };
          });
          const result = await saveProfileButtons(preparedData, 'url');
          if (result) {
            stdAlert(texts.editProfileButtons.updateSuccess);
          } else {
            stdAlert(texts.errors.unexpected);
          }
        };
        const websiteURLButtons = data.buttons.filter(
          (button) => button.data_type === 'url',
        );
        return (
          <>
            <View style={styles.instructionWrapper}>
              <Text style={styles.instructionText}>
                {texts.editProfileButtons.editWebsiteButtonsInstruction}
              </Text>
            </View>
            <View style={styles.graphicsWrapper}>
              <Graphics width={300} height={200} />
            </View>
            <EditWebsitesForm
              onSubmit={handleOnSubmit}
              initialWebsitesData={websiteURLButtons.map(
                ({ id, data, label }) => ({
                  id,
                  buttonName: label,
                  websiteURL: data,
                }),
              )}
            />
          </>
        );
      }}
    </EditButtonsScreenWrapper>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  graphicsWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  instructionText: {
    opacity: 0.8,
    textAlign: 'center',
  },
});
