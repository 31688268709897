/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect } from 'react';
import { StyleSheet, View, ScrollView, Platform } from 'react-native';
import { Text, TextInput, ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import * as ImagePicker from 'expo-image-picker';

import { Video } from 'expo-av';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Graphics from '../../../assets/undraw_upload_take_photo.svg';
import ScreenWrapper from '../../components/ScreenWrapper';
import { useTranslation } from '../../translations';
import { useTheme } from '../../theming';
import { stdAlert } from '../../components/alert';
import AutoscaleImage from '../../components/AutoscaleImage';
import { checkCameraRollPermissionForIOS } from '../../components/UserMedia/imageUtils';
import { useActions, useStore } from '../../store';
import { getVideoThumbnail } from '../../components/UserMedia/videoUtils';
import { DEFAULT_VIDEO_THUMBNAIL, DEFAULT_AVATAR_IMAGE } from '../../consts';
import { ScreenProps } from './index';

type Props = ScreenProps<'PhotoVideo'>;

export default function PhotoVideo({ navigation }: Props) {
  const texts = useTranslation('onboarding', 'userMedia', 'common', 'errors');
  const { colors } = useTheme();
  const {
    user,
    profiles: { fetchAndSetProfileBranding },
  } = useActions();
  const { profiles } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [videoLink, setVideoLink] = useState<string>('');
  const [preview, setPreview] = useState<string | null>(null);
  const [previewKind, setPreviewKind] = useState<
    'video' | 'image' | 'externalVideo' | null
  >(null);
  const [hasPermission, setHasPermission] = useState<boolean | null>(
    Constants?.platform?.ios !== undefined ? null : true,
  );

  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    if (previewKind !== 'externalVideo') {
      setVideoLink('');
    }
  }, [previewKind]);

  const handleSaveUploadedVideos = async () => {
    if (!preview) {
      return;
    }
    switch (previewKind) {
      case 'video':
        return user.updateOnboardedUserVideo({
          videoUri: preview,
          profileID: profiles.mainProfileID!,
        });
      case 'externalVideo':
        return user.updateOnboardedUserVideoLink({
          videoLink: videoLink,
          profileID: profiles.mainProfileID!,
        });
      case 'image':
        return user.updateOnboardedUserPhoto({
          photoUri: preview,
          profileID: profiles.mainProfileID!,
        });
    }
  };

  const handleGoToDashboardPress = async () => {
    if (!profiles.mainProfileID) {
      return;
    }
    setIsLoading(true);
    await handleSaveUploadedVideos();
    const result = await user.fetchAndSetUserAccountData();
    if (result === true) {
      if (profiles.mainProfileID !== null) {
        await fetchAndSetProfileBranding(profiles.mainProfileID);
      }
      navigation.replace('Dashboard');
    } else {
      stdAlert(texts.errors.unexpected);
    }
    setIsLoading(false);
  };

  const handleChangeVideoLink = async (newVideoLink: string) => {
    setVideoLink(newVideoLink);
    if (newVideoLink !== '') {
      setIsLoading(true);
      const thumbnail = await getVideoThumbnail(newVideoLink);
      setIsLoading(false);
      setPreviewKind('externalVideo');
      setPreview(thumbnail ? thumbnail : null);
    }
  };

  const handleAddingPhoto = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission === true) {
      setHasPermission(true);
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      setPreviewKind('image');
      setPreview(!result.cancelled ? result.uri : null);
    } else {
      setHasPermission(false);
    }
  };

  const handleTakingPhoto = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission === true) {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();
      if (status === 'granted') {
        setHasPermission(true);
        const result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
        setPreviewKind('image');
        setPreview(!result.cancelled ? result.uri : null);
        return;
      }
    }
    setHasPermission(false);
  };

  const handleAddingVideo = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission === true) {
      setHasPermission(true);
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Videos,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      setPreviewKind('video');
      setPreview(!result.cancelled ? result.uri : null);
    } else {
      setHasPermission(false);
    }
  };

  const handleTakingVideo = async () => {
    const grantedPermission = await checkCameraRollPermissionForIOS(
      hasPermission !== null,
    );
    if (grantedPermission) {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();
      if (status === 'granted') {
        setHasPermission(true);
        const result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Videos,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
        setPreview(!result.cancelled ? result.uri : null);
        setPreviewKind('video');
        return;
      }
    }
    setHasPermission(false);
  };

  if (hasPermission === false) {
    return (
      <ScreenWrapper style={styles.container}>
        <Text>{texts.userMedia.noCameraPermission}</Text>
        <View style={styles.buttonsWrapper}>
          <TextInput mode="outlined" label={texts.userMedia.insertLink} />
          <Button
            style={styles.goToDashboardButton}
            onPress={handleGoToDashboardPress}
            mode="contained"
          >
            {texts.onboarding.goToDashboard}
          </Button>
        </View>
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper style={styles.container}>
      <ScrollView
        style={styles.fullWidth}
        contentContainerStyle={styles.scrollViewContentContainerStyle}
      >
        <View style={styles.instructionWrapper}>
          <Text style={styles.instructionText}>
            {texts.onboarding.uploadOrTakePhoto}
          </Text>
        </View>
        <Preview
          isLoading={isLoading}
          preview={preview}
          previewKind={previewKind}
        />
        <View style={styles.buttonsWrapper}>
          <Button mode="outlined" onPress={handleAddingPhoto}>
            {texts.userMedia.addPhoto}
          </Button>
          {!isWeb && (
            <Button
              style={styles.button}
              onPress={handleTakingPhoto}
              mode="outlined"
              icon={() => (
                <Icon
                  name="camera"
                  style={[styles.cameraIcon, { color: colors.text }]}
                />
              )}
            >
              {texts.userMedia.takePhoto}
            </Button>
          )}
          <View style={styles.photoVideoDivider}>
            <Text>{texts.common.orDivider}</Text>
          </View>
          <Button mode="outlined" onPress={handleAddingVideo}>
            {texts.userMedia.uploadVideo}
          </Button>
          {!isWeb && (
            <Button
              style={styles.button}
              onPress={handleTakingVideo}
              mode="outlined"
              icon={() => (
                <Icon
                  name="video"
                  style={[styles.cameraIcon, { color: colors.text }]}
                />
              )}
            >
              {texts.userMedia.takeNewVideo}
            </Button>
          )}
          <View style={styles.photoVideoDivider}>
            <Text>{texts.common.orDivider}</Text>
          </View>
          <TextInput
            mode="outlined"
            value={videoLink}
            onChangeText={handleChangeVideoLink}
            label={texts.userMedia.insertLink}
          />
          <Button
            loading={isLoading}
            style={styles.goToDashboardButton}
            onPress={handleGoToDashboardPress}
            mode="contained"
          >
            {texts.onboarding.goToDashboard}
          </Button>
        </View>
      </ScrollView>
    </ScreenWrapper>
  );
}

interface PreviewProps {
  isLoading: boolean;
  preview: string | null;
  previewKind: 'video' | 'image' | 'externalVideo' | null;
}

function Preview({ isLoading, preview, previewKind }: PreviewProps) {
  if (isLoading) {
    return (
      <ScreenWrapper style={[styles.container, styles.loadingContainer]}>
        <ActivityIndicator />
      </ScreenWrapper>
    );
  }

  switch (previewKind) {
    case 'image':
      return (
        <AutoscaleImage
          uri={preview || DEFAULT_AVATAR_IMAGE}
          style={styles.previewSize}
          resizeMode="contain"
        />
      );

    case 'externalVideo':
      return (
        <AutoscaleImage
          uri={preview || DEFAULT_VIDEO_THUMBNAIL}
          style={styles.previewSize}
          resizeMode="contain"
        />
      );

    case 'video':
      return preview ? (
        <Video
          source={{ uri: preview as string }}
          rate={1.0}
          volume={1.0}
          isMuted
          resizeMode="cover"
          shouldPlay
          isLooping
          style={styles.previewSize}
        />
      ) : (
        <AutoscaleImage
          uri={DEFAULT_VIDEO_THUMBNAIL}
          style={styles.previewSize}
          resizeMode="contain"
        />
      );

    default:
      return <Graphics width={200} height={200} />;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    flexBasis: 200,
    width: 200,
  },
  scrollViewContentContainerStyle: {
    width: '100%',
    alignItems: 'center',
  },
  buttonsWrapper: {
    marginTop: 20,
    alignSelf: 'stretch',
  },
  button: {
    marginTop: 16,
  },
  goToDashboardButton: {
    marginTop: 24,
  },
  cameraIcon: {
    fontSize: 24,
  },
  photoVideoDivider: {
    alignItems: 'center',
    padding: 8,
  },
  fullWidth: { width: '100%' },
  instructionWrapper: {
    paddingBottom: 40,
  },
  instructionText: {
    opacity: 0.8,
    textAlign: 'center',
  },
  previewSize: {
    width: 200,
    height: 200,
  },
});
