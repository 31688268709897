import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';

import Icon from '../../components/Icon';
import { useTheme } from '../../theming';

type Props = {
  customIcon?: string;
  disabled?: boolean;
  folderName: string;
  onLongPress?: () => void;
  onPress?: () => void;
};

export default function FolderItem({
  customIcon = 'folder',
  disabled = false,
  folderName,
  onLongPress,
  onPress,
}: Props) {
  const { colors } = useTheme();

  return (
    <TouchableOpacity onLongPress={onLongPress} onPress={onPress}>
      <View
        style={[
          styles.container,
          { borderColor: colors.darkGrey, opacity: disabled ? 0.25 : 1 },
        ]}
      >
        <Icon name={customIcon as any} size={28} color={colors.primary} />
        <Text style={styles.folderName}>{folderName}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    marginTop: 4,
    borderWidth: 1,
  },
  folderName: {
    fontSize: 24,
    marginLeft: 16,
  },
});
