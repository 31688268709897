/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/** This function is borrowed from this gist if stopped working correctly take a look for newer version
 * https://gist.github.com/takien/4077195#gistcomment-3410228
 */
function getYouTubeId(url: string) {
  const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : null;
}

function getVimeoId(url: string) {
  const matchIdRegex =
    /(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/g;
  const matchedValue = matchIdRegex.exec(url);
  return matchedValue ? matchedValue[1] : null;
}

interface ParsedVideoInfo {
  provider: 'youtube' | 'vimeo';
  id: string;
}

export const getVideoInfo = (url: string): ParsedVideoInfo | null => {
  if (['youtube', 'youtu.be', 'ytimg'].some((domain) => url.includes(domain))) {
    const id = getYouTubeId(url);
    return id ? { provider: 'youtube', id } : null;
  }
  if (url.includes('vimeo')) {
    const id = getVimeoId(url);
    return id ? { provider: 'vimeo', id } : null;
  }
  return null;
};

export const getEmbeddedUrl = (url: string) => {
  const videoInfo = getVideoInfo(url);
  if (videoInfo?.provider === 'youtube') {
    return `https://www.youtube.com/embed/${videoInfo.id}`;
  }
  if (videoInfo?.provider === 'vimeo') {
    return `https://player.vimeo.com/video/${videoInfo.id}?title=0&byline=0&portrait=0&autoplay=0`;
  }
  return '';
};

export const getVideoThumbnail = async (url: string): Promise<string> => {
  try {
    const videoInfo = getVideoInfo(url);
    if (videoInfo?.provider === 'youtube') {
      const youtubeUrl = `https://img.youtube.com/vi/${videoInfo.id}/0.jpg`;
      return await fetch(youtubeUrl, { mode: 'no-cors' }).then((res) => {
        if (res.status === 404) {
          return '';
        }
        return youtubeUrl;
      });
    }
    if (videoInfo?.provider === 'vimeo') {
      const data = await fetch(
        `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoInfo.id}`,
      ).then((res) => res.json());
      return data?.thumbnail_url || '';
    }
    return '';
  } catch (e) {
    return '';
  }
};

export function getVideoFileInfo(
  photoUri: string,
): { type: string; filename: string } | null {
  const filename = photoUri.split('/').pop();

  if (filename !== undefined) {
    const match = /\.(\w+)$/.exec(filename);
    const type = match ? `video/${match[1]}` : `video`;
    return { type, filename };
  }

  return null;
}
