import React from 'react';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import { RouteProp as NavigationRouteProp } from '@react-navigation/core';

import { MainNavigatorParams } from '../../MainNavigator';
import { useTheme } from '../../theming';
import ResetPasswordScreen from './ResetPassword';
import ForgotPasswordHomeScreen from './ForgotPasswordHome';

export type ForgotPasswordNavigatorParams = {
  ForgotPasswordHome: undefined;
  ResetPassword: {
    reset_password_token: string;
  };
};

type Screens = keyof (ForgotPasswordNavigatorParams & MainNavigatorParams);

export type NavigationProp<S extends Screens> = StackNavigationProp<
  ForgotPasswordNavigatorParams & MainNavigatorParams,
  S
>;

export type RouteProp<S extends Screens> = NavigationRouteProp<
  ForgotPasswordNavigatorParams & MainNavigatorParams,
  S
>;

const Stack = createStackNavigator<ForgotPasswordNavigatorParams>();

export default function ForgotPasswordStack() {
  const { colors } = useTheme();
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerShown: true,
        headerStyle: {
          borderBottomWidth: 0, // removes bottom border from header on the web
          backgroundColor: colors.black,
          shadowColor: 'transparent', // removes bottom border from header on IOS
        },
        headerTintColor: colors.text,
      }}
    >
      <Stack.Screen
        name="ForgotPasswordHome"
        options={{ headerTitle: 'Forgot password' }}
        component={ForgotPasswordHomeScreen}
      />
      <Stack.Screen
        name="ResetPassword"
        options={{ headerTitle: 'Reset password' }}
        component={ResetPasswordScreen}
      />
    </Stack.Navigator>
  );
}
