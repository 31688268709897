import React, { useContext, useState, useRef, useEffect } from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Animated,
  Easing,
  Platform,
} from 'react-native';

import { ActivityIndicator } from 'react-native-paper';

import StyledWebView from '../../components/StyledWebView';
import * as templates from '../../components/StyledWebView/templates';

import ScreenWrapper from '../../components/ScreenWrapper';
import { ButtonType } from '../../store/effects/api/types';
import { useTheme } from '../../theming';
import DataContext from '../Profile/DataContext';
import LogoHeader from './LogoHeader';
import { RouteProp } from './index';

const prepareContent = (
  subPage: ButtonType,
  paddingVertical: number,
  paddingHorizontal: number,
) => {
  return `
  ${
    subPage.sub_page_data?.sub_page_url !== '' &&
    typeof subPage.sub_page_data?.sub_page_url_label === 'string'
      ? templates.subPageWebsiteLink({
          href: subPage?.sub_page_data.sub_page_url_label,
          label: subPage?.sub_page_data.sub_page_url_label,
        })
      : ''
  }
    ${subPage.sub_page_data?.sub_page_videos
      ?.map((videoData) => {
        return [
          videoData.video_url !== null
            ? templates.iframeVideo({ src: videoData.video_url })
            : '',
          videoData.content !== null
            ? templates.content({
                content: videoData.content,
                paddingHorizontal,
                paddingVertical,
                center: true,
              })
            : '',
        ]
          .filter(Boolean)
          .join('');
      })
      .join()}
    ${subPage.sub_page_data?.sub_page_photos?.map((imageData) => {
      return [
        imageData.image_url !== null
          ? templates.image({ src: imageData.image_url })
          : '',
        imageData.content !== null
          ? templates.content({
              content: imageData.content,
              paddingHorizontal,
              paddingVertical,
              center: true,
            })
          : '',
      ]
        .filter(Boolean)
        .join('');
    })}
    ${
      typeof subPage?.information_data?.info_image_url === 'string'
        ? templates.image({ src: subPage?.information_data?.info_image_url })
        : ''
    }
    ${
      typeof subPage?.information_data?.info_image_text === 'string'
        ? templates.content({
            content: subPage?.information_data?.info_image_text,
            paddingHorizontal,
            paddingVertical,
          })
        : ''
    }
  `;
};

type Props = {
  route: RouteProp<'DBC-SubPage'>;
};

export default function SubPage({ route }: Props) {
  const opacity = useRef<Animated.Value>(new Animated.Value(0));
  const { data } = useContext(DataContext.Context);
  const subPageData = data?.buttons[route.params.buttonIndex];
  const { colors, spacings } = useTheme();
  const [webviewLoaded, setWebviewLoaded] = useState(false);

  useEffect(() => {
    if (webviewLoaded) {
      Animated.timing(opacity.current, {
        duration: 400,
        easing: Easing.inOut(Easing.quad),
        toValue: 1,
        useNativeDriver: true,
      }).start();
    }
  }, [webviewLoaded]);

  return (
    <ScreenWrapper style={styles.container} noPadding>
      {data !== null && subPageData !== undefined ? (
        <>
          <Animated.ScrollView
            style={[styles.fullWidth, { opacity: opacity.current }]}
            contentContainerStyle={[styles.center]}
          >
            <LogoHeader
              title={subPageData.label}
              titleBackground={
                subPageData.background_url ?? data.title_bg_image_url
              }
              logoBanner={data.logo_url}
            />
            <View style={[styles.fullWidth, styles.center]}>
              <StyledWebView
                width={
                  Platform.OS === 'web'
                    ? '100%'
                    : `${Dimensions.get('window').width}px`
                }
                font={data.font}
                colors={colors}
                html={prepareContent(
                  subPageData,
                  spacings.content.horizontal,
                  spacings.content.vertical,
                )}
                onLoad={() => {
                  setWebviewLoaded(true);
                }}
              />
            </View>
          </Animated.ScrollView>
          {webviewLoaded === false ? (
            <View
              style={[
                styles.webviewLoadingIndicator,
                { backgroundColor: colors.background },
              ]}
            >
              <ActivityIndicator />
            </View>
          ) : null}
        </>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  center: {
    alignItems: 'center',
  },
  webviewLoadingIndicator: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
