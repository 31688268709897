import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, TextInput } from 'react-native-paper';

import { useTheme } from '../../../../theming';
import Button from '../../../../components/Button';
import { useTranslation } from '../../../../translations';

type EmailButtonType = {
  email: string;
  buttonName: string;
  id: number;
};

export type { EmailButtonType };

type EmailsData = {
  [key: number]: EmailButtonType & { changed: boolean };
};

type Props = {
  initialEmailsData: EmailButtonType[];
  onSubmit: (emailsData: EmailButtonType[]) => void;
};

export default function EditEmailsForm({ initialEmailsData, onSubmit }: Props) {
  const texts = useTranslation('editProfileButtons');
  const { colors } = useTheme();
  const [emailsData, setEmailsData] = useState<EmailsData>(
    initialEmailsData.reduce((allEmailsData, curr) => {
      return {
        ...allEmailsData,
        [curr.id]: { ...curr, changed: false },
      };
    }, {}),
  );

  const handleOnSavePress = () => {
    const emailsButtonsDataChanged = Object.values(emailsData).filter(
      (emailButtonData) => emailButtonData.changed,
    );
    onSubmit(emailsButtonsDataChanged);
  };

  const handleOnChange = (id: number, newEmail: string) => {
    setEmailsData((oldEmailsData) => {
      return {
        ...oldEmailsData,
        [id]: {
          ...oldEmailsData[id],
          email: newEmail,
          changed: true,
        },
      };
    });
  };

  return (
    <>
      {Object.values(emailsData).map(({ email, buttonName, id }) => {
        return (
          <View key={id} style={[styles.section, { borderColor: colors.text }]}>
            <Text>{buttonName}</Text>
            <TextInput
              mode="outlined"
              value={email}
              placeholder={texts.editProfileButtons.emailPlaceholder}
              autoCapitalize="none"
              keyboardType="email-address"
              textContentType="emailAddress"
              onChangeText={(newEmail) => {
                handleOnChange(id, newEmail);
              }}
            />
          </View>
        );
      })}
      <Button
        style={styles.buttonSave}
        mode="contained"
        onPress={handleOnSavePress}
      >
        {texts.editProfileButtons.save}
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  section: {
    marginVertical: 8,
  },
  buttonSave: {
    marginTop: 20,
  },
});
