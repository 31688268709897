import React, { useContext } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import Graphics from '../../../../assets/undraw_social_media.svg';
import ScreenWrapper from '../../../components/ScreenWrapper';
import LogoHeader from '../LogoHeader';
import DataContext from '../../Profile/DataContext';
import { RouteProp } from '../index';

import SocialMediaContactPage from '../../../components/SocialMediaContactPage';

type Props = {
  route: RouteProp<'DBC-SocialMedia'>;
};

export default function SocialMediaLinksScreen({ route }: Props) {
  const { data } = useContext(DataContext.Context);
  const socialMediaLinksData = data?.buttons[route.params.buttonIndex];
  return (
    <ScreenWrapper noPadding style={styles.container}>
      {data !== null && socialMediaLinksData !== undefined ? (
        <ScrollView style={styles.fullWidth}>
          <LogoHeader
            title={socialMediaLinksData.label}
            titleBackground={
              socialMediaLinksData.background_url ?? data.title_bg_image_url
            }
            logoBanner={data.logo_url}
          />
          <ScreenWrapper.Content style={[styles.fullWidth]}>
            <View style={styles.graphicsWrapper}>
              <Graphics width={300} height={200} />
            </View>
            <View>
              {socialMediaLinksData.social_media_link !== undefined ? (
                <SocialMediaContactPage
                  socialMediaData={socialMediaLinksData.social_media_link}
                />
              ) : null}
            </View>
          </ScreenWrapper.Content>
        </ScrollView>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  graphicsWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  fullWidth: {
    width: '100%',
  },
});
