import React from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/core';

import IconButton from '../../components/IconButton';
import { useTranslation } from '../../translations';
import { useActions, useStore } from '../../store';
import { MyProfileMeta } from '../../store/effects/api/types';
import { useTheme } from '../../theming';
import { NavigationProp } from '../../MainNavigator';
import { getFormattedDate } from '../../utils/date';
import ProfilesListItem from '../../components/ProfilesListItem';

type Props = {
  item: MyProfileMeta;
};

export default function MyProfilesListItem({ item: profile }: Props) {
  const texts = useTranslation('profiles');
  const { colors } = useTheme();
  const {
    profiles: { mainProfileID },
  } = useStore();
  const {
    profiles: { markAsFavourite },
  } = useActions();
  const navigation = useNavigation<NavigationProp<'MyProfiles'>>();

  const createdAtDate = new Date(profile.created_at);
  const formattedDate = getFormattedDate(createdAtDate);
  const tileColor =
    profile.id === mainProfileID ? colors.darkGrey : colors.black;

  const dateText = `${texts.profiles.createdDate}: ${formattedDate}`;
  const handleMarkAsFavourite = () => {
    if (mainProfileID !== profile.id) {
      markAsFavourite(profile.id);
    }
  };

  const handleOnPress = () => {
    navigation.navigate('ProfileStack', { profileID: profile.id });
  };

  return (
    <ProfilesListItem
      profile={profile}
      onPress={handleOnPress}
      dateText={dateText}
      tileColor={tileColor}
      icon={
        <IconButton
          icon={mainProfileID === profile.id ? 'heart' : 'heart-outline'}
          onPress={handleMarkAsFavourite}
          color={colors.text}
          style={styles.mainProfileIcon}
        />
      }
    />
  );
}

const styles = StyleSheet.create({
  mainProfileIcon: {
    marginLeft: 'auto',
    alignSelf: 'flex-end',
    marginBottom: 0,
    marginRight: 0,
  },
});
