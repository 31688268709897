import React, { useContext } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import ScreenWrapper from '../../components/ScreenWrapper';
import { DataLoadStatus } from '../../store/types';
import { InvitationData } from '../../store/effects/api/types';
import InvitationDataContext from './InvitationDataContext';

type Props = {
  children: (invitationData: InvitationData) => React.ReactNode;
};

export default function OnboardingScreenWrapper({ children }: Props) {
  const { status: whitelabelThemeLoadingStatus, data } = useContext(
    InvitationDataContext.Context,
  );
  return (
    <ScreenWrapper style={styles.container} noPadding>
      {whitelabelThemeLoadingStatus === DataLoadStatus.SUCCESS &&
      data !== null ? (
        <ScrollView
          style={styles.fullWidth}
          contentContainerStyle={styles.scrollViewContentContainerStyles}
        >
          <ScreenWrapper.Content style={styles.fullWidth}>
            {children(data)}
          </ScreenWrapper.Content>
        </ScrollView>
      ) : (
        <View style={styles.loaderWrapper}>
          <ActivityIndicator />
        </View>
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loaderWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollViewContentContainerStyles: {
    flexGrow: 1,
  },
  fullWidth: {
    width: '100%',
  },
});
