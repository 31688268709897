import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, TextInput } from 'react-native-paper';

import { useTheme } from '../../../../theming';
import Button from '../../../../components/Button';
import { useTranslation } from '../../../../translations';

type SurveyButtonType = {
  email: string;
  phone: string;
  buttonName: string;
  id: number;
};

export type { SurveyButtonType };

type SurveysData = {
  [key: number]: SurveyButtonType & { changed: boolean };
};

type Props = {
  initialSurveysData: SurveyButtonType[];
  onSubmit: (surveysButtonsData: SurveyButtonType[]) => void;
};

export default function EditSurveysForm({
  initialSurveysData,
  onSubmit,
}: Props) {
  const texts = useTranslation('editProfileButtons');
  const { colors } = useTheme();
  const [surveysData, setSurveysData] = useState<SurveysData>(
    initialSurveysData.reduce((allSurveysData, curr) => {
      return {
        ...allSurveysData,
        [curr.id]: { ...curr, changed: false },
      };
    }, {}),
  );

  const handleOnSavePress = () => {
    const changedSurveysButtonData = Object.values(surveysData).filter(
      (surveyButtonData) => surveyButtonData.changed,
    );

    onSubmit(changedSurveysButtonData);
  };

  const handleOnChangeEmail = (id: number, newSurveysEmail: string) => {
    setSurveysData((oldSurveysData) => {
      return {
        ...oldSurveysData,
        [id]: {
          ...oldSurveysData[id],
          email: newSurveysEmail,
          changed: true,
        },
      };
    });
  };
  const handleOnChangePhone = (id: number, newSurveysPhone: string) => {
    setSurveysData((oldSurveysData) => {
      return {
        ...oldSurveysData,
        [id]: {
          ...oldSurveysData[id],
          phone: newSurveysPhone,
          changed: true,
        },
      };
    });
  };

  return (
    <>
      {Object.values(surveysData).map(({ buttonName, id, email, phone }) => {
        return (
          <View key={id} style={styles.section}>
            <Text style={styles.buttonNameText}>{buttonName}</Text>
            <View style={[styles.inputsWrapper, { borderColor: colors.text }]}>
              <View style={styles.inputWithLabelWrapper}>
                <Text>
                  {texts.editProfileButtons.emailForSurveyResultsLabel}
                </Text>
                <TextInput
                  mode="outlined"
                  value={email}
                  placeholder={texts.editProfileButtons.emailPlaceholder}
                  autoCapitalize="none"
                  keyboardType="email-address"
                  textContentType="emailAddress"
                  onChangeText={(newEmail) => {
                    handleOnChangeEmail(id, newEmail);
                  }}
                />
              </View>
              <View style={styles.inputWithLabelWrapper}>
                <Text>
                  {texts.editProfileButtons.phoneForSurveyResultsLabel}
                </Text>
                <TextInput
                  mode="outlined"
                  value={phone}
                  placeholder={texts.editProfileButtons.phonePlaceholder}
                  autoCapitalize="none"
                  keyboardType="phone-pad"
                  textContentType="telephoneNumber"
                  onChangeText={(newPhone) => {
                    handleOnChangePhone(id, newPhone);
                  }}
                />
              </View>
            </View>
          </View>
        );
      })}
      <Button
        style={styles.buttonSave}
        mode="contained"
        onPress={handleOnSavePress}
      >
        {texts.editProfileButtons.save}
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  section: {
    marginVertical: 5,
  },
  inputsWrapper: {
    borderWidth: 1,
    padding: 12,
  },
  buttonSave: {
    marginTop: 20,
  },
  inputWithLabelWrapper: {
    marginVertical: 5,
  },
  buttonNameText: {
    marginVertical: 10,
  },
});
