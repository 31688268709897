import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';
import Button from '../../../components/Button';

import { ButtonType, SurveyQuestion } from '../../../store/effects/api/types';
import AnswerField from './AnswerField';

type Props = {
  surveyConfig: ButtonType;
  onSubmit: (result: SurveyQuestion[]) => void;
  isProcessing: boolean;
};
type Answers = { [key: number]: SurveyQuestion };

export default function SurveyForm({
  surveyConfig,
  onSubmit,
  isProcessing,
}: Props) {
  const isMounted = useRef(false);
  const prevProps = useRef(surveyConfig.id);
  const [answers, setAnswers] = useState<Answers>(getInitialState());

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setAnswers(getInitialState());
      prevProps.current = surveyConfig.id;
    }
    return () => {
      prevProps.current = surveyConfig.id;
    };
  }, [surveyConfig.id]);

  function getInitialState() {
    return (
      surveyConfig.survey as NonNullable<ButtonType['survey']>
    ).survey_questions.reduce((allQuestions, question) => {
      return {
        ...allQuestions,
        [question.id]: {
          ...question,
          answer:
            question.question_type === 'menu'
              ? question.answer?.split(', ').map((answer) => answer.trim())[0]
              : '',
        },
      };
    }, {});
  }

  const surveyData = surveyConfig.survey;

  const handleSubmit = () => {
    const preparedAnswers = Object.values(answers);
    onSubmit(preparedAnswers);
  };

  const handleOnAnswered = ({ id, answer }: { id: number; answer: string }) => {
    setAnswers((oldAnswers) => {
      return {
        ...oldAnswers,
        [id]: {
          ...oldAnswers[id],
          answer,
        },
      };
    });
  };

  if (prevProps.current !== surveyConfig.id) {
    return <ActivityIndicator />;
  }

  return (
    <>
      {surveyData?.survey_questions.map((surveyQuestion) => (
        <View key={surveyQuestion.id} style={styles.questionWithAnswer}>
          <Text>{surveyQuestion.question}</Text>
          <AnswerField
            questionId={surveyQuestion.id}
            questionType={surveyQuestion.question_type}
            questionData={
              surveyQuestion.question_type === 'menu' &&
              surveyQuestion.answer !== null
                ? {
                    options: surveyQuestion.answer.split(', '),
                    answer: answers[surveyQuestion.id]?.answer ?? '',
                  }
                : surveyQuestion.question_type === 'text'
                ? {
                    answer: answers[surveyQuestion.id].answer ?? '',
                  }
                : undefined
            }
            onAnswered={handleOnAnswered}
          />
        </View>
      ))}
      <Button
        loading={isProcessing}
        mode="contained"
        onPress={handleSubmit}
        style={styles.submitButton}
      >
        Submit
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  submitButton: {
    marginTop: 20,
  },
  questionWithAnswer: {
    marginVertical: 5,
  },
});
