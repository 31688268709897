import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Text } from 'react-native-paper';

import { useTheme } from '../../theming';

type Props = {
  children: React.ReactText | null;
  background?: string | null;
};

export default function Header({ children, background }: Props) {
  const { fonts, spacings } = useTheme();
  return (
    <View style={styles.container}>
      {background !== undefined && background !== null ? (
        <Image
          style={styles.image}
          source={{ uri: background }}
          accessibilityIgnoresInvertColors
        />
      ) : null}
      <Text
        style={[
          styles.label,
          fonts.medium,
          { paddingHorizontal: spacings.content.horizontal },
        ]}
      >
        {children}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  label: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 20,
    zIndex: 1,
  },
});
