import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { TextInput } from 'react-native-paper';

import { useTranslation } from '../../../translations';
import { EditableProfileDetails } from '../../../store/effects/api/types';
import Button from '../../../components/Button';

type Props = {
  initialProfileData: EditableProfileDetails;
  isProcessing: boolean;
  onSubmit: (profileData: EditableProfileDetails) => void;
};

export default function EditProfileInfoForm({
  isProcessing,
  initialProfileData,
  onSubmit,
}: Props) {
  const texts = useTranslation('forms', 'edit');
  const [profileName, setProfileName] = useState(initialProfileData.name ?? '');
  const [profileRole, setProfileRole] = useState(
    initialProfileData.profile_role ?? '',
  );
  const [profileCompany, setCompany] = useState(
    initialProfileData.company ?? '',
  );
  const [profileTitle, setProfileTitle] = useState(
    initialProfileData.title ?? '',
  );
  const [profileWebsite, setProfileWebsite] = useState(
    initialProfileData.website ?? '',
  );

  const handleSubmit = () => {
    const profileData: EditableProfileDetails = {
      name: profileName,
      profile_role: profileRole,
      company: profileCompany,
      title: profileTitle,
      website: profileWebsite === '' ? null : profileWebsite,
    };

    onSubmit(profileData);
  };

  return (
    <>
      <TextInput
        mode="outlined"
        value={profileName}
        onChangeText={setProfileName}
        label={texts.forms.mainTitleLabel}
        autoCapitalize="sentences"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={profileTitle}
        onChangeText={setProfileTitle}
        label={texts.forms.profileNameLabel}
        autoCapitalize="sentences"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={profileRole}
        onChangeText={setProfileRole}
        label={texts.forms.roleLabel}
        autoCapitalize="sentences"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={profileCompany}
        onChangeText={setCompany}
        label={texts.forms.companyLabel}
        autoCapitalize="sentences"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={profileWebsite}
        onChangeText={setProfileWebsite}
        label={texts.forms.websiteLabel}
        autoCapitalize="sentences"
        style={styles.input}
      />
      <Button
        loading={isProcessing}
        mode="contained"
        onPress={handleSubmit}
        style={styles.button}
      >
        {texts.edit.save}
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  input: {
    marginVertical: 5,
  },
  button: {
    marginTop: 20,
  },
});
