import React from 'react';
import { Video } from 'expo-av';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';

type Props = {
  url: string;
  videoStyles?: StyleProp<ViewStyle>;
};

export default function InternalVideo({ url, videoStyles }: Props) {
  return (
    <Video
      source={{ uri: `${url}` }}
      rate={1.0}
      volume={1.0}
      isMuted
      resizeMode="cover"
      shouldPlay
      isLooping
      style={[styles.video, videoStyles]}
    />
  );
}

const styles = StyleSheet.create({
  video: {
    width: '100%',
    height: '100%',
    borderRadius: 16,
  },
});
