import { useState, useEffect } from 'react';
import { Dimensions, Platform } from 'react-native';
import { debounce } from 'debounce';

type AppDims = {
  width: number;
  height: number;
};

function getAppSize(): AppDims {
  const appDim = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  };
  if (Platform.OS === 'web') {
    if (window.innerWidth > window.innerHeight * 1.2) {
      return {
        width: appDim.width / 2,
        height: appDim.height,
      };
    }
  }
  return appDim;
}

export default function useAppSize(): AppDims {
  const [appSize, setAppSize] = useState(getAppSize());
  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleResize = () => {
        setAppSize(getAppSize());
      };
      const handleResizeListener = debounce(handleResize, 300);
      window.addEventListener('resize', handleResizeListener);
      return () => {
        window.removeEventListener('resize', handleResizeListener);
      };
    }
  }, []);

  return appSize;
}
