import React, { useState } from 'react';
import { TextInput, Button } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import { useTranslation } from '../../translations';
import { OnboardingUserAccountData } from '../../store/effects/api/types';
import { stdAlert } from '../../components/alert';

type Props = {
  submitButtonText: string;
  onSubmit: (user: OnboardingUserAccountData) => void;
};

export default function SignUpForm({ onSubmit, submitButtonText }: Props) {
  const texts = useTranslation('forms');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  function validateRequiredFields() {
    return (
      name.trim().length !== 0 &&
      lastName.trim().length !== 0 &&
      email.trim().length !== 0 &&
      phone.trim().length !== 0 &&
      password.length !== 0 &&
      passwordConfirmation.length !== 0
    );
  }

  function validatePasswordConfirmation() {
    return password === passwordConfirmation;
  }

  const handleSubmit = () => {
    const areRequiredFieldsFilled = validateRequiredFields();
    const arePasswordsTheSame = validatePasswordConfirmation();
    if (!areRequiredFieldsFilled) {
      stdAlert(texts.forms.fillAllFields);
      return;
    }

    if (!arePasswordsTheSame) {
      stdAlert(texts.forms.passwordsNotMatch);
      return;
    }
    const userAccount: OnboardingUserAccountData = {
      name,
      last_name: lastName,
      email,
      phone,
      website,
      password,
      password_confirmation: passwordConfirmation,
    };
    onSubmit(userAccount);
  };

  return (
    <>
      <TextInput
        mode="outlined"
        value={name}
        onChangeText={setName}
        label={texts.forms.nameLabel}
        autoCapitalize="sentences"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={lastName}
        onChangeText={setLastName}
        label={texts.forms.lastNameLabel}
        autoCapitalize="sentences"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={email}
        keyboardType="email-address"
        onChangeText={setEmail}
        label={texts.forms.emailLabel}
        textContentType="emailAddress"
        autoCapitalize="none"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={phone}
        keyboardType="phone-pad"
        onChangeText={setPhone}
        label={texts.forms.phoneNumLabel}
        textContentType="telephoneNumber"
        autoCapitalize="none"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={website}
        onChangeText={setWebsite}
        label={texts.forms.websiteLabel}
        autoCapitalize="none"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={password}
        onChangeText={setPassword}
        textContentType="oneTimeCode" //workaround see https://stackoverflow.com/questions/53097620/expo-disable-autofill-strong-password-autofill-on-ios-12
        secureTextEntry={true}
        label={texts.forms.passwordLabel}
        autoCapitalize="none"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={passwordConfirmation}
        onChangeText={setPasswordConfirmation}
        textContentType="oneTimeCode"
        secureTextEntry={true}
        label={texts.forms.passwordConfirmLabel}
        autoCapitalize="none"
        style={styles.input}
      />
      <Button mode="contained" onPress={handleSubmit} style={styles.button}>
        {submitButtonText}
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  input: {
    marginVertical: 5,
    maxHeight: 56,
  },
  button: {
    marginTop: 20,
  },
});
