import React, { useContext, useState, useLayoutEffect } from 'react';
import {
  StyleSheet,
  ScrollView,
  View,
  Platform,
  Dimensions,
  Image,
} from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';

import ScreenWrapper from '../../../components/ScreenWrapper';
import Video from '../../../components/UserMedia/ExternalVideo';
import { stdAlert } from '../../../components/alert';
import { SurveyQuestion } from '../../../store/effects/api/types';
import { useTranslation } from '../../../translations';
import { DataLoadStatus } from '../../../store/types';
import { useEffects } from '../../../store';
import DataContext from '../../../screens/Profile/DataContext';

import LogoHeader from '../LogoHeader';
import { RouteProp, NavigationProp } from '../index';

import SurveyForm from './SurveyForm';
import ReferralFields from './ReferralFields';

type Props = {
  route: RouteProp<'DBC-Survey'>;
  navigation: NavigationProp<'DBC-Survey'>;
};

export default function SurveyScreen({ route, navigation }: Props) {
  const texts = useTranslation('errors', 'survey');
  const { data, status } = useContext(DataContext.Context);
  const { api } = useEffects();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [isProcessingAnswers, setIsProcessingAnswers] = useState(false);
  const surveyData = data?.buttons[route.params.buttonIndex];

  useLayoutEffect(() => {
    if (
      status === DataLoadStatus.SUCCESS &&
      data !== null &&
      surveyData !== undefined
    ) {
      navigation.setOptions({
        headerShown: true,
        headerTitle:
          surveyData.survey?.is_referral === true ? 'Referral' : 'Survey',
      });
    }
  }, [status, data, surveyData?.id]);

  const handleSubmit = async (result: SurveyQuestion[]) => {
    if (
      surveyData !== undefined &&
      data !== null &&
      surveyData.survey !== undefined
    ) {
      setIsProcessingAnswers(true);
      const saveResult = await api.saveSurvey(
        surveyData.survey.id,
        data.id,
        surveyData.survey.is_referral === true
          ? {
              questionsWithAnswers: result,
              referral: { name, email, phoneNum },
            }
          : { questionsWithAnswers: result },
        surveyData.survey.is_referral,
      );
      if (saveResult.success !== undefined) {
        stdAlert(
          surveyData.survey.thank_you ?? texts.survey.defaultThanksMsg,
          'Survey',
          () => {
            navigation.replace('DigitalBusinessCardStack', {
              profileID: data.id,
            });
          },
        );
      } else {
        stdAlert(texts.errors.unexpected);
      }

      setIsProcessingAnswers(false);
    }
  };

  return (
    <ScreenWrapper noPadding withKeyboardAwareScrollView>
      {status === DataLoadStatus.SUCCESS && data !== null ? (
        <ScrollView>
          {surveyData !== undefined ? (
            <>
              <LogoHeader
                title={surveyData.label}
                titleBackground={data.title_bg_image_url}
                logoBanner={data.logo_url}
              />
              <ScreenWrapper.Content>
                {surveyData.survey?.picture_url !== undefined &&
                surveyData.survey?.picture_url !== null ? (
                  <View style={styles.imageWrapper}>
                    <Image
                      style={styles.image}
                      resizeMode="cover"
                      source={{ uri: surveyData.survey.picture_url }}
                      accessibilityIgnoresInvertColors
                    />
                  </View>
                ) : null}
                {surveyData.survey?.video !== undefined &&
                surveyData.survey?.video !== '' ? (
                  <View style={styles.videoWrapper}>
                    <Video url={surveyData.survey.video ?? ''} />
                  </View>
                ) : null}
                <View style={styles.formWrapper}>
                  {surveyData.survey?.is_referral === true ? (
                    <ReferralFields
                      email={email}
                      name={name}
                      phone={phoneNum}
                      onEmailChange={setEmail}
                      onNameChange={setName}
                      onPhoneChange={setPhoneNum}
                    />
                  ) : null}
                  <SurveyForm
                    isProcessing={isProcessingAnswers}
                    onSubmit={handleSubmit}
                    surveyConfig={surveyData}
                  />
                </View>
              </ScreenWrapper.Content>
            </>
          ) : null}
        </ScrollView>
      ) : status === DataLoadStatus.ERROR ? (
        <Text>{texts.errors.unexpected}</Text>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  formWrapper: {
    marginTop: 20,
  },
  videoWrapper: {
    marginTop: 8,
    width: '100%',
    minHeight: Platform.OS === 'web' ? 300 : Dimensions.get('window').width / 2,
  },
  imageWrapper: {
    marginTop: 8,
    width: '100%',
  },
  image: {
    minHeight: 200,
    width: '100%',
  },
});
