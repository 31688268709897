import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

import { stdAlert } from '../../components/alert';
import { useTranslation } from '../../translations';
import { OnboardingUserAccountData } from '../../store/effects/api/types';
import { useActions } from '../../store';
import WhitelabelTopBanner from '../../components/Whitelabel/TopBanner';
import WhitelabelFooter from '../../components/Whitelabel/Footer';

import SignUpForm from './SignUpForm';
import InvitationDataContext from './InvitationDataContext';
import OnboardingScreenWrapper from './OnboardingScreenWrapper';
import { NavigationProp } from './index';

type Props = { navigation: NavigationProp<'SignUp'> };

export default function SignUp({ navigation }: Props) {
  const { invitationHash } = useContext(InvitationDataContext.Context);
  const texts = useTranslation('onboarding', 'common');
  const {
    onboarding: { registerUser },
  } = useActions();

  const handleSubmit = async (user: OnboardingUserAccountData) => {
    const result = await registerUser({ userData: user, invitationHash });
    if (result !== true) {
      stdAlert(texts.onboarding.registerUserError);
    } else {
      stdAlert(texts.onboarding.accountCreated);
      navigation.replace('PhotoVideo');
    }
  };

  return (
    <OnboardingScreenWrapper>
      {(invitationData) => {
        return (
          <>
            <WhitelabelTopBanner
              customTopBannerUrl={
                invitationData.template_white_label.custom_top_banner_url
              }
            />
            <View style={styles.instructionWrapper}>
              <Text>{texts.onboarding.signUpInstruction}</Text>
            </View>
            <SignUpForm
              onSubmit={handleSubmit}
              submitButtonText={texts.onboarding.next}
            />
            <View style={styles.footer}>
              <WhitelabelFooter
                customPoweredByUrl={
                  invitationData.template_white_label.custom_powered_by_url
                }
              />
            </View>
          </>
        );
      }}
    </OnboardingScreenWrapper>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  footer: {
    marginTop: 16,
  },
});
