import { getEmbeddedUrl } from '../UserMedia/videoUtils';

export const viewportContent = `width=device-width, user-scalable=no`;

export const viewportTag = `<meta name="viewport" content="${viewportContent}">`;

export const globalStyles = ({
  width,
  fontFamily = 'sans-serif',
  textColor = 'black',
  backgroundColor = 'white',
}: {
  width: string | number;
  fontFamily?: string;
  textColor?: string;
  backgroundColor?: string;
}) => `
  body {
    width: ${width};
    background-color: ${backgroundColor};
    color: ${textColor};
  }
  * {
    font-family: '${fontFamily}';
    color: white;
  }
  img, iframe {
    width: 100%;
  }
`;

export const image = ({ src }: { src: string }) => `
  <img src="${src}"/>
`;

export const iframeVideo = ({ src }: { src: string }) => `
    <iframe
      src="${getEmbeddedUrl(src)}"
      frameBorder="0"
      allowFullScreen
    ></iframe>
`;

export const subPageWebsiteLink = ({
  href,
  label,
}: {
  href: string;
  label: string;
}) => `
  <a href=${
    /^https?/.test(href) ? href : `https://${href}`
  } style="text-align: center;padding: 0 20px;text-transform: uppercase;font-size: 20px;display: block; color: #000000;background-image: linear-gradient(#FFF, #F1F1F1); text-decoration:none; border: 1px solid #AAA;">${label}</a>
`;

export const content = ({
  paddingVertical = 0,
  paddingHorizontal = 0,
  center = false,
  content,
}: {
  paddingVertical?: number;
  paddingHorizontal?: number;
  center?: boolean;
  content: string;
}) => `
  <div style="padding: ${paddingVertical}px ${paddingHorizontal}px; ${
  center ? 'text-align: center' : ''
} ">
  ${content}
  </div>
`;

export const googleFontLink = (fontFamily: string) =>
  `https://fonts.googleapis.com/css2?family=${fontFamily}:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap`;

export const googleFont = (fontFamily: string) =>
  `<link href="${googleFontLink(fontFamily)}" rel="stylesheet">`;
