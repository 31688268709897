import React from 'react';
import { createTheming } from '@callstack/react-theme-provider';
import {
  Provider as PaperProvider,
  DefaultTheme as DefaultPaperTheme,
} from 'react-native-paper';

type PaperTheme = ReactNativePaper.Theme;

import type { Theme } from './defaultTheme';
import defaultTheme from './defaultTheme';
export type { Theme } from './defaultTheme';

const theming = createTheming<Theme>(defaultTheme);

export const useTheme = theming.useTheme;

export const withTheme = theming.withTheme;

export const nonGoogleFonts = [undefined, 'Arial', 'Helvetica', '', null];

const InternalThemeProvider = theming.ThemeProvider;

const WrappedPaperProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const paperTheme: PaperTheme = {
    ...DefaultPaperTheme,
    colors: {
      ...DefaultPaperTheme.colors,
      background: theme.colors.background,
      primary: theme.colors.primary,
      text: theme.colors.text,
      placeholder: theme.colors.lightGrey,
    },
    fonts: {
      ...DefaultPaperTheme.fonts,
      ...theme.fonts,
    },
  };
  return <PaperProvider theme={paperTheme}>{children}</PaperProvider>;
};

export function ThemeProvider({
  theme,
  children,
}: {
  theme?: Theme;
  children: React.ReactNode;
}) {
  return (
    <InternalThemeProvider theme={theme}>
      <WrappedPaperProvider>{children}</WrappedPaperProvider>
    </InternalThemeProvider>
  );
}
