import React from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';
import { TextInput } from 'react-native-paper';
import SocialMediaIcon from './SocialMediaIcon';
import PremiumFeatureGate from './PremiumFeatureGate';

type Props = {
  socialMediaName:
    | 'facebook'
    | 'instagram'
    | 'twitter'
    | 'youtube'
    | 'vimeo'
    | 'linkedin';
  style?: StyleProp<ViewStyle>;
  value: string;
  onChangeText: (value: string) => void;
};

const SOCIAL_MEDIA_ICON_SIZE = 22;

export default function SocialMediaInput({
  socialMediaName,
  style,
  value,
  onChangeText,
}: Props) {
  return (
    <View style={[styles.socialMediaWrapper, style]}>
      <PremiumFeatureGate alwaysClickable={true} available={false}>
        <TextInput
          mode="outlined"
          value={value}
          label={`${socialMediaName} (optional)`}
          onChangeText={onChangeText}
          autoCapitalize="none"
          style={styles.socialMediaInput}
        />
      </PremiumFeatureGate>
      <SocialMediaIcon
        name={socialMediaName}
        style={styles.socialMediaIcon}
        size={SOCIAL_MEDIA_ICON_SIZE}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  socialMediaIcon: {
    marginLeft: 16,
    marginTop: 8,
  },
  socialMediaWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  socialMediaInput: {
    flex: 1,
    maxHeight: 64,
  },
});
