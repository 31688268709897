import React, { useContext, useState, useEffect } from 'react';
import { StyleSheet, ScrollView, Image, Share, Linking } from 'react-native';
import { Text, ActivityIndicator, FAB } from 'react-native-paper';

import ScreenWrapper from '../../components/ScreenWrapper';
import { DataLoadStatus } from '../../store/types';
import { useEffects, useStore } from '../../store';

import { useTheme } from '../../theming';
import { useTranslation } from '../../translations';

import { stdAlert } from '../../components/alert';
import SocialMediaButtons from '../../components/SocialMediaButtons';

import DataContext from '../Profile/DataContext';

import UserImage from '../../components/UserImage';
import { useCompanyPositionLabel } from '../../hooks/useCompanyPositionLabel';
import Button from './Button';
import LogoHeader from './LogoHeader';
import { NavigationProp } from './index';

type Props = {
  navigation: NavigationProp<'DBC-Home'>;
};

export default function DBCHome({ navigation }: Props) {
  const theme = useTheme();
  const {
    user: { token },
  } = useStore();
  const { api } = useEffects();
  const { colors } = theme;
  const texts = useTranslation('errors', 'common', 'digitalBusinessCard');
  const [isUserAlreadySavedProfile, setIsUserAlreadySavedProfile] = useState<
    boolean | null
  >(null);
  const [isOpen, setIsOpen] = useState(false);
  const { status, data, saveProfile, isUserProfile, generateShareLink } =
    useContext(DataContext.Context);
  const companyLabel = useCompanyPositionLabel({
    company: data?.company,
    profileRole: data?.profile_role,
  });
  useEffect(() => {
    (async () => {
      const isUserLoggedIn = token !== null;
      if (
        isUserLoggedIn &&
        status === DataLoadStatus.SUCCESS &&
        data !== null
      ) {
        const response = await api.getSavedProfilesList();
        if (response.success) {
          const { data: savedProfiles } = response.success;
          setIsUserAlreadySavedProfile(
            savedProfiles.profiles.find(
              (savedProfile) => savedProfile.id === data.id,
            ) !== undefined,
          );
        }
      } else {
        setIsUserAlreadySavedProfile(false);
      }
    })();
  }, [status, data, token, api]);

  const handleFABPress = ({ open }: { open: boolean }) => {
    setIsOpen(open);
  };

  const fabStyles = { backgroundColor: colors.primary };

  const handleQRCodeActionPress = () => {
    navigation.navigate('DBC-QRCode');
  };
  const handleSaveProfilePress = async () => {
    const saveResult = await saveProfile();
    if (saveResult === true) {
      stdAlert(texts.digitalBusinessCard.saveSuccess);
    } else {
      stdAlert(texts.errors.unexpected);
    }
  };

  const handleShareProfile = async () => {
    const shareLink = await generateShareLink();
    if (shareLink !== null) {
      Share.share({ message: shareLink });
    }
  };

  const handleScanActionPress = () => {
    navigation.navigate('QRScanner');
  };

  const handleVCard = async () => {
    if (data !== null) {
      if (data.vcard_download_link !== null) {
        await Linking.openURL(data.vcard_download_link);
      }
    }
  };

  const fabActions = [
    {
      style: fabStyles,
      icon: 'qrcode',
      color: colors.black,
      label: texts.digitalBusinessCard.QRCode,
      onPress: handleQRCodeActionPress,
    },
    {
      style: fabStyles,
      color: colors.black,
      icon: 'share',
      label: texts.digitalBusinessCard.share,
      onPress: handleShareProfile,
    },
  ];

  if (isUserProfile === true) {
    fabActions.push({
      style: fabStyles,
      color: colors.black,
      icon: 'qrcode-scan',
      label: texts.digitalBusinessCard.scan,
      onPress: handleScanActionPress,
    });
  } else {
    if (isUserAlreadySavedProfile === false) {
      fabActions.push({
        style: fabStyles,
        color: colors.black,
        icon: 'content-save',
        label: texts.digitalBusinessCard.save,
        onPress: handleSaveProfilePress,
      });
    }
  }

  if (data !== null) {
    if (data.vcard_download_link !== null) {
      fabActions.push({
        style: fabStyles,
        color: colors.black,
        icon: 'account-card-details',
        label: texts.digitalBusinessCard.vcard,
        onPress: handleVCard,
      });
    }
  }

  return (
    <ScreenWrapper style={styles.container} noPadding={true}>
      {status === DataLoadStatus.SUCCESS &&
      data !== null &&
      isUserAlreadySavedProfile !== null ? (
        <>
          {data.main_bg_image_url !== null ? (
            <Image
              source={{
                uri: data.main_bg_image_url,
              }}
              accessibilityIgnoresInvertColors
              style={styles.backgroundImage}
            />
          ) : null}
          <ScrollView
            style={styles.fullWidth}
            contentContainerStyle={styles.center}
          >
            <LogoHeader
              title={data.title}
              titleBackground={data.title_bg_image_url}
              logoBanner={data.logo_url}
            />
            <ScreenWrapper.Content style={[styles.fullWidth, styles.center]}>
              <UserImage
                picture={data.picture_url}
                video={data.video}
                videoClip={data.video_clip_url}
                mediaType={data.picture_or_video}
                style={styles.userMedia}
              />

              {companyLabel !== null ? (
                <Text style={styles.companyRole}>{companyLabel}</Text>
              ) : null}
              {data.buttons.map((button, index) => {
                // Do not display `Description` button if there
                // are not any elements within
                if (
                  button.data_type === 'description' &&
                  button.elements.length < 1
                ) {
                  return;
                }

                return (
                  <Button
                    {...button}
                    key={button.id !== null ? button.id : index}
                    label={button.label}
                    style={styles.button}
                    index={index}
                  />
                );
              })}
            </ScreenWrapper.Content>
            {data.show_social_media_links && (
              <SocialMediaButtons
                style={styles.socialMediaButtonsWrapper}
                socialMedias={{
                  social_facebook: data.account.social_facebook,
                  social_instagram: data.account.social_instagram,
                  social_youtube: data.account.social_youtube,
                  social_twitter: data.account.social_twitter,
                  social_linkedin: data.account.social_linkedin,
                  social_vimeo: data.account.social_vimeo,
                }}
              />
            )}
          </ScrollView>
          <FAB.Group
            color={colors.black}
            theme={{ colors: { text: colors.black } }}
            fabStyle={fabStyles}
            visible
            style={styles.fabPosition}
            open={isOpen}
            icon={isOpen ? 'close' : 'menu'}
            actions={fabActions}
            onStateChange={handleFABPress}
          />
        </>
      ) : status === DataLoadStatus.ERROR ? (
        <Text>{texts.errors.unexpected}</Text>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  center: {
    alignItems: 'center',
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    resizeMode: 'repeat',
  },
  userMedia: {
    width: 200,
    height: 200,
    margin: 20,
    borderRadius: 8,
    overflow: 'hidden',
  },
  companyRole: {
    marginBottom: 20,
    fontSize: 16,
  },
  button: {
    marginBottom: 16,
    width: '100%',
  },
  fabPosition: {
    padding: 8,
  },
  socialMediaButtonsWrapper: {
    paddingBottom: 80,
  },
});
