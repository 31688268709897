import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import { ScreenProps } from '../MainNavigator';
import { stdAlert } from '../components/alert';
import ScreenWrapper from '../components/ScreenWrapper';
import { useStore, useEffects } from '../store';
import { useTranslation } from '../translations';

type Props = ScreenProps<'SharedProfile'>;

export default function SharedProfile({
  route: { params },
  navigation,
}: Props) {
  const shareUuid = params.uuid;
  const texts = useTranslation('errors');
  const { api } = useEffects();
  const {
    user: { token },
  } = useStore();
  useEffect(() => {
    (async () => {
      const isUserLoggedIn = token !== null;
      if (isUserLoggedIn) {
        const confirmResult = await api.confirmShare(shareUuid);
        if (confirmResult.error !== undefined) {
          stdAlert(texts.errors.unexpected);
        }
      }

      const result = await api.exchangeShareUuidForProfileId(shareUuid);
      if (result.success) {
        const profileID = result.success.data.id;
        navigation.replace('DigitalBusinessCardStack', { profileID });
      } else {
        stdAlert(texts.errors.unexpected);
        navigation.replace('Welcome');
      }
    })();
  }, []);
  return (
    <ScreenWrapper style={styles.container}>
      <ActivityIndicator />
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
});
