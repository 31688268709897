import React, { useState } from 'react';
import {
  ViewStyle,
  StyleSheet,
  TouchableOpacity,
  StyleProp,
  View,
  LayoutChangeEvent,
  TextStyle,
} from 'react-native';
import { Text } from 'react-native-paper';

import { useTheme } from '../theming';
import Icon, { IconName } from './Icon';

type ElementProps = {
  icon: IconName;
  title: string;
  textStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  iconStyle?: { color: string };
  onPress?: () => void;
};

export function Item({
  icon,
  title,
  style,
  textStyle,
  iconStyle,
  onPress,
}: ElementProps) {
  const { colors } = useTheme();

  return (
    <TouchableOpacity
      style={[styles.container, { backgroundColor: colors.darkGrey }, style]}
      onPress={onPress}
    >
      <Icon
        name={icon}
        style={[{ color: colors.text }, styles.icon, iconStyle]}
      />
      <Text style={textStyle}>{title}</Text>
    </TouchableOpacity>
  );
}

type ContainerProps = {
  children:
    | React.ReactElement<ElementProps>
    | Array<React.ReactElement<ElementProps> | null>;
  style?: StyleProp<ViewStyle>;
};

const distanceBetweenItems = 16;

export function Grid({ children, style }: ContainerProps) {
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const handleOnLayout = (e: LayoutChangeEvent) => {
    setContainerWidth(e.nativeEvent.layout.width);
  };
  const childrenCount = (children as unknown[]).length;
  return (
    <View style={[styles.grid, style]} onLayout={handleOnLayout}>
      {React.Children.map(children, (child, index) => {
        if (child !== null) {
          return React.cloneElement(child, {
            style: [
              child.props.style as ViewStyle,
              {
                width:
                  containerWidth !== null
                    ? (containerWidth - distanceBetweenItems) / 2
                    : '45%',
              },
              childrenCount > 2 && index < childrenCount - 2
                ? styles.itemMarginBottom
                : null,
            ],
          });
        }
      })}
    </View>
  );
}

export default {
  Item,
  Grid,
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    padding: 10,
    paddingVertical: 27,
  },
  itemMarginBottom: {
    marginBottom: distanceBetweenItems,
  },
  icon: {
    fontSize: 24,
    marginBottom: 5,
  },
  grid: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
});
