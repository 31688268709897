import React from 'react';
import { useTranslation } from '../../translations';
import { MyProfileMeta } from '../../store/effects/api/types';
import { useTheme } from '../../theming';
import { getFormattedDate } from '../../utils/date';
import ProfilesListItem from '../../components/ProfilesListItem';
import { useNavigateToPreview } from '../../hooks/useNavigateToPreview';

type Props = {
  item: MyProfileMeta;
};

export function TemplateProfile({ item: profile }: Props) {
  const texts = useTranslation('profiles');
  const { colors } = useTheme();
  const { navigateToPreview } = useNavigateToPreview();

  const createdAtDate = new Date(profile.created_at);
  const formattedDate = getFormattedDate(createdAtDate);

  const dateText = `${texts.profiles.createdDate}: ${formattedDate}`;
  const handleOnPress = () => {
    navigateToPreview(profile.id);
  };

  return (
    <ProfilesListItem
      profile={profile}
      onPress={handleOnPress}
      dateText={dateText}
      tileColor={colors.black}
    />
  );
}
