import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, TextInput } from 'react-native-paper';

import { useTheme } from '../../../../theming';
import Button from '../../../../components/Button';
import { useTranslation } from '../../../../translations';

type PhoneButtonType = {
  phone: string;
  buttonName: string;
  id: number;
};

export type { PhoneButtonType };

type PhonesData = {
  [key: number]: PhoneButtonType & { changed: boolean };
};

type Props = {
  initialPhonesData: PhoneButtonType[];
  onSubmit: (phonesButtonsData: PhoneButtonType[]) => void;
};

export default function EditPhonesForm({ initialPhonesData, onSubmit }: Props) {
  const texts = useTranslation('editProfileButtons');
  const { colors } = useTheme();
  const [phonesData, setPhonesData] = useState<PhonesData>(
    initialPhonesData.reduce((allPhonesData, curr) => {
      return {
        ...allPhonesData,
        [curr.id]: { ...curr, changed: false },
      };
    }, {}),
  );

  const handleOnSavePress = () => {
    const phonesButtonsDataChanged = Object.values(phonesData).filter(
      (phoneButtonData) => phoneButtonData.changed,
    );
    onSubmit(phonesButtonsDataChanged);
  };

  const handleOnChange = (id: number, newPhone: string) => {
    setPhonesData((oldPhonesData) => {
      return {
        ...oldPhonesData,
        [id]: {
          ...oldPhonesData[id],
          phone: newPhone,
          changed: true,
        },
      };
    });
  };

  return (
    <>
      {Object.values(phonesData).map(({ phone, buttonName, id }) => {
        return (
          <View key={id} style={[styles.section, { borderColor: colors.text }]}>
            <Text>{buttonName}</Text>
            <TextInput
              mode="outlined"
              value={phone}
              placeholder={texts.editProfileButtons.phonePlaceholder}
              keyboardType="phone-pad"
              textContentType="telephoneNumber"
              onChangeText={(newPhone) => {
                handleOnChange(id, newPhone);
              }}
            />
          </View>
        );
      })}
      <Button
        style={styles.buttonSave}
        mode="contained"
        onPress={handleOnSavePress}
      >
        {texts.editProfileButtons.save}
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  section: {
    marginVertical: 8,
  },
  buttonSave: {
    marginTop: 20,
  },
});
