import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import { ActivityIndicator } from 'react-native-paper';
import { RouteProp as NavigationRouteProp } from '@react-navigation/core';

import { ScreenProps, MainNavigatorParams } from '../../MainNavigator';
import { useTheme } from '../../theming';
import { useEffects, useStore } from '../../store';
import { useTranslation } from '../../translations';
import ScreenWrapper from '../../components/ScreenWrapper';
import { stdAlert } from '../../components/alert';

import HomeScreen from './HomeScreen';
import EditProfileInfoScreen from './EditProfileInfo';
import DataContext from './DataContext';
import EditProfileButtonsStack from './EditProfileButtonsStack';
import ChangeProfilePhotoVideoScreen from './ChangeProfilePhotoVideo';

export type ProfileNavigatorParams = {
  ProfileHome: undefined;
  EditProfileInfo: undefined;
  ChangeProfilePhotoVideo: undefined;
  EditProfileButtonsStack: undefined;
};

type Screens = keyof (ProfileNavigatorParams & MainNavigatorParams);

export type NavigationProp<S extends Screens> = StackNavigationProp<
  ProfileNavigatorParams & MainNavigatorParams,
  S
>;

export type RouteProp<S extends Screens> = NavigationRouteProp<
  ProfileNavigatorParams & MainNavigatorParams,
  S
>;

export type ProfileStackScreenProps<S extends Screens> = {
  navigation: NavigationProp<S>;
  route: RouteProp<S>;
};

const Stack = createStackNavigator<ProfileNavigatorParams>();

type Props = ScreenProps<'ProfileStack'>;

function ProfileStackNavigator({ route, navigation }: Props) {
  const texts = useTranslation('profileHome');
  const { colors } = useTheme();
  const [isUserAllowedToSee, setIsUserAllowedToSee] = useState(false);
  const { api } = useEffects();
  const { user } = useStore();
  const {
    params: { profileID },
  } = route;

  useEffect(() => {
    (async () => {
      const result = await api.getProfilesList();
      if (result.success) {
        const myProfiles = result.success.data;
        const isUserProfile =
          myProfiles.find((profile) => profile.id === profileID) !== undefined;
        if (isUserProfile) {
          setIsUserAllowedToSee(true);
          return;
        }
        // we want to prevent navigating back because axios interceptor navigates to welcome screen already
      } else if (result.error?.response.status !== 401) {
        stdAlert(texts.profileHome.notAllowedToSeeScreen);
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('Dashboard');
        }
      }
    })();
  }, [profileID, user.token]);

  if (isUserAllowedToSee === false) {
    return (
      <ScreenWrapper style={styles.container}>
        <ActivityIndicator />
      </ScreenWrapper>
    );
  }

  return (
    <DataContext.Provider profileID={profileID}>
      <Stack.Navigator
        screenOptions={{
          headerBackTitleVisible: false,
          headerShown: true,
          headerStyle: {
            borderBottomWidth: 0, // removes bottom border from header on the web
            backgroundColor: colors.black,
            shadowColor: 'transparent', // removes bottom border from header on IOS
          },
          headerTintColor: colors.text,
        }}
      >
        <Stack.Screen
          name="ProfileHome"
          options={{ headerTitle: 'Profile home' }}
          component={HomeScreen}
        />

        <Stack.Screen
          name="EditProfileInfo"
          options={{ headerTitle: 'Edit profile info' }}
          component={EditProfileInfoScreen}
        />

        <Stack.Screen
          name="ChangeProfilePhotoVideo"
          options={{ headerTitle: 'Profile Photo/Video' }}
          component={ChangeProfilePhotoVideoScreen}
        />
        <Stack.Screen
          name="EditProfileButtonsStack"
          options={{ headerShown: false }}
          component={EditProfileButtonsStack}
        />
      </Stack.Navigator>
    </DataContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ProfileStackNavigator;
