import React from 'react';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';

import { DrawerNavigationProp as DrawerNavigationPropLib } from '@react-navigation/drawer';
import { RouteProp as NavigationRouteProp } from '@react-navigation/core';

import { Platform, useWindowDimensions } from 'react-native';
import { ScreenProps, MainNavigatorParams } from '../../MainNavigator';
import { useTheme } from '../../theming';
import Icon from '../../components/Icon';
import { MOBILE_WIDTH } from '../../consts';
import DataContext from '../Profile/DataContext';
import SocialMediaLinksScreen from './SocialMediaLinksScreen';
import GalleryScreen from './GalleryScreen';
import DBCHomeScreen from './DBCHomeScreen';
import InfoPageScreen from './InfoPageScreen';
import SubpageScreen from './SubPageScreen';
import QRCodeScreen from './QRCodeScreen';
import SurveyScreen from './SurveyScreen';
import ContactPageScreen from './ContactPageScreen';
import WebDrawerNavigator from './WebDrawerNavigator';
import DescriptionScreen from './WebDrawerNavigator/DescriptionScreen';

type ButtonIndexParam = {
  buttonIndex: number;
};

export type DBCNavigatorParams = {
  DrawerHome: undefined;
  'DBC-Home': undefined;
  'DBC-QRCode': undefined;
  'DBC-Survey': ButtonIndexParam;
  'DBC-Gallery': ButtonIndexParam;
  'DBC-InfoPage': ButtonIndexParam;
  'DBC-SubPage': ButtonIndexParam;
  'DBC-SocialMedia': ButtonIndexParam;
  'DBC-ContactPage': ButtonIndexParam;
  'DBC-Description': ButtonIndexParam;
};

export type DBCNavigatorWebParams = {
  'DBC-Phone': ButtonIndexParam;
  'DBC-Email': ButtonIndexParam;
  'DBC-Website': ButtonIndexParam;
  'DBC-PDF': ButtonIndexParam;
  'DBC-Date': ButtonIndexParam;
  'DBC-Description': ButtonIndexParam;
};

type WebScreens = keyof DBCNavigatorWebParams;

export type WebRouteProp<S extends WebScreens> = NavigationRouteProp<
  DBCNavigatorWebParams,
  S
>;

type Screens = keyof (DBCNavigatorParams & MainNavigatorParams);

export type NavigationProp<S extends Screens> = StackNavigationProp<
  DBCNavigatorParams & MainNavigatorParams,
  S
>;

export type DrawerNavigationProp<S extends keyof DBCNavigatorParams> =
  DrawerNavigationPropLib<DBCNavigatorParams, S>;

export type RouteProp<S extends Screens> = NavigationRouteProp<
  DBCNavigatorParams & MainNavigatorParams,
  S
>;

const Stack = createStackNavigator<DBCNavigatorParams>();

type Props = ScreenProps<'DigitalBusinessCardStack'>;

function DigitalBusinessCardStackNavigator({ route }: Props) {
  const { colors } = useTheme();
  const dimensions = useWindowDimensions();
  const {
    params: { profileID },
  } = route;
  const isWeb = Platform.OS === 'web';

  return (
    <DataContext.Provider profileID={profileID}>
      {isWeb && dimensions.width > MOBILE_WIDTH ? (
        <Stack.Navigator headerMode="none">
          <Stack.Screen name="DrawerHome" component={WebDrawerNavigator} />
        </Stack.Navigator>
      ) : (
        <Stack.Navigator
          screenOptions={{
            headerBackTitleVisible: false,
            headerShown: true,
            headerStyle: {
              borderBottomWidth: 0, // removes bottom border from header on the web
              backgroundColor: colors.black,
              shadowColor: 'transparent', // removes bottom border from header on IOS
            },
            headerTintColor: colors.text,
          }}
        >
          <Stack.Screen
            name="DBC-Home"
            options={{ headerTitle: 'Profile preview' }}
            component={DBCHomeScreen}
          />
          <Stack.Screen
            name="DBC-SocialMedia"
            options={{ headerTitle: 'Social media' }}
            component={SocialMediaLinksScreen}
          />
          <Stack.Screen
            options={{
              headerTitle: 'QR code',
              headerBackImage: ({ tintColor }) => (
                <Icon
                  name="close"
                  color={tintColor}
                  size={22}
                  style={Platform.select({
                    ios: { padding: 8 },
                    web: { padding: 8 },
                  })}
                />
              ),
            }}
            name="DBC-QRCode"
            component={QRCodeScreen}
          />
          <Stack.Screen
            name="DBC-Survey"
            options={{ headerShown: false }}
            component={SurveyScreen}
          />
          <Stack.Screen
            name="DBC-Gallery"
            options={{ headerTitle: 'Photo gallery' }}
            component={GalleryScreen}
          />
          <Stack.Screen
            name="DBC-InfoPage"
            options={{ headerTitle: 'Profile info page' }}
            component={InfoPageScreen}
          />
          <Stack.Screen
            name="DBC-SubPage"
            options={{ headerTitle: 'Subpage' }}
            component={SubpageScreen}
          />
          <Stack.Screen
            name="DBC-ContactPage"
            options={{ headerTitle: 'Profile contact page' }}
            component={ContactPageScreen}
          />
          <Stack.Screen
            name="DBC-Description"
            options={{ headerTitle: 'Description' }}
            component={DescriptionScreen}
          />
        </Stack.Navigator>
      )}
    </DataContext.Provider>
  );
}

export default DigitalBusinessCardStackNavigator;
