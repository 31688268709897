import React from 'react';
import { StyleSheet } from 'react-native';

import { DEFAULT_URL_BANNER } from '../../consts';
import AutoscaleImage from '../AutoscaleImage';

type Props = {
  customTopBannerUrl: string | null;
};

export default function WhitelabelTopBanner({ customTopBannerUrl }: Props) {
  return (
    <AutoscaleImage
      uri={customTopBannerUrl ?? DEFAULT_URL_BANNER}
      style={styles.logoBanner}
    />
  );
}

const styles = StyleSheet.create({
  logoBanner: {
    width: '100%',
    resizeMode: 'contain',
  },
});
