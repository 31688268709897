import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';

import Graphics from '../../../../assets/undraw_change_user_info.svg';
import { useTheme } from '../../../theming';
import Button from '../../../components/Button';
import { openLink } from '../../../utils/buttonActions';
import { useTranslation } from '../../../translations';
import { WebRouteProp } from '../index';
import DataLoaderWrapper from './DataLoaderWrapper';

type Props = {
  route: WebRouteProp<'DBC-Email'>;
};

export default function PDFScreen({ route }: Props) {
  const { colors } = useTheme();
  const texts = useTranslation('errors', 'webNavigation');
  const buttonIndex = route.params?.buttonIndex;
  return (
    <DataLoaderWrapper buttonIndex={buttonIndex}>
      {(PDFButton) => (
        <TouchableOpacity
          onPress={() => {
            openLink(`mailto:${PDFButton.data}`);
          }}
          style={[styles.wrapper, { backgroundColor: colors.darkGrey }]}
        >
          <View style={styles.graphicsWrapper}>
            <Graphics width={600} height={400} />
          </View>
          <View style={styles.buttonWrapper}>
            <Button
              mode="contained"
              onPress={() => openLink(PDFButton.data)}
              contentStyle={styles.button}
              labelStyle={styles.PDFText}
            >
              {texts.webNavigation.downloadPDFLabel}
            </Button>
          </View>
        </TouchableOpacity>
      )}
    </DataLoaderWrapper>
  );
}

const styles = StyleSheet.create({
  graphicsWrapper: {
    alignItems: 'center',
  },
  PDFText: {
    fontSize: 32,
  },
  wrapper: {
    padding: 40,
  },
  buttonWrapper: {
    paddingTop: 20,
  },
  button: {
    paddingVertical: 8,
  },
});
