import { useCallback } from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/core';

export function useNavigateToPreview() {
  const navigation = useNavigation();
  const navigateToPreview = useCallback(
    (id: number | null) => {
      if (id !== null) {
        if (Platform.OS === 'web') {
          window.open(`/profile-preview/${id}`, '_blank');
          return;
        }
        navigation.navigate('DigitalBusinessCardStack', { profileID: id });
      }
    },
    [navigation],
  );
  return { navigateToPreview };
}
