import React from 'react';
import { YellowBox, StatusBar, StyleSheet, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { registerRootComponent } from 'expo';
import { Provider as OvermindProvider } from 'overmind-react';
import { I18nextProvider } from 'react-i18next';
import {
  useFonts,
  Roboto_400Regular,
  Roboto_500Medium,
} from '@expo-google-fonts/roboto';

import { ThemeProvider, useTheme } from './theming';

YellowBox.ignoreWarnings(['OVERMIND']);

import MainNavigator from './MainNavigator';
import { overmind } from './store';
import i18n from './translations';

function LoadingView() {
  const { colors } = useTheme();
  return (
    <View style={[styles.loadingView, { backgroundColor: colors.background }]}>
      <ActivityIndicator />
    </View>
  );
}

function App() {
  const [fontsLoaded] = useFonts({
    Roboto: Roboto_400Regular,
    'Roboto Medium': Roboto_500Medium,
  });

  return (
    <OvermindProvider value={overmind}>
      <StatusBar barStyle="light-content" />
      <I18nextProvider i18n={i18n}>
        <ThemeProvider>
          {fontsLoaded ? <MainNavigator /> : <LoadingView />}
        </ThemeProvider>
      </I18nextProvider>
    </OvermindProvider>
  );
}

const styles = StyleSheet.create({
  loadingView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default registerRootComponent(App);
