import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

import Graphics from '../../../../../assets/undraw_description.svg';
import { useTranslation } from '../../../../translations';
import { stdAlert } from '../../../../components/alert';
import { DescriptionDetails } from '../../../../store/effects/api/types';
import EditDescriptionButtonScreenWrapper from './EditDescriptionButtonScreenWrapper';

import EditDescriptionsForm from './EditDescriptionsForm';

export default function EditDescriptionsButtonsScreen() {
  const texts = useTranslation('editProfileButtons', 'errors');
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <EditDescriptionButtonScreenWrapper>
      {(data, saveDescriptionButton) => {
        const descriptionButton =
          data.buttons.find((button) => button.data_type === 'description') ??
          null;

        const descriptionData = {
          id: descriptionButton?.id ?? null,
          elements: descriptionButton?.elements ?? null,
        };

        const handleOnSubmit = async (data: DescriptionDetails) => {
          setIsProcessing(true);
          const result = await saveDescriptionButton(data);
          setIsProcessing(false);

          if (result) {
            stdAlert(texts.editProfileButtons.updateSuccess);
          } else {
            stdAlert(texts.errors.unexpected);
          }
        };
        return (
          <>
            <View style={styles.instructionWrapper}>
              <Text style={styles.instructionText}>
                {texts.editProfileButtons.editDescriptionButtonsInstruction}
              </Text>
            </View>
            <View style={styles.graphicsWrapper}>
              <Graphics width={300} height={200} />
            </View>
            <EditDescriptionsForm
              initialDescriptionData={descriptionData}
              onSubmit={handleOnSubmit}
              isProcessing={isProcessing}
            />
          </>
        );
      }}
    </EditDescriptionButtonScreenWrapper>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  graphicsWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  instructionText: {
    opacity: 0.8,
    textAlign: 'center',
  },
});
