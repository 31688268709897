import React, { useState } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { Text, TextInput } from 'react-native-paper';

import { useActions } from '../../store';
import Graphics from '../../../assets/undraw_forgot_password.svg';
import ScreenWrapper from '../../components/ScreenWrapper';
import { stdAlert } from '../../components/alert';
import Button from '../../components/Button';
import { useTranslation } from '../../translations';

import { NavigationProp } from './index';

type Props = {
  navigation: NavigationProp<'ForgotPasswordHome'>;
};

export default function ForgotPasswordHomeScreen({ navigation }: Props) {
  const texts = useTranslation('errors', 'forms', 'forgotPassword');
  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const { user: userActions } = useActions();

  const handleSendButtonPress = async () => {
    setIsProcessing(true);
    const result = await userActions.sendResetPasswordInstructions({ email });
    if (result === true) {
      stdAlert(texts.forgotPassword.instructionsSentSuccess);
      navigation.replace('Welcome');
    } else {
      stdAlert(texts.errors.unexpected);
    }
    setIsProcessing(false);
  };

  return (
    <ScreenWrapper noPadding>
      <ScrollView
        style={styles.scrollViewStyles}
        contentContainerStyle={styles.scrollViewContentContainerStyles}
      >
        <ScreenWrapper.Content>
          <View style={styles.instructionWrapper}>
            <Text style={styles.instructionText}>
              {texts.forgotPassword.enterEmailInstruction}
            </Text>
          </View>
          <View style={styles.graphicsWrapper}>
            <Graphics width={300} height={200} />
          </View>
          <TextInput
            style={styles.input}
            textContentType="emailAddress"
            keyboardType="email-address"
            mode="outlined"
            autoCapitalize="none"
            label={texts.forms.emailLabel}
            value={email}
            onChangeText={setEmail}
          />
          <Button
            loading={isProcessing}
            mode="contained"
            style={styles.buttonSend}
            onPress={handleSendButtonPress}
          >
            {texts.forgotPassword.send}
          </Button>
        </ScreenWrapper.Content>
      </ScrollView>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  instructionText: {
    opacity: 0.8,
    textAlign: 'center',
  },
  graphicsWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  scrollViewStyles: {
    width: '100%',
  },
  scrollViewContentContainerStyles: {
    flexGrow: 1,
  },
  input: {
    marginVertical: 5,
  },
  buttonSend: {
    marginTop: 20,
  },
});
