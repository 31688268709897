import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, ScrollView, View, Image } from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';

import UserImage from '../../components/UserImage';
import Button from '../../components/Button';
import ScreenWrapper from '../../components/ScreenWrapper';
import Icon from '../../components/Icon';
import { DEFAULT_URL_BANNER } from '../../consts';
import { DataLoadStatus } from '../../store/types';
import useAppSize from '../../hooks/useAppSize';
import { useNavigateToPreview } from '../../hooks/useNavigateToPreview';
import { useTranslation } from '../../translations';
import { useTheme } from '../../theming';
import { useCompanyPositionLabel } from '../../hooks/useCompanyPositionLabel';

import DataContext from './DataContext';
import DownloadQRCodeButton from './DownloadQRCodeButton';
import { NavigationProp } from './index';

type Props = {
  navigation: NavigationProp<'ProfileHome'>;
};

export default function HomeScreen({ navigation }: Props) {
  const texts = useTranslation('errors', 'common', 'profileHome', 'homeScreen');

  const { colors } = useTheme();
  const appSize = useAppSize();
  const { data, status, getProfileQRCodeUrl } = useContext(DataContext.Context);
  const [barcodeUrl, setBarcodeUrl] = useState<string | null>(null);
  const [isLoadingBarcode, setIsLoadingBarcode] = useState(true);
  const imageSize = appSize.width * 0.35;
  const bannerHeight = imageSize * 0.85;
  const companyLabel = useCompanyPositionLabel({
    company: data?.company,
    profileRole: data?.profile_role,
  });
  const { navigateToPreview } = useNavigateToPreview();
  const handleProfilePreviewPress = () => {
    if (data !== null) {
      navigateToPreview(data.id);
    }
  };

  const handleEditProfileInfoPress = () => {
    navigation.navigate('EditProfileInfo');
  };

  const handleChangeProfilePhotoVideoPress = () => {
    navigation.navigate('ChangeProfilePhotoVideo');
  };

  const handleEditButtonsPress = () => {
    navigation.navigate('EditProfileButtonsStack');
  };

  useEffect(() => {
    if (status === DataLoadStatus.SUCCESS && data !== null) {
      (async () => {
        setIsLoadingBarcode(true);
        const response = await getProfileQRCodeUrl();
        if (response) {
          setBarcodeUrl(response.QRCodeUrl);
        }
        setIsLoadingBarcode(false);
      })();
    }
  }, [status, data]);

  const profileRoleAndNameHeight = 32;

  return (
    <ScreenWrapper style={styles.container}>
      {status === DataLoadStatus.SUCCESS && data !== null ? (
        <ScrollView
          style={styles.fullWidth}
          contentContainerStyle={styles.scrollViewContentContainerStyles}
        >
          <View>
            <View style={[styles.bannerWrapper, { height: bannerHeight }]}>
              <Image
                style={styles.banner}
                resizeMode="cover"
                source={{ uri: data.logo_url ?? DEFAULT_URL_BANNER }}
                accessibilityIgnoresInvertColors
              />
            </View>
            <View
              style={[
                styles.profileInfoWrapper,
                {
                  top: bannerHeight - imageSize / 2,
                },
              ]}
            >
              <View
                style={[
                  styles.imageWrapper,
                  { width: imageSize, height: imageSize },
                ]}
              >
                <UserImage
                  style={[styles.userImage, { borderColor: colors.background }]}
                  picture={data.picture_url}
                  video={data.video}
                  videoClip={data.video_clip_url}
                  mediaType={data.picture_or_video}
                />
              </View>
              <Text style={styles.profileName}>{data.name ?? data.title}</Text>
              {companyLabel !== null ? (
                <View style={styles.profileDetailsWrapper}>
                  <Text style={styles.profileDetailsText}>{companyLabel}</Text>
                </View>
              ) : null}
            </View>
            <View
              style={{
                marginTop: imageSize / 2 + 2 * profileRoleAndNameHeight,
              }}
            >
              <Button
                contentStyle={styles.contentButton}
                onPress={handleProfilePreviewPress}
                style={styles.button}
                labelStyle={{ color: colors.black }}
                mode="contained"
              >
                {data.button_label || texts.profileHome.profilePreview}
              </Button>
              <Button
                contentStyle={styles.contentButton}
                onPress={handleEditProfileInfoPress}
                style={styles.button}
                mode="outlined"
                icon={() => (
                  <Icon
                    name="account-edit"
                    style={[styles.icon, { color: colors.text }]}
                  />
                )}
              >
                {texts.profileHome.editProfileInfo}
              </Button>
              <Button
                contentStyle={styles.contentButton}
                onPress={handleChangeProfilePhotoVideoPress}
                style={styles.button}
                mode="outlined"
                icon={() => (
                  <Icon
                    name="camera-plus"
                    style={[styles.icon, { color: colors.text }]}
                  />
                )}
              >
                {texts.homeScreen.setPhotoVideo}
              </Button>
              <Button
                contentStyle={styles.contentButton}
                onPress={handleEditButtonsPress}
                style={styles.button}
                mode="outlined"
                icon={() => (
                  <Icon
                    name="square-edit-outline"
                    style={[styles.icon, { color: colors.text }]}
                  />
                )}
              >
                {texts.profileHome.editProfileButtons}
              </Button>
              <DownloadQRCodeButton
                loading={isLoadingBarcode}
                style={styles.button}
                QRCodeUrl={barcodeUrl ?? ''}
              />
            </View>
          </View>
        </ScrollView>
      ) : status === DataLoadStatus.ERROR ? (
        <Text>{texts.errors.unexpected}</Text>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollViewContentContainerStyles: {
    flexGrow: 1,
  },
  fullWidth: {
    width: '100%',
  },
  profileInfoWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    alignItems: 'center',
  },
  imageWrapper: {
    paddingBottom: 8,
  },
  profileDetailsWrapper: {
    alignItems: 'center',
    paddingTop: 4,
  },
  profileName: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  button: {
    marginVertical: 5,
  },
  contentButton: {
    paddingVertical: 4,
  },
  banner: {
    width: '100%',
    height: '100%',
  },
  bannerWrapper: {
    alignSelf: 'stretch',
  },
  userImage: {
    borderWidth: 5,
  },
  profileDetailsText: {
    textAlign: 'center',
  },
});
