import React from 'react';
import { View, StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { useTheme } from '../theming';
import Icon from './Icon';

type Props = {
  style?: StyleProp<ViewStyle>;
  name: 'facebook' | 'instagram' | 'twitter' | 'youtube' | 'linkedin' | 'vimeo';
  size?: number;
};

const ICON_SIZE = 12;
const PADDING_SIZE = 4;
export default function SocialMediaIcon({
  style,
  name,
  size = ICON_SIZE,
}: Props) {
  const { colors } = useTheme();
  return (
    <View
      style={[
        styles.socialMediaIconWrapper,
        {
          backgroundColor: colors.text,
          borderRadius: (size + PADDING_SIZE * 2) / 2,
        },
        style,
      ]}
    >
      <Icon name={name} color={colors.black} size={size} />
    </View>
  );
}

const styles = StyleSheet.create({
  socialMediaIconWrapper: {
    padding: PADDING_SIZE,
  },
});
