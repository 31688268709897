import React from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/core';

import Button from '../components/Button';
import { useTranslation } from '../translations';
import { useTheme } from '../theming';
import { useActions } from '../store';
import { NavigationProp } from '../MainNavigator';

export default function LogoutButton() {
  const navigation = useNavigation<NavigationProp<'Dashboard'>>();
  const { colors, spacings } = useTheme();
  const texts = useTranslation('common');
  const {
    user: { logout },
  } = useActions();

  const handleLogout = () => {
    logout();
    navigation.reset({ index: 0, routes: [{ name: 'Welcome' }] });
  };

  return (
    <Button
      style={{
        borderColor: colors.primary,
        marginRight: spacings.content.horizontal,
      }}
      onPress={handleLogout}
      icon="logout"
      labelStyle={[styles.labelText, { color: colors.primary }]}
      mode="outlined"
    >
      {texts.common.logout}
    </Button>
  );
}

const styles = StyleSheet.create({
  labelText: {
    fontSize: 12,
  },
});
