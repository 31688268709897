import React, { useContext } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';

import ScreenWrapper from '../../../components/ScreenWrapper';
import { useTranslation } from '../../../translations';
import {
  ProfileDetails,
  EditButton,
  ButtonType,
} from '../../../store/effects/api/types';
import { DataLoadStatus } from '../../../store/types';

import DataContext from '../DataContext';

type Props = {
  children: (
    profileDetails: ProfileDetails,
    saveProfileButtons: (
      buttonsData: EditButton[],
      buttonsType: NonNullable<ButtonType['data_type']>,
    ) => Promise<boolean>,
  ) => React.ReactNode;
};

export default function EditButtonsScreenWrapper({ children }: Props) {
  const texts = useTranslation('errors');
  const { data, status, saveProfileButtons } = useContext(DataContext.Context);
  return (
    <ScreenWrapper
      style={styles.container}
      noPadding
      withKeyboardAwareScrollView
    >
      {status === DataLoadStatus.SUCCESS && data !== null ? (
        <ScrollView
          style={styles.scrollViewStyle}
          contentContainerStyle={styles.scrollViewContentContainerStyles}
        >
          <ScreenWrapper.Content>
            {children(data, saveProfileButtons)}
          </ScreenWrapper.Content>
        </ScrollView>
      ) : status === DataLoadStatus.ERROR ? (
        <Text>{texts.errors.unexpected}</Text>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  scrollViewContentContainerStyles: { flexGrow: 1 },
  scrollViewStyle: { width: '100%' },
});
