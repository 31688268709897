import React, { useState, useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, TextInput } from 'react-native-paper';

import { useTranslation } from '../../translations';
import { stdAlert } from '../../components/alert';
import Button from '../../components/Button';
import Graphics from '../../../assets/undraw_profile_6l1l.svg';
import WhitelabelFooter from '../../components/Whitelabel/Footer';
import { useActions } from '../../store';

import OnboardingScreenWrapper from './OnboardingScreenWrapper';
import InvitationDataContext from './InvitationDataContext';
import { NavigationProp } from './index';

type Props = { navigation: NavigationProp<'OnboardingWelcome'> };

export default function OnboardingWelcomeScreen({ navigation }: Props) {
  const { invitationHash } = useContext(InvitationDataContext.Context);
  const texts = useTranslation(
    'common',
    'welcome',
    'onboarding',
    'login',
    'errors',
  );
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const { profiles } = useActions();
  const [password, setPassword] = useState('');

  const handleSignUpPress = () => {
    if (!isLoading) {
      navigation.navigate('SignUp');
    }
  };

  const handleCreateProfilePress = async () => {
    setIsLoading(true);
    const result = await profiles.loginAndCreateProfile({
      email,
      password,
      invitationHash,
    });
    setIsLoading(false);

    if (result === true) {
      stdAlert(texts.onboarding.newProfileCreated);
      navigation.replace('Dashboard');
    } else {
      stdAlert(texts.errors.unexpected);
    }
  };

  return (
    <OnboardingScreenWrapper>
      {(invitationData) => {
        return (
          <>
            <View style={styles.content}>
              <View style={styles.welcomeTextWrapper}>
                <Text style={styles.welcomeText}>
                  {texts.welcome.welcomeText}
                </Text>
                <Text
                  style={[styles.welcomeText, styles.createAccountOrPofileText]}
                >
                  {texts.welcome.createAccountOrProfile}
                </Text>
              </View>
              <View style={styles.graphicsWrapper}>
                <Graphics width={300} height={200} />
              </View>
              <Button
                style={styles.button}
                mode="contained"
                onPress={handleSignUpPress}
              >
                {texts.onboarding.createAccount}
              </Button>
              <View style={styles.orDividerWrapper}>
                <Text>{texts.common.orDivider}</Text>
                <View style={styles.orOptionWrapper}>
                  <Text style={styles.orOptionText}>
                    {texts.onboarding.alreadyHaveAccount}
                  </Text>
                </View>
              </View>
              <View>
                <TextInput
                  mode="outlined"
                  value={email}
                  onChangeText={setEmail}
                  label={texts.login.emailLabel}
                  textContentType="emailAddress"
                  autoCapitalize="none"
                  style={styles.inputHeight}
                />
                <TextInput
                  mode="outlined"
                  value={password}
                  onChangeText={setPassword}
                  textContentType="password"
                  secureTextEntry={true}
                  label={texts.login.passwordLabel}
                  autoCapitalize="none"
                  style={styles.input}
                />
                <Button
                  loading={isLoading}
                  mode="outlined"
                  onPress={handleCreateProfilePress}
                  style={styles.button}
                >
                  {texts.onboarding.createProfile}
                </Button>
              </View>
            </View>
            <WhitelabelFooter
              customPoweredByUrl={
                invitationData.template_white_label.custom_powered_by_url
              }
            />
          </>
        );
      }}
    </OnboardingScreenWrapper>
  );
}

const styles = StyleSheet.create({
  content: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  welcomeTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  createAccountOrPofileText: {
    opacity: 0.8,
  },
  welcomeText: {
    fontSize: 16,
    lineHeight: 25,
  },
  graphicsWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  button: {
    marginTop: 20,
  },
  inputHeight: {
    maxHeight: 56,
  },
  input: {
    maxHeight: 56,
    marginVertical: 8,
  },
  orOptionText: {
    textAlign: 'center',
    opacity: 0.8,
    fontSize: 12,
  },
  orOptionWrapper: {
    paddingTop: 4,
  },
  orDividerWrapper: {
    paddingVertical: 4,
    alignItems: 'center',
  },
});
