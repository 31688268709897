import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Paragraph, Subheading, TextInput } from 'react-native-paper';

import { useTranslation } from '../../translations';
import { stdAlert } from '../../components/alert';
import { UserAccountGeneral } from '../../store/effects/api/types';
import Button from '../../components/Button';
import SocialMediaInput from '../../components/SocialMediaInput';

type Props = {
  initialUserData: UserAccountGeneral;
  isProcessing: boolean;
  onSubmit: (user: UserAccountGeneral) => void;
};

export default function UserInfoForm({
  isProcessing,
  initialUserData,
  onSubmit,
}: Props) {
  const texts = useTranslation('forms', 'edit');
  const [name, setName] = useState(initialUserData.name);
  const [lastName, setLastName] = useState(initialUserData.last_name);
  const [email, setEmail] = useState(initialUserData.email);
  const [phone, setPhone] = useState(initialUserData.phone);
  const [website, setWebsite] = useState(initialUserData.website ?? '');
  const [facebook, setFacebook] = useState(
    initialUserData.social_facebook ?? '',
  );
  const [instagram, setInstagram] = useState(
    initialUserData.social_instagram ?? '',
  );
  const [twitter, setTwitter] = useState(initialUserData.social_twitter ?? '');
  const [youtube, setYoutube] = useState(initialUserData.social_youtube ?? '');
  const [linkedin, setLinkedin] = useState(
    initialUserData.social_linkedin ?? '',
  );
  const [vimeo, setVimeo] = useState(initialUserData.social_vimeo ?? '');

  function validateRequiredFields() {
    return (
      name.trim().length !== 0 &&
      lastName.trim().length !== 0 &&
      email.trim().length !== 0 &&
      phone.trim().length !== 0
    );
  }

  const handleSubmit = () => {
    const areRequiredFieldsFilled = validateRequiredFields();
    if (!areRequiredFieldsFilled) {
      stdAlert(texts.forms.fillAllFields);
      return;
    }

    const userAccount: UserAccountGeneral = {
      name,
      last_name: lastName,
      email,
      phone,
      website: website === '' ? null : website,
      social_facebook: facebook === '' ? null : facebook,
      social_instagram: instagram === '' ? null : instagram,
      social_linkedin: linkedin === '' ? null : linkedin,
      social_youtube: youtube === '' ? null : youtube,
      social_twitter: twitter === '' ? null : twitter,
      social_vimeo: vimeo === '' ? null : vimeo,
    };

    onSubmit(userAccount);
  };

  return (
    <>
      <TextInput
        mode="outlined"
        value={name}
        onChangeText={setName}
        label={texts.forms.nameLabel}
        autoCapitalize="sentences"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={lastName}
        onChangeText={setLastName}
        label={texts.forms.lastNameLabel}
        autoCapitalize="sentences"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={email}
        keyboardType="email-address"
        onChangeText={setEmail}
        label={texts.forms.emailLabel}
        textContentType="emailAddress"
        autoCapitalize="none"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={phone}
        keyboardType="phone-pad"
        onChangeText={setPhone}
        label={texts.forms.phoneNumLabel}
        textContentType="telephoneNumber"
        autoCapitalize="none"
        style={styles.input}
      />
      <TextInput
        mode="outlined"
        value={website}
        onChangeText={setWebsite}
        label={texts.forms.websiteLabel}
        autoCapitalize="none"
        style={styles.input}
      />
      <Subheading style={styles.premiumTitle}>PREMIUM</Subheading>
      <Paragraph>
        Social media will only display in your PREMIUM profiles
      </Paragraph>
      <SocialMediaInput
        socialMediaName="facebook"
        onChangeText={setFacebook}
        value={facebook}
        style={styles.socialMediaInput}
      />
      <SocialMediaInput
        socialMediaName="instagram"
        onChangeText={setInstagram}
        value={instagram}
        style={styles.socialMediaInput}
      />
      <SocialMediaInput
        socialMediaName="twitter"
        onChangeText={setTwitter}
        value={twitter}
        style={styles.socialMediaInput}
      />
      <SocialMediaInput
        socialMediaName="youtube"
        onChangeText={setYoutube}
        value={youtube}
        style={styles.socialMediaInput}
      />
      <SocialMediaInput
        socialMediaName="linkedin"
        onChangeText={setLinkedin}
        value={linkedin}
        style={styles.socialMediaInput}
      />
      <SocialMediaInput
        socialMediaName="vimeo"
        onChangeText={setVimeo}
        value={vimeo}
        style={styles.socialMediaInput}
      />
      <Button
        loading={isProcessing}
        mode="contained"
        onPress={handleSubmit}
        style={styles.button}
      >
        {texts.edit.save}
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  input: {
    marginVertical: 5,
  },
  button: {
    marginTop: 20,
  },
  socialMediaInput: {
    marginVertical: 5,
  },
  premiumTitle: {
    color: 'red',
    fontWeight: 'bold',
  },
});
