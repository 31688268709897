import React from 'react';
import { StyleSheet } from 'react-native';

import { openLink } from '../utils/buttonActions';
import Button from './Button';

type Props = {
  socialMediaData: {
    facebook: string | null;
    instagram: string | null;
    linkedin: string | null;
    myspace: string | null;
    twitter: string | null;
    vimeo: string | null;
    youtube: string | null;
    [key: string]: string | null;
  };
};

export default function SocialMediaContactPage({ socialMediaData }: Props) {
  return (
    <>
      {Object.keys(socialMediaData)
        .filter(
          (socialMediaName) =>
            socialMediaData[socialMediaName] !== null &&
            socialMediaData[socialMediaName] !== '',
        )
        .map((socialMediaName) => {
          const socialMediaLink = socialMediaData[socialMediaName] as string;
          const socialMediaLinkWithProtocol = /^https?/.test(socialMediaLink)
            ? socialMediaLink
            : `https://${socialMediaLink}`;
          return (
            <Button
              key={socialMediaName}
              icon={socialMediaName}
              mode="outlined"
              style={styles.button}
              onPress={() => openLink(socialMediaLinkWithProtocol)}
            >
              {socialMediaName}
            </Button>
          );
        })}
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    marginVertical: 5,
  },
});
