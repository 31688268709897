import React, { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native-paper';
import { FlatList, StyleSheet, View } from 'react-native';
import {
  SavedProfileMeta,
  SavedProfilesTree,
} from '../../store/effects/api/types';
import { api } from '../../store/effects';
import FolderItem from './FolderItem';
import ListItem from './ListItem';

interface FoldersNavigatorProps {
  isModalsVisible?: boolean;
  onCurrentFolderChange?: (id?: string) => void;
  onFolderOptions?: (id?: string) => void;
  onSelect?: (id: string) => void;
  previewMode?: boolean;
  selected?: string[];
}

export default function FoldersNavigator({
  isModalsVisible,
  onCurrentFolderChange,
  onFolderOptions,
  onSelect = () => true,
  previewMode = false,
  selected = [],
}: FoldersNavigatorProps) {
  const [currentFolderId, setCurrentFolderId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [tree, setTree] = useState<SavedProfilesTree>({
    current_folder_id: null,
    current_folder_name: null,
    folders: [],
    parent_folder_id: null,
    parent_folder_name: null,
    profiles: [],
  });

  useEffect(() => {
    if (typeof onCurrentFolderChange !== 'undefined') {
      onCurrentFolderChange(currentFolderId);
    }
    setIsLoading(true);
    api.getSavedProfilesList(currentFolderId).then((response) => {
      if (response.success) {
        setTree(response.success.data);
        setIsLoading(false);
      }
    });
  }, [currentFolderId, isModalsVisible]);

  if (isLoading) {
    return <ActivityIndicator />;
  }

  return (
    <>
      {currentFolderId !== undefined && (
        <FolderItem
          customIcon="chevron-left"
          folderName={tree.parent_folder_name ?? 'All saved profiles'}
          onPress={() => {
            // @ts-ignore
            setCurrentFolderId(tree.parent_folder_id ?? undefined);
          }}
        />
      )}
      <FlatList
        data={[
          ...tree.folders.map((item) => ({ type: 'folder', ...item })),
          ...tree.profiles.map((item) => ({ type: 'profile', ...item })),
        ]}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => {
          if (item.type === 'folder') {
            return (
              <FolderItem
                folderName={item.name ?? ''}
                onLongPress={() => {
                  if (onFolderOptions) {
                    onFolderOptions(item.id);
                  }
                }}
                onPress={() => {
                  setIsLoading(true);
                  setCurrentFolderId(item.id);
                }}
              />
            );
          }

          return (
            <View>
              {previewMode ? (
                <FolderItem
                  customIcon="account"
                  disabled
                  folderName={item.name ?? ''}
                />
              ) : (
                <ListItem
                  selected={selected.includes(item.id.toString())}
                  onSelectProfile={onSelect}
                  item={item as SavedProfileMeta}
                />
              )}
            </View>
          );
        }}
        style={styles.listContainer}
      />
    </>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    width: '100%',
  },
});
