const translations = {
  common: {
    theTagNetwork: 'The Tag Network',
    login: 'Login',
    logout: 'Logout',
    signUp: 'Sign Up',
    emptyList: 'List is empty',
    at: 'at',
    orDivider: '- or -',
  },
  webNavigation: {
    profilePreview: 'Profile preview',
    backLabel: 'Back',
    emailText: 'Email',
    phoneNumberText: 'Phone number',
    websiteText: 'Website',
    downloadPDFLabel: 'Download PDF',
    googleCalendar: 'Google Calendar',
    iCal: 'iCal',
    description: 'Description',
  },
  errors: {
    unauthorized: 'User unauthorized',
    unexpected: 'An unexpected error ocurred',
  },
  welcome: {
    welcomeText: 'Welcome!',
    loginToYourAccount: 'Login to your account',
    createAccountOrProfile: 'Create an account or a new profile',
    forgotPassword: 'Forgot your password?',
    noLinkSignUp:
      'You need to open an app from unique sign up link to be able to sign up',
  },
  login: {
    emailLabel: 'email',
    passwordLabel: 'password',
    invalidCredentials: 'Login failed, invalid credentials.',
  },
  dashboard: {
    editUserInfo: 'Edit user info',
    myProfiles: 'My profiles',
    savedProfiles: 'Saved profiles',
    scannedProfiles: 'Scanned profiles',
    scan: 'Scan',
  },
  savedProfiles: {
    actionUndone: 'This action is irreversible.',
    areYouSure: 'Are you sure that you want to delete this folder?',
    yesDelete: 'Yes, Delete',
    noCancel: 'No, Cancel',
    deleteFolder: 'Delete folder',
    newFolder: 'New folder',
  },
  homeScreen: {
    setPhotoVideo: 'Set Photo/Video',
    whatDoYouWant: 'What do you want to do?',
  },
  digitalBusinessCard: {
    couldNotOpenLink: 'The device is not able to open the link',
    QRCode: 'QR Code',
    scan: 'Scan',
    save: 'Save',
    share: 'Share',
    saveSuccess: 'Profile has been saved!',
    vcard: 'Save vCard',
  },
  QRScanner: {
    instruction: 'Put QR code in the frame to scan it',
    scan: 'Scan',
    noPermissionGranted: 'Permission to use camera was not granted',
    profileAlreadyScanned: 'This profile has been already scanned today!',
  },
  onboarding: {
    next: 'Next',
    alreadyHaveAccount: 'Already have an account?',
    alreadyCreatedProfile:
      'You have already created a profile from this template',
    onboardingTitle: 'Onboarding',
    newProfileCreated: 'A new profile has been created!',
    askCreateNewProfile: 'Do you want to create a new profile?',
    createAccount: 'Create new account and profile',
    createProfile: 'Create a profile',
    signUpInstruction: 'Fill the details & create account',
    invitationLinkNotValid: "Invitation link expired or doesn't exist",
    registerUserError: 'There was a problem with creating your account',
    uploadOrTakePhoto: 'Upload photo or take a photo',
    accountCreated: 'The account has been created!',
    goToDashboard: 'Go to dashboard',
  },
  forms: {
    passwordsNotMatch: 'Passwords do not match',
    nameLabel: 'name',
    profileNameLabel: 'profile name (optional)',
    fillAllFields: 'All fields must be filled',
    lastNameLabel: 'last name',
    emailLabel: 'email',
    phoneNumLabel: 'phone number',
    passwordLabel: 'password',
    companyLabel: 'company (optional)',
    websiteLabel: 'website (optional)',
    roleLabel: 'role (optional)',
    mainTitleLabel: 'main profile title (optional)',
    newPasswordLabel: 'new password',
    newPasswordConfirmLabel: 'new password confirmation',
    passwordConfirmLabel: 'password confirmation',
    currentPasswordLabel: 'current password',
    chooseFile: 'Choose file',
    noChosenFile: 'No file chosen',
    answerPlaceholder: 'Please answer here ...',
  },
  userMedia: {
    noCameraPermission: 'We need camera permissions to make this work!',
    addPhoto: 'Add your photo',
    takePhoto: 'Take a photo',
    takeNewPhoto: 'Take a new photo',
    takeNewVideo: 'Take a new video',
    uploadPhoto: 'Upload a photo',
    uploadVideo: 'Upload a video',
    uploadTopBanner: 'Upload a top banner',
    changePhoto: 'Do you want to change your photo/video?',
    changeTopBanner: 'Do you want to change your top banner?',
    insertLink: 'Insert link to a video on Youtube/Vimeo',
    onlyYoutubeOrVimeo:
      'Only links from Youtube or vimeo are currently supported',
  },
  whitelabel: {
    poweredBy: 'Powered by',
  },
  edit: {
    editProfileInfo: 'Do you want to change your profile info?',
    editGeneralInfo: 'Do you want to change your personal info?',
    save: 'Save',
    updateSuccess: 'Your data has been updated!',
    editPassword: 'Do you want to change your password?',
  },
  profiles: {
    createdDate: 'Created on',
    savedDate: 'Saved on',
    scannedDate: 'Scanned on',
  },
  editProfileButtons: {
    emailButton: 'Email',
    emailPlaceholder: 'email',
    phoneButton: 'Phone',
    phonePlaceholder: 'phone',
    website: 'Website',
    websitePlaceholder: 'website',
    pdf: 'PDF',
    survey: 'Survey',
    referral: 'Referral',
    save: 'Save',
    description: 'Description',
    descriptionPlaceholder: 'description',
    updateSuccess: 'Your buttons data has been updated!',
    emailForSurveyResultsLabel: 'Email address to send results',
    phoneForSurveyResultsLabel: 'Cell phone to send results',
    editButtonsHomeInstruction: 'Do you want to edit your profile buttons?',
    editEmailButtonsInstruction: 'You can edit your email buttons info here.',
    editPhoneButtonsInstruction: 'You can edit your phone buttons info here.',
    editWebsiteButtonsInstruction: 'You can edit your email buttons info here.',
    editPDFButtonsInstruction: 'You can edit your PDF buttons info here',
    editSurveyButtonsInstruction: 'You can edit your survey buttons info here',
    editReferralButtonsInstruction:
      'You can edit your referral buttons instruction',
    editDescriptionButtonsInstruction:
      'You can edit your description info here',
  },
  editDescriptionButtons: {
    addText: 'Add text',
    addPhoto: 'Add photo',
    addVideo: 'Add video',
    insertLink: 'Insert link',
    removeSectionQuestion: 'Do you want to remove that section?',
    changePhoto: 'Change a photo',
    changeVideo: 'Change a video',
    insertLinkPlaceholder: 'insert link',
  },
  forgotPassword: {
    newPasswordLabel: 'new password',
    newPasswordConfirmLabel: 'new password confirmation',
    save: 'Save',
    send: 'Send',
    instructionsSentSuccess: 'Reset password instructions have been sent',
    resetPasswordSuccess: 'Your password has been reset!',
    enterEmailInstruction:
      'Enter your email in order to receive reset password instructions.',
    resetPasswordInstruction: 'You can provide here a new password',
  },
  profileHome: {
    editProfileButtons: 'Edit buttons',
    editProfileInfo: 'Edit profile info',
    profilePreview: 'Profile preview',
    downloadQRCode: 'Download QR Code',
    reports: 'Profile Reports',
    notAllowedToSeeScreen:
      'You are not allowed to see other users profile home screen',
  },
  share: {
    QRCode: 'Share your QR code to connect with other people.',
  },
  survey: {
    defaultThanksMsg: 'Thanks! Survey has been submited',
  },
  premiumFeatureGate: {
    notAvailable: 'Available to premium users only.',
    overlay: 'Premium',
  },
};

export default translations;
