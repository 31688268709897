import React, { useRef, useEffect } from 'react';
import { useNavigation } from '@react-navigation/core';
import { Animated, StyleSheet } from 'react-native';
import { useTranslation } from '../../translations';
import { SavedProfileMeta } from '../../store/effects/api/types';
import { useTheme } from '../../theming';
import { NavigationProp } from '../../MainNavigator';
import { getFormattedDate } from '../../utils/date';
import ProfilesListItem from '../../components/ProfilesListItem';
import Icon from '../../components/Icon';

type Props = {
  item: SavedProfileMeta;
  onSelectProfile: (id: string) => void;
  selected?: boolean;
};

function SavedProfilesListItem({
  item: profile,
  onSelectProfile,
  selected,
}: Props) {
  const texts = useTranslation('profiles');
  const { colors } = useTheme();
  const navigation = useNavigation<NavigationProp<'SavedProfiles'>>();
  const opacity = useRef(new Animated.Value(0));

  const savedAtDate = new Date(profile.saved_at);
  const formattedDate = getFormattedDate(savedAtDate);
  const dateText = `${texts.profiles.savedDate}:  ${formattedDate}`;

  const handleOnPress = () => {
    navigation.navigate('DigitalBusinessCardStack', {
      profileID: profile.id,
    });
  };
  const listItemStyles =
    selected === true
      ? { tileColor: colors.primary, opacity: 0.8 }
      : { tileColor: colors.black };

  useEffect(() => {
    Animated.timing(opacity.current, {
      toValue: selected === true ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [selected]);

  return (
    <ProfilesListItem
      profile={profile}
      icon={
        <Animated.View
          style={[styles.checkIconWrapper, { opacity: opacity.current }]}
        >
          <Icon name="check-circle-outline" size={30} color={colors.text} />
        </Animated.View>
      }
      onLongPress={() => {
        onSelectProfile(profile.id.toString());
      }}
      onPress={handleOnPress}
      dateText={dateText}
      {...listItemStyles}
    />
  );
}

const styles = StyleSheet.create({
  checkIconWrapper: {
    marginLeft: 'auto',
    alignSelf: 'flex-end',
    marginBottom: 0,
    marginRight: 0,
  },
});

export default SavedProfilesListItem;
