import React, { useContext } from 'react';
import { ScrollView, StyleSheet, Image, View } from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';

import DataContext from '../Profile/DataContext';
import { openLink } from '../../utils/buttonActions';
import Button from '../../components/Button';
import SocialMediaButtons from '../../components/SocialMediaContactPage';
import ScreenWrapper from '../../components/ScreenWrapper';
import { useTranslation } from '../../translations';
import { DataLoadStatus } from '../../store/types';
import LogoHeader from './LogoHeader';
import { RouteProp } from './index';

type Props = {
  route: RouteProp<'DBC-ContactPage'>;
};

export default function ContactPageScreen({ route }: Props) {
  const texts = useTranslation('errors');
  const { data, status } = useContext(DataContext.Context);
  const contactPageData = data?.buttons[route.params.buttonIndex];

  const handleWebpageButtonPress = () => {
    if (
      contactPageData?.contact_page !== undefined &&
      contactPageData.contact_page.website !== null &&
      contactPageData.contact_page.website !== ''
    ) {
      const linkToWebsiteWithProtocol = /^https?/.test(
        contactPageData.contact_page.website,
      )
        ? contactPageData.contact_page.website
        : `https://${contactPageData.contact_page.website}`;
      openLink(linkToWebsiteWithProtocol);
    }
  };

  const handleEmailButtonPress = () => {
    if (
      contactPageData?.contact_page !== undefined &&
      contactPageData.contact_page.email !== null &&
      contactPageData.contact_page.email !== ''
    ) {
      openLink(`mailto:${contactPageData.contact_page.email}`);
    }
  };

  const handlePhoneButtonPress = () => {
    if (
      contactPageData?.contact_page !== undefined &&
      contactPageData.contact_page.phone_number !== null &&
      contactPageData.contact_page.phone_number !== ''
    ) {
      openLink(`tel:${contactPageData.contact_page.phone_number}`);
    }
  };

  return (
    <ScreenWrapper noPadding>
      {status === DataLoadStatus.SUCCESS &&
      data !== null &&
      contactPageData !== undefined &&
      contactPageData.contact_page !== undefined ? (
        <ScrollView>
          <LogoHeader
            logoBanner={
              contactPageData.contact_page.banner_url ?? data.logo_url
            }
            title={contactPageData.label}
            titleBackground={
              contactPageData.background_url ?? data.title_bg_image_url
            }
          />
          {contactPageData.contact_page.custom_subheader !== undefined ? (
            <View style={styles.customButton}>
              <Image
                style={styles.image}
                source={{
                  uri: contactPageData.contact_page.custom_subheader
                    .custom_subheader_url,
                }}
                accessibilityIgnoresInvertColors
              />
              <Text style={styles.label}>
                {
                  contactPageData.contact_page.custom_subheader
                    .custom_subheader_text
                }
              </Text>
            </View>
          ) : null}
          <ScreenWrapper.Content>
            <View style={styles.buttonsWrapper}>
              {contactPageData.contact_page.phone_number !== null &&
              contactPageData.contact_page.phone_number !== '' ? (
                <Button
                  onPress={handlePhoneButtonPress}
                  style={styles.button}
                  icon="phone"
                  mode="outlined"
                >
                  {contactPageData.contact_page.phone_button_label !== ''
                    ? `${contactPageData.contact_page.phone_button_label} `
                    : ''}
                  {contactPageData.contact_page.phone_number}
                </Button>
              ) : null}
              {contactPageData.contact_page.email !== null &&
              contactPageData.contact_page.email !== '' ? (
                <Button
                  style={styles.button}
                  icon="email"
                  onPress={handleEmailButtonPress}
                  mode="outlined"
                >
                  {contactPageData.contact_page.email_button_label !== ''
                    ? contactPageData.contact_page.email_button_label
                    : ''}
                </Button>
              ) : null}
              {contactPageData.contact_page.website !== null &&
              contactPageData.contact_page.website !== '' ? (
                <Button
                  style={styles.button}
                  icon="web"
                  onPress={handleWebpageButtonPress}
                  mode="outlined"
                >
                  {contactPageData.contact_page.website_button_label !== ''
                    ? contactPageData.contact_page.website_button_label
                    : ''}
                </Button>
              ) : null}
              <SocialMediaButtons
                socialMediaData={{
                  facebook: contactPageData.contact_page.facebook,
                  instagram: contactPageData.contact_page.instagram,
                  linkedin: contactPageData.contact_page.linked_in,
                  myspace: contactPageData.contact_page.myspace,
                  twitter: contactPageData.contact_page.twitter,
                  vimeo: contactPageData.contact_page.vimeo,
                  youtube: contactPageData.contact_page.youtube,
                }}
              />
            </View>
          </ScreenWrapper.Content>
        </ScrollView>
      ) : status === DataLoadStatus.ERROR ? (
        <Text>{texts.errors.unexpected}</Text>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  button: {
    marginVertical: 5,
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 20,
    zIndex: 1,
  },
  customButton: {
    width: '100%',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsWrapper: {
    marginTop: 20,
  },
});
