import { useState, useEffect } from 'react';
import { debounce } from 'debounce';
import { ViewStyle, Platform } from 'react-native';

type WebStyles = {
  container?: ViewStyle;
  content?: ViewStyle;
};

export default function useWebWideScreenStyles() {
  const [webStyles, setWebStyles] = useState<WebStyles>(getStyles);

  function getStyles(): WebStyles {
    if (Platform.OS === 'web') {
      if (window.innerWidth > window.innerHeight * 1.2) {
        return {
          container: {
            alignItems: 'center',
          },
          content: {
            width: '50vw',
          },
        };
      }
    }
    return {};
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleResize = () => {
        setWebStyles(getStyles());
      };

      const handleResizeListener = debounce(handleResize, 300);
      window.addEventListener('resize', handleResizeListener);
      return () => {
        window.removeEventListener('resize', handleResizeListener);
      };
    }
  }, []);

  return webStyles;
}
