import { UserMeta } from '../effects/api/types';

export type UserState = {
  token: string | null;
  meta: UserMeta | null;
};

export const state: UserState = {
  meta: null,
  token: null,
};
