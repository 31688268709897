import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

import Graphics from '../../../../../assets/undraw_survey.svg';

import { useTranslation } from '../../../../translations';
import { stdAlert } from '../../../../components/alert';
import EditButtonsScreenWrapper from '../EditButtonsScreenWrapper';

import EditSurveysForm, { SurveyButtonType } from './EditSurveysForm';

export default function EditSurveysButtonsScreen() {
  const texts = useTranslation('editProfileButtons', 'errors');
  return (
    <EditButtonsScreenWrapper>
      {(data, saveProfileButtons) => {
        const handleOnSubmit = async (
          referralsButtonsData: SurveyButtonType[],
        ) => {
          const preparedData = referralsButtonsData.map(
            (referralButtonData) => {
              return {
                id: referralButtonData.id,
                data: {
                  email: referralButtonData.email,
                  phone: referralButtonData.phone,
                },
              };
            },
          );
          const result = await saveProfileButtons(preparedData, 'survey');
          if (result) {
            stdAlert(texts.editProfileButtons.updateSuccess);
          } else {
            stdAlert(texts.errors.unexpected);
          }
        };
        const surveyButtons = data.buttons.filter(
          (button) =>
            button.data_type === 'survey' &&
            button.survey !== undefined &&
            Boolean(button.survey.is_referral) === false, // undefined or false
        );
        return (
          <>
            <View style={styles.instructionWrapper}>
              <Text style={styles.instructionText}>
                {texts.editProfileButtons.editSurveyButtonsInstruction}
              </Text>
            </View>
            <View style={styles.graphicsWrapper}>
              <Graphics width={300} height={200} />
            </View>
            <EditSurveysForm
              onSubmit={handleOnSubmit}
              initialSurveysData={surveyButtons.map(
                ({ id, data, label, survey }) => ({
                  id,
                  buttonName: label,
                  email: data,
                  phone: survey?.cell_phone ?? '',
                }),
              )}
            />
          </>
        );
      }}
    </EditButtonsScreenWrapper>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  graphicsWrapper: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  instructionText: {
    opacity: 0.8,
    textAlign: 'center',
  },
});
