export type WhitelabelState = {
  customBgUrl: string | null | undefined;
  customPoweredByUrl: string | null | undefined;
  customTopBannerUrl: string | null | undefined;
  whiteLabelPrimaryColor: string | null | undefined;
};

export const state: WhitelabelState = {
  customBgUrl: null,
  customPoweredByUrl: null,
  customTopBannerUrl: null,
  whiteLabelPrimaryColor: null,
};
