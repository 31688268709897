import React from 'react';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import { RouteProp as NavigationRouteProp } from '@react-navigation/core';

import { useTheme } from '../../../theming';
import { MainNavigatorParams } from '../../../MainNavigator';
import EditProfileButtonsHome from './EditProfileButtonsHome';
import EditEmailButtonsScreen from './EditEmailButtons';
import EditPhoneButtonsScreen from './EditPhoneButtons';
import EditWebsiteButtonsScreen from './EditWebsiteButtons';
import EditPDFButtonsScreen from './EditPDFButtons';
import EditSurveysButtonsScreen from './EditSurveyButtons';
import EditReferralButtonsScreen from './EditReferralButtonsScreen';
import EditDescriptionButtonsScreen from './EditDescriptionButtons';

export type EditProfileButtonsNavigatorParams = {
  EditProfileButtonsHome: undefined;
  EditEmailButtons: undefined;
  EditPhoneButtons: undefined;
  EditWebsiteButtons: undefined;
  EditPDFButtons: undefined;
  EditSurveyButtons: undefined;
  EditReferralButtons: undefined;
  EditDescriptionButtons: undefined;
};

type Screens = keyof (EditProfileButtonsNavigatorParams & MainNavigatorParams);

export type NavigationProp<S extends Screens> = StackNavigationProp<
  EditProfileButtonsNavigatorParams & MainNavigatorParams,
  S
>;

export type RouteProp<S extends Screens> = NavigationRouteProp<
  EditProfileButtonsNavigatorParams & MainNavigatorParams,
  S
>;

const Stack = createStackNavigator<EditProfileButtonsNavigatorParams>();

export default function EditProfileButtonsStack() {
  const { colors } = useTheme();
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerShown: true,
        headerStyle: {
          borderBottomWidth: 0, // removes bottom border from header on the web
          backgroundColor: colors.black,
          shadowColor: 'transparent', // removes bottom border from header on IOS
        },
        headerTintColor: colors.text,
      }}
    >
      <Stack.Screen
        name="EditProfileButtonsHome"
        component={EditProfileButtonsHome}
      />
      <Stack.Screen
        name="EditEmailButtons"
        component={EditEmailButtonsScreen}
      />
      <Stack.Screen
        name="EditPhoneButtons"
        component={EditPhoneButtonsScreen}
      />
      <Stack.Screen
        name="EditWebsiteButtons"
        component={EditWebsiteButtonsScreen}
      />
      <Stack.Screen name="EditPDFButtons" component={EditPDFButtonsScreen} />
      <Stack.Screen
        name="EditSurveyButtons"
        component={EditSurveysButtonsScreen}
      />
      <Stack.Screen
        name="EditReferralButtons"
        component={EditReferralButtonsScreen}
      />
      <Stack.Screen
        name="EditDescriptionButtons"
        component={EditDescriptionButtonsScreen}
      />
    </Stack.Navigator>
  );
}
