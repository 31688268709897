/*
  eslint-disable @typescript-eslint/strict-boolean-expressions
*/
import React, { createContext, useState, useEffect } from 'react';
import { loadAsync as loadFontAsync } from 'expo-font';
import * as GoogleFonts from '@expo-google-fonts/dev';

import { DataLoadStatus } from '../../store/types';
import {
  ProfileDetails,
  EditableProfileDetails,
  EditButton,
  ButtonType,
  Whitelabel,
  Policy,
  DescriptionDetails,
} from '../../store/effects/api/types';
import { useEffects, useStore } from '../../store';

import { ThemeProvider } from '../../theming/index';
import { nonGoogleFonts, Theme, useTheme } from '../../theming';

type ContextData = {
  data: null | ProfileDetails;
  isUserProfile: boolean;
  status: DataLoadStatus;
  updateProfileData: (
    updatedProfileData: EditableProfileDetails,
  ) => Promise<boolean>;
  updateProfilePhoto: (photoUri: string) => Promise<boolean>;
  updateProfileVideo: (videoLink: string) => Promise<boolean>;
  updateProfileVideoLink: (videoLink: string) => Promise<boolean>;
  updateProfileTopBanner: (topBannerUri: string) => Promise<boolean>;
  saveProfile: () => Promise<boolean>;
  saveProfileButtons: (
    buttonsData: EditButton[],
    buttonsType: NonNullable<ButtonType['data_type']>,
  ) => Promise<boolean>;
  saveDescriptionButton: (data: DescriptionDetails) => Promise<boolean>;
  generateShareLink: () => Promise<string | null>;
  getProfileQRCodeUrl: () => Promise<{ QRCodeUrl: string } | undefined>;
  hasPolicy: (policy: Policy) => boolean;
};

const forceHTTPS = (url: string) => {
  return url.replace('http://', 'https://');
};

export const ProfileData = createContext<ContextData>({
  data: null,
  status: DataLoadStatus.UNKNOWN,
  isUserProfile: false,
  updateProfileData: () => Promise.resolve(false),
  updateProfilePhoto: () => Promise.resolve(false),
  updateProfileVideo: () => Promise.resolve(false),
  updateProfileVideoLink: () => Promise.resolve(false),
  updateProfileTopBanner: () => Promise.resolve(false),
  saveProfile: () => Promise.resolve(false),
  saveProfileButtons: () => Promise.resolve(false),
  generateShareLink: () => Promise.resolve(null),
  getProfileQRCodeUrl: () => Promise.resolve(undefined),
  hasPolicy: (_: string) => false,
  saveDescriptionButton: () => Promise.resolve(false),
});

function Provider({
  profileID,
  children,
}: {
  profileID: number;
  children: React.ReactNode;
}) {
  const {
    user: { token },
  } = useStore();
  const theme = useTheme();
  const { api } = useEffects();
  const [fetchState, setFetchState] = useState<DataLoadStatus>(
    DataLoadStatus.UNKNOWN,
  );
  const [profileWhitelabelInfo, setProfileWhitelabelInfo] =
    useState<Whitelabel | null>(null);
  const [isUserProfile, setIsUserProfile] = useState(false);
  const [profileData, setProfileData] = useState<ProfileDetails | null>(null);
  const [profilePoliciesData, setProfilePoliciesData] = useState<Policy[]>([]);
  const [customFontFamilies, setCustomFontFamilies] = useState<
    Theme['fonts'] | null
  >(null);
  async function saveProfile() {
    const result = await api.saveProfile(profileID);
    return result.success === true;
  }

  async function saveProfileButtons(
    buttonsData: EditButton[],
    buttonsType: NonNullable<ButtonType['data_type']>,
  ) {
    if (buttonsData.length > 0) {
      const saveResult = await api.saveProfileButtons(
        profileID,
        buttonsData,
        buttonsType,
      );

      if (saveResult.success === true) {
        const result = await api.getProfileDetails(profileID);
        if (result.success) {
          const updatedData = result.success.data;
          setProfileData(updatedData);
          return true;
        }
      }
      return false;
    }
    return true;
  }

  async function saveDescriptionButton(data: DescriptionDetails) {
    if (data !== null) {
      const saveResult = await api.saveDescription(data, profileID);

      if (saveResult.success === true) {
        const result = await api.getProfileDetails(profileID);
        if (result.success) {
          const updatedData = result.success.data;
          setProfileData(updatedData);
          return true;
        }
      }
      return false;
    }
    return false;
  }

  async function updateProfileData(updatedProfileData: EditableProfileDetails) {
    if (profileData !== null) {
      const result = await api.updateProfileDetails(
        profileID,
        updatedProfileData,
      );
      if (result.success === true) {
        setProfileData((oldProfileData) => {
          return { ...oldProfileData, ...updatedProfileData } as ProfileDetails;
        });
        return true;
      }
    }
    return false;
  }

  async function updateProfilePhoto(photoUri: string) {
    if (profileData !== null) {
      const updateResult = await api.updateProfilePhoto(profileID, photoUri);
      if (updateResult.success === true) {
        const response = await api.getProfileDetails(profileID);
        if (response.success) {
          const { data: updatedData } = response.success;
          setProfileData(updatedData);
          return true;
        }
      }
    }
    return false;
  }

  async function updateProfileTopBanner(topBannerUri: string) {
    if (profileData !== null) {
      const updateResult = await api.updateProfileTopBanner(
        profileID,
        topBannerUri,
      );
      if (updateResult.success === true) {
        const response = await api.getProfileDetails(profileID);
        if (response.success) {
          const { data: updatedData } = response.success;
          setProfileData(updatedData);
          return true;
        }
      }
    }
    return false;
  }

  async function getProfileQRCodeUrl() {
    const response = await api.getProfileBarcode(profileID);
    if (response.success) {
      return {
        QRCodeUrl: response.success.data.barcode_url,
      };
    }
    return undefined;
  }

  async function generateShareLink() {
    const isUserLoggedIn = token !== null;
    const result = isUserLoggedIn
      ? await api.generateShareLinkForLoggedInUser(profileID)
      : await api.generateShareLinkForUnloggedUser(profileID);
    if (result.success) {
      return result.success.data.url_to_share;
    }
    return null;
  }

  async function updateProfileVideo(videoUri: string) {
    if (profileData !== null) {
      const updateResult = await api.updateProfileVideo(profileID, videoUri);
      if (updateResult.success === true) {
        const response = await api.getProfileDetails(profileID);
        if (response.success) {
          const { data: updatedData } = response.success;
          setProfileData(updatedData);
          return true;
        }
      }
    }
    return false;
  }

  async function updateProfileVideoLink(videoUri: string) {
    if (profileData !== null) {
      const updateResult = await api.updateProfileVideoLink(
        profileID,
        videoUri,
      );
      if (updateResult.success === true) {
        const response = await api.getProfileDetails(profileID);
        if (response.success) {
          const { data: updatedData } = response.success;
          setProfileData(updatedData);
          return true;
        }
      }
    }
    return false;
  }

  function hasPolicy(policy: Policy) {
    return profilePoliciesData.includes(policy);
  }

  useEffect(() => {
    (async () => {
      setFetchState(DataLoadStatus.LOADING);
      let isUserProfile;
      try {
        if (token !== null) {
          const profilesResponse = await api.getProfilesList();
          if (profilesResponse.success) {
            isUserProfile =
              profilesResponse.success.data.find(
                (profile) => profile.id === profileID,
              ) !== undefined;
            setIsUserProfile(isUserProfile);
          } else {
            setIsUserProfile(false);
          }
        }

        const profileBrandingResponse = await api.getProfileBranding(profileID);
        let profileWhitelabelInfoData;
        if (profileBrandingResponse.success) {
          profileWhitelabelInfoData = profileBrandingResponse.success.data;
        }
        const response = await api.getProfileDetails(profileID);
        if (response.success) {
          const { data } = response.success;
          setProfileData(data);
          if (token && isUserProfile) {
            const policiesResponse = await api.getProfilePolicies(data.id);
            if (policiesResponse.success) {
              setProfilePoliciesData(policiesResponse.success.data);
            }
          }
          if (data.show_social_media_links) {
            setProfilePoliciesData(
              (prevPolicies) =>
                [
                  ...new Set([...prevPolicies, 'show_social_media_links']),
                ] as Policy[],
            );
          }
          if (!nonGoogleFonts.includes(data.font)) {
            const font = data.font as string;
            try {
              const trimmedFontName = font.replace(/\s/g, '');
              const regular = {
                link:
                  // @ts-ignore
                  GoogleFonts[trimmedFontName + '_400Regular'] ||
                  // @ts-ignore
                  GoogleFonts['Roboto_400Regular'],
                family: `${data.font} Regular`,
              };
              const medium = {
                link:
                  // @ts-ignore
                  GoogleFonts[trimmedFontName + '_500Medium'] ||
                  // @ts-ignore
                  GoogleFonts[trimmedFontName + '_400Regular'] ||
                  GoogleFonts['Roboto_500Medium'],
                family: `${data.font} Medium`,
              };
              await loadFontAsync({
                [regular.family]: forceHTTPS(regular.link),
                [medium.family]: forceHTTPS(medium.link),
              });

              setCustomFontFamilies({
                regular: {
                  fontFamily: regular.family,
                  fontWeight: '400',
                },
                medium: {
                  fontFamily: medium.family,
                  fontWeight: '500',
                },
              });
            } catch (e) {
              // let it fail silently
              console.warn('Failed to load custom font family', e);
            }
          }
          if (profileWhitelabelInfoData?.template_white_label !== undefined) {
            setProfileWhitelabelInfo(
              profileWhitelabelInfoData.template_white_label,
            );
          }
          setFetchState(DataLoadStatus.SUCCESS);
        } else {
          setFetchState(DataLoadStatus.ERROR);
        }
      } catch (e) {
        setFetchState(DataLoadStatus.ERROR);
      }
    })();
  }, [api, profileID, token]);

  const themeWithWhitelabelColors = {
    ...theme,
    colors: {
      ...theme.colors,
      primary:
        profileWhitelabelInfo?.white_label_primary_color ??
        theme.colors.primary,
    },
  };
  return (
    <ProfileData.Provider
      value={{
        isUserProfile,
        data: profileData,
        status: fetchState,
        updateProfileData,
        updateProfilePhoto,
        updateProfileVideo,
        updateProfileVideoLink,
        updateProfileTopBanner,
        saveProfile,
        generateShareLink,
        saveProfileButtons,
        saveDescriptionButton,
        getProfileQRCodeUrl,
        hasPolicy,
      }}
    >
      <ThemeProvider
        theme={{
          ...themeWithWhitelabelColors,
          ...(customFontFamilies !== null
            ? {
                fonts: customFontFamilies,
              }
            : {}),
        }}
      >
        {children}
      </ThemeProvider>
    </ProfileData.Provider>
  );
}

export default {
  Context: ProfileData,
  Provider: Provider,
};
