import React, { ComponentProps, ComponentType } from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import MaterialCommunityIconsMap from '@expo/vector-icons/build/vendor/react-native-vector-icons/glyphmaps/MaterialCommunityIcons.json';

export type IconName = keyof typeof MaterialCommunityIconsMap;

const Icon = MaterialCommunityIcons as ComponentType<
  React.ComponentProps<typeof MaterialCommunityIcons> & { name: IconName }
>;

export default Icon;
