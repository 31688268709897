import React from 'react';
import { StyleSheet } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';

import {
  DBCNavigatorParams,
  DBCNavigatorWebParams,
  NavigationProp,
} from '../index';
import QrCodeScreen from '../QRCodeScreen';

import InfoPageScreen from './InfoPageScreen';
import SubPageScreen from './SubpageScreen';
import SurveyScreen from './SurveyScreen';
import ContactPageScreen from './ContactPageScreen';
import DrawerContent from './DrawerContent';
import PhoneScreen from './PhoneScreen';
import GalleryScreen from './GalleryScreen';
import EmailScreen from './EmailScreen';
import WebsiteScreen from './WebsiteScreen';
import PDFScreen from './PDFScreen';
import DBCHomeScreen from './DBCHomeScreen';
import DateScreen from './DateScreen';
import DescriptionScreen from './DescriptionScreen';

const Drawer = createDrawerNavigator<
  DBCNavigatorParams & DBCNavigatorWebParams
>();
type Props = { navigation: NavigationProp<'DrawerHome'> };

export default function WebDrawerNavigator({
  navigation: stackNavigation,
}: Props) {
  return (
    <Drawer.Navigator
      drawerStyle={styles.drawerStyle}
      drawerType="permanent"
      drawerContent={(props) => (
        <DrawerContent {...props} stackNavigation={stackNavigation} />
      )}
    >
      <Drawer.Screen name="DBC-Home" component={DBCHomeScreen} />
      <Drawer.Screen name="DBC-Gallery" component={GalleryScreen} />
      <Drawer.Screen name="DBC-InfoPage" component={InfoPageScreen} />
      <Drawer.Screen name="DBC-SubPage" component={SubPageScreen} />
      <Drawer.Screen name="DBC-Email" component={EmailScreen} />
      <Drawer.Screen name="DBC-Phone" component={PhoneScreen} />
      <Drawer.Screen name="DBC-Website" component={WebsiteScreen} />
      <Drawer.Screen name="DBC-Survey" component={SurveyScreen} />
      <Drawer.Screen name="DBC-ContactPage" component={ContactPageScreen} />
      <Drawer.Screen name="DBC-PDF" component={PDFScreen} />
      <Drawer.Screen name="DBC-Date" component={DateScreen} />
      <Drawer.Screen name="DBC-QRCode" component={QrCodeScreen} />
      <Drawer.Screen name="DBC-Description" component={DescriptionScreen} />
    </Drawer.Navigator>
  );
}

const styles = StyleSheet.create({
  drawerStyle: {
    borderRightWidth: 0,
  },
});
