import React from 'react';
import { StyleSheet, View } from 'react-native';

import Graphics from '../../../../assets/undraw_online_calendar_kvu2.svg';
import { useTheme } from '../../../theming';
import Button from '../../../components/Button';
import { openLink } from '../../../utils/buttonActions';
import { useTranslation } from '../../../translations';
import { WebRouteProp } from '../index';
import { ButtonType } from '../../../store/effects/api/types';
import DataLoaderWrapper from './DataLoaderWrapper';

type Props = {
  route: WebRouteProp<'DBC-Date'>;
};

export default function DateScreen({ route }: Props) {
  const { colors } = useTheme();
  const texts = useTranslation('errors', 'webNavigation');
  const buttonIndex = route.params?.buttonIndex;

  const handleGoogleCalendar = (dateButton: ButtonType) => {
    const title = dateButton.button_event?.summary;
    const description = dateButton.button_event?.description;
    const start_date = dateButton.button_event?.start_date;
    const end_date = dateButton.button_event?.end_date;

    openLink(
      `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${description}&dates=${start_date}/${end_date}`,
    );
  };

  return (
    <DataLoaderWrapper buttonIndex={buttonIndex}>
      {(dateButton) => (
        <View style={[styles.wrapper, { backgroundColor: colors.darkGrey }]}>
          <View style={styles.graphicsWrapper}>
            <Graphics width={600} height={400} />
          </View>
          <View style={styles.buttonWrapper}>
            <Button
              mode="contained"
              onPress={() => {
                openLink(dateButton.button_event?.ical_file_url as string);
              }}
              contentStyle={styles.button}
              labelStyle={styles.PDFText}
            >
              {texts.webNavigation.iCal}
            </Button>
            <View style={styles.delimiter} />
            <Button
              mode="contained"
              onPress={() => handleGoogleCalendar(dateButton)}
              contentStyle={styles.button}
              labelStyle={styles.PDFText}
            >
              {texts.webNavigation.googleCalendar}
            </Button>
          </View>
        </View>
      )}
    </DataLoaderWrapper>
  );
}

const styles = StyleSheet.create({
  graphicsWrapper: {
    alignItems: 'center',
  },
  PDFText: {
    fontSize: 32,
  },
  wrapper: {
    padding: 40,
  },
  buttonWrapper: {
    paddingTop: 20,
  },
  button: {
    paddingVertical: 8,
  },
  delimiter: {
    height: 10,
  },
});
