import AsyncStorage from '@react-native-community/async-storage';
import { debounce } from 'debounce';
import { State } from '../index';

const storageKey = 'TAG_data';

const debouncedStorageSetItem = debounce(AsyncStorage.setItem, 500);

const persistGate = {
  async get(): Promise<State | undefined> {
    const data = await AsyncStorage.getItem(storageKey);
    if (data !== null) {
      return JSON.parse(data);
    }
    return undefined;
  },
  async set(state: State) {
    debouncedStorageSetItem(storageKey, JSON.stringify(state));
  },
};

export default persistGate;
