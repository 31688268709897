import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTheme } from '../../../theming';

type Props = {
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
};

const BORDER_WIDTH = 5;

const getCornerStylesFromPosition = (position: Props['position']) => {
  switch (position) {
    case 'top-left':
      return {
        borderTopWidth: BORDER_WIDTH,
        borderLeftWidth: BORDER_WIDTH,
        top: 0,
        left: 0,
      };
    case 'top-right': {
      return {
        borderTopWidth: BORDER_WIDTH,
        borderRightWidth: BORDER_WIDTH,
        top: 0,
        right: 0,
      };
    }
    case 'bottom-left': {
      return {
        borderLeftWidth: BORDER_WIDTH,
        borderBottomWidth: BORDER_WIDTH,
        bottom: 0,
        left: 0,
      };
    }
    case 'bottom-right': {
      return {
        borderBottomWidth: BORDER_WIDTH,
        borderRightWidth: BORDER_WIDTH,
        bottom: 0,
        right: 0,
      };
    }
  }
};

export function Corner({ position }: Props) {
  const { colors } = useTheme();
  const cornerStylesFromPosition = getCornerStylesFromPosition(position);
  return (
    <View
      style={[
        styles.corner,
        cornerStylesFromPosition,
        { borderColor: colors.primary },
      ]}
    />
  );
}

const styles = StyleSheet.create({
  corner: {
    position: 'absolute',
    width: '20%',
    height: '20%',
  },
});
