import { IConfig, createOvermind } from 'overmind';
import {
  createHook,
  createStateHook,
  createActionsHook,
  createEffectsHook,
  createReactionHook,
} from 'overmind-react';
import { namespaced, merge } from 'overmind/config';

import * as user from './user';
import * as profiles from './profiles';
import * as templates from './templates';
import * as onboarding from './onboarding';
import * as whitelabel from './whitelabel';

import * as effects from './effects';
import { onInitialize } from './onInitialize';

export const config = merge(
  {
    effects,
    onInitialize,
  },
  namespaced({
    whitelabel,
    onboarding,
    user,
    profiles,
    templates,
  }),
);

declare module 'overmind' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Config extends IConfig<typeof config> {}
}

export type State = IConfig<typeof config>['state'];

export const useOvermind = createHook<typeof config>();
export const useStore = createStateHook<typeof config>();
export const useActions = createActionsHook<typeof config>();
export const useEffects = createEffectsHook<typeof config>();
export const useReaction = createReactionHook<typeof config>();

export const overmind = createOvermind(config);
