import { Action } from 'overmind';

import { DataLoadStatus } from '../types';

export const getMyProfilesList: Action<void, Promise<void>> = async ({
  state,
  effects,
  actions,
}) => {
  const {
    profiles: { profilesList },
  } = state;
  profilesList.status = DataLoadStatus.LOADING;
  const profilesListResult = await effects.api.getProfilesList();
  if (profilesListResult.success) {
    profilesList.data = profilesListResult.success.data;
    profilesList.status = DataLoadStatus.SUCCESS;
    actions.profiles.setMainProfile();
    actions.profiles.moveFavouriteProfileToTheTop();
  } else {
    profilesList.status = DataLoadStatus.ERROR;
  }
};

export const getSavedProfilesList: Action<void, Promise<void>> = async ({
  state,
  effects,
}) => {
  const {
    profiles: { savedProfilesList },
  } = state;
  savedProfilesList.status = DataLoadStatus.LOADING;
  const savedProfilesListResult = await effects.api.getSavedProfilesList();
  if (savedProfilesListResult.success) {
    savedProfilesList.data = savedProfilesListResult.success.data.profiles;
    savedProfilesList.status = DataLoadStatus.SUCCESS;
  } else {
    savedProfilesList.status = DataLoadStatus.ERROR;
  }
};

export const getScannedProfilesList: Action<void, Promise<void>> = async ({
  state,
  effects,
}) => {
  const {
    profiles: { scannedProfilesList },
  } = state;
  scannedProfilesList.status = DataLoadStatus.LOADING;
  const scannedProfilesListResult = await effects.api.getScannedProfilesList();
  if (scannedProfilesListResult.success) {
    scannedProfilesList.data = scannedProfilesListResult.success.data;
    scannedProfilesList.status = DataLoadStatus.SUCCESS;
  } else {
    scannedProfilesList.status = DataLoadStatus.ERROR;
  }
};

export const setMainProfile: Action<void, void> = ({ state }) => {
  const { user, profiles } = state;
  if (user.meta !== null) {
    if (user.meta.favorite_profile_id !== null) {
      profiles.mainProfileID = user.meta.favorite_profile_id;
    } else {
      profiles.mainProfileID = profiles.profilesList.data[0].id;
    }
  }
};

export const markAsFavourite: Action<number, Promise<boolean>> = async (
  { state, actions, effects },
  profileID: number,
): Promise<boolean> => {
  const markResult = await effects.api.markProfileAsFavourite(profileID);
  if (markResult.success === true) {
    const { user } = state;
    if (user.meta !== null) {
      user.meta.favorite_profile_id = profileID;
      actions.profiles.setMainProfile();
      return true;
    }
  }
  return false;
};

export const fetchAndSetProfileBranding: Action<
  number,
  Promise<boolean>
> = async ({ actions, effects }, profileID: number): Promise<boolean> => {
  const result = await effects.api.getProfileBranding(profileID);
  if (result.success) {
    const brandingData = result.success.data;
    actions.whitelabel.setWhitelabel(brandingData.template_white_label);
    return true;
  }
  actions.whitelabel.reset();
  return false;
};

export const moveFavouriteProfileToTheTop: Action<void, void> = ({ state }) => {
  const {
    profiles: { mainProfileID, profilesList },
  } = state;
  if (mainProfileID !== null) {
    const favouriteProfileIndex = profilesList.data.findIndex(
      (profile) => profile.id === mainProfileID,
    );
    if (favouriteProfileIndex !== -1) {
      state.profiles.profilesList.data = [
        profilesList.data[favouriteProfileIndex],
        ...profilesList.data
          .slice(0, favouriteProfileIndex)
          .concat(profilesList.data.slice(favouriteProfileIndex + 1)),
      ];
    }
  }
};

export const loginAndCreateProfile: Action<
  { email: string; password: string; invitationHash: string },
  Promise<boolean>
> = async (
  { state, effects, actions },
  { email, password, invitationHash },
) => {
  const loginResult = await effects.api.getToken(email, password);
  if (loginResult.success) {
    state.user.token = loginResult.success.token;
    return await actions.profiles.createProfileLoggedInUser({ invitationHash });
  }
  return false;
};

export const createProfileLoggedInUser: Action<
  { invitationHash: string },
  Promise<boolean>
> = async ({ effects, actions }, { invitationHash }) => {
  const createProfileResult = await effects.api.createProfile(invitationHash);
  if (createProfileResult.success === true) {
    const result = await actions.user.fetchAndSetUserAccountData();
    return result === true;
  }
  return false;
};
