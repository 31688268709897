import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import { NavigationProp } from '../MainNavigator';
import ScreenWrapper from '../components/ScreenWrapper';

type Props = {
  navigation: NavigationProp<'NotFound'>;
};

export default function NotFoundScreen({ navigation }: Props) {
  useEffect(() => {
    navigation.navigate('Welcome');
  });

  return (
    <ScreenWrapper style={styles.container}>
      <ActivityIndicator />
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
