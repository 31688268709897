import React, { useState, useMemo, useLayoutEffect, useRef } from 'react';
import {
  StyleSheet,
  View,
  TextInput as RNTextInput,
  ScrollView,
  Platform,
} from 'react-native';
import { Text, Modal, TextInput } from 'react-native-paper';
import _ from 'lodash';

import ScreenWrapper from '../../components/ScreenWrapper';
import { NavigationProp } from '../../MainNavigator';
import IconButton from '../../components/IconButton';
import Button from '../../components/Button';
import { useTheme } from '../../theming';
import useAppSize from '../../hooks/useAppSize';

import { useTranslation } from '../../translations';
import FoldersNavigator from './FoldersNavigator';
import { api } from './../../store/effects';
import FolderItem from './FolderItem';

type Props = {
  navigation: NavigationProp<'SavedProfiles'>;
};

export default function SavedProfilesList({ navigation }: Props) {
  const { colors } = useTheme();
  const appSize = useAppSize();

  const newFolderInput = useRef<RNTextInput | null>(null);
  const [isAddNewFolderModalVisible, setIsAddNewFolderModalVisible] =
    useState(false);
  const [isMoveToFolderModalVisible, setIsMoveToFolderModalVisible] =
    useState(false);
  const [isDeleteFolderModalVisible, setIsDeleteFolderModalVisible] =
    useState(false);
  const [folderName, setFolderName] = useState('Folder without a name');
  const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

  const isModalsVisible =
    isAddNewFolderModalVisible ||
    isMoveToFolderModalVisible ||
    isDeleteFolderModalVisible;

  const [currentFolderInMainNavigator, setCurrentFolderInMainNavigator] =
    useState<string>();
  const [currentFolderInModalNavigator, setCurrentFolderInModalNavigator] =
    useState<string>();

  const texts = useTranslation('savedProfiles');

  const handleOnAddNewFolderPress = () => {
    setIsAddNewFolderModalVisible(true);
  };

  const handleOnMoveToFolderPress = () => {
    setIsMoveToFolderModalVisible(true);
  };
  const handleUnselectAll = () => {
    setSelectedProfiles([]);
  };

  const handleRequestDeleteFolder = async () => {
    setIsDeleteFolderModalVisible(true);
  };

  const handleOnFolderOptionsPress = (id?: string): void => {
    if (typeof id !== 'undefined') {
      setSelectedFolderId(id);
    }
  };

  const hideModals = () => {
    setIsAddNewFolderModalVisible(false);
    setIsMoveToFolderModalVisible(false);
    setIsDeleteFolderModalVisible(false);
    setSelectedFolderId(null);
  };

  useLayoutEffect(() => {
    if (selectedProfiles.length > 0) {
      navigation.setOptions({
        headerTitleAlign: 'left',
        headerTitle: `Selected (${selectedProfiles.length})`,
        headerRight: () => (
          <>
            <IconButton
              size={28}
              onPress={handleUnselectAll}
              icon="close-box"
            />
            <IconButton
              size={28}
              onPress={handleOnMoveToFolderPress}
              icon="folder-move"
            />
            <IconButton
              size={28}
              onPress={handleOnAddNewFolderPress}
              icon="folder-plus"
            />
          </>
        ),
      });
    } else {
      navigation.setOptions({
        headerTitle: 'Saved profiles',
        headerRight: () => (
          <IconButton
            size={28}
            onPress={handleOnAddNewFolderPress}
            icon="folder-plus"
          />
        ),
      });
    }
  }, [selectedProfiles.length]);

  const modalWidth = appSize.width * 0.9;
  const narrowModalWidth =
    Platform.OS === 'web' ? appSize.width * 0.6 : appSize.width * 0.9;

  const handleCreate = () => {
    api
      .createSavedProfilesFolder(folderName, currentFolderInMainNavigator)
      .then(() => {
        hideModals();
        setSelectedProfiles([]);
      });
  };

  const handleDeleteFolder = () => {
    if (selectedFolderId !== null) {
      api.deleteSavedProfilesFolder(selectedFolderId).then(() => {
        hideModals();
      });
    }
  };

  const handlePutHere = () => {
    const promises = [];
    for (const selectedProfile of selectedProfiles) {
      promises.push(
        api.moveProfileToFolder(selectedProfile, currentFolderInModalNavigator),
      );
    }
    Promise.all(promises).then(() => {
      hideModals();
      setSelectedProfiles([]);
    });
  };

  return (
    <ScreenWrapper noPadding style={styles.container}>
      <>
        <ScreenWrapper.Content style={styles.content}>
          {useMemo(
            () => (
              <FoldersNavigator
                isModalsVisible={isModalsVisible}
                onCurrentFolderChange={(id) => {
                  setCurrentFolderInMainNavigator(id);
                }}
                onFolderOptions={handleOnFolderOptionsPress}
                onSelect={(id) => {
                  setSelectedProfiles(_.xor(selectedProfiles, [id]));
                }}
                selected={selectedProfiles}
              />
            ),
            [isModalsVisible, selectedProfiles],
          )}
        </ScreenWrapper.Content>
        <Modal
          contentContainerStyle={styles.modalContainer}
          visible={isAddNewFolderModalVisible}
          onDismiss={hideModals}
        >
          <View
            style={[
              styles.modalWrapper,
              {
                width: modalWidth,
                backgroundColor: colors.darkGrey,
              },
            ]}
          >
            <Text style={styles.modalTitle}>
              {texts.savedProfiles.newFolder}
            </Text>
            <TextInput
              selectTextOnFocus
              onLayout={() => {
                newFolderInput.current?.focus();
              }}
              ref={newFolderInput}
              placeholder="folder name"
              mode="flat"
              onChangeText={setFolderName}
              value={folderName}
            />
            <View style={styles.modalActions}>
              <Button onPress={hideModals}>Cancel</Button>
              <Button onPress={handleCreate}>Create</Button>
            </View>
          </View>
        </Modal>
        <Modal
          contentContainerStyle={styles.commonModalContainer}
          visible={isMoveToFolderModalVisible}
          onDismiss={hideModals}
        >
          <>
            <View
              style={[
                styles.modalWrapper,
                {
                  width: modalWidth,
                  backgroundColor: colors.darkGrey,
                },
              ]}
            >
              <Text style={styles.modalTitle}>Move to folder</Text>
              <ScrollView>
                <FoldersNavigator
                  onCurrentFolderChange={(id) => {
                    setCurrentFolderInModalNavigator(id);
                  }}
                  previewMode
                />
              </ScrollView>
              <View style={styles.modalActions}>
                <Button onPress={hideModals}>Cancel</Button>
                <Button onPress={handlePutHere}>Put here</Button>
              </View>
            </View>
          </>
        </Modal>
        <Modal
          contentContainerStyle={styles.folderOptionsModalContainer}
          visible={selectedFolderId !== null}
          onDismiss={hideModals}
        >
          <>
            <View
              style={[
                styles.modalWrapper,
                {
                  width: modalWidth,
                  backgroundColor: colors.darkGrey,
                },
              ]}
            >
              <Text style={styles.modalTitle}>Folder options</Text>
              <ScrollView>
                <FolderItem
                  customIcon="delete"
                  folderName="Delete folder"
                  onPress={handleRequestDeleteFolder}
                />
              </ScrollView>
              <View style={styles.modalActions}>
                <Button onPress={hideModals}>Cancel</Button>
              </View>
            </View>
          </>
        </Modal>
        <Modal
          contentContainerStyle={styles.deleteModalContainer}
          visible={isDeleteFolderModalVisible}
          onDismiss={hideModals}
        >
          <>
            <View
              style={[
                styles.modalWrapper,
                {
                  width: narrowModalWidth,
                  backgroundColor: colors.darkGrey,
                },
              ]}
            >
              <Text style={styles.modalTitle}>
                {texts.savedProfiles.deleteFolder}
              </Text>
              <Text>{texts.savedProfiles.areYouSure}</Text>
              <Text>{texts.savedProfiles.actionUndone}</Text>
              <View style={styles.modalActions}>
                <Button onPress={hideModals}>
                  {texts.savedProfiles.noCancel}
                </Button>
                <Button onPress={handleDeleteFolder}>
                  {texts.savedProfiles.yesDelete}
                </Button>
              </View>
            </View>
          </>
        </Modal>
      </>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    paddingHorizontal: 0,
  },
  commonModalContainer: { alignItems: 'center' },
  folderOptionsModalContainer: { alignItems: 'center', height: 200 },
  deleteModalContainer: {
    alignItems: 'center',
    height: 170,
  },
  modalActions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 24,
  },
  modalTitle: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 24,
  },
  modalWrapper: {
    padding: 16,
    borderRadius: 10,
    height: '96%',
  },
  modalContainer: {
    alignItems: 'center',
  },
});
