import React, { useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Text } from 'react-native-paper';

import Graphics from '../../../assets/undraw_change_user_info.svg';
import { useTranslation } from '../../translations';
import { stdAlert } from '../../components/alert';
import { UserAccountGeneral } from '../../store/effects/api/types';
import { useStore, useActions } from '../../store';
import UserInfoForm from './UserInfoForm';

export default function UserInfoScreen() {
  const texts = useTranslation('edit', 'errors');
  const { user } = useActions();
  const {
    user: { meta },
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (userData: UserAccountGeneral) => {
    setIsLoading(true);
    const result = await user.updateUserGeneralData({
      userGeneralData: userData,
    });
    setIsLoading(false);
    if (result === true) {
      stdAlert(texts.edit.updateSuccess);
    }
    if (result === false) {
      stdAlert(texts.errors.unexpected);
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.instructionWrapper}>
          <Text>{texts.edit.editGeneralInfo}</Text>
        </View>
        <View style={styles.graphicsWrapper}>
          <Graphics width={200} height={150} />
        </View>
        <UserInfoForm
          isProcessing={isLoading}
          onSubmit={handleSubmit}
          initialUserData={meta as UserAccountGeneral}
        />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  instructionWrapper: {
    alignItems: 'center',
    paddingVertical: 24,
  },
  graphicsWrapper: {
    alignItems: 'center',
  },
  container: {
    flex: 1,
  },
});
