import React from 'react';
import { View } from 'react-native';

import { useTheme } from '../../../theming';

import { RouteProp } from '../index';
import Gallery from '../GalleryScreen/Gallery';

import DataLoaderWrapper from './DataLoaderWrapper';

type Props = {
  route: RouteProp<'DBC-Gallery'>;
};

export default function GalleryScreen({ route }: Props) {
  const { spacings } = useTheme();
  const buttonIndex = route.params?.buttonIndex;

  return (
    <DataLoaderWrapper buttonIndex={buttonIndex}>
      {(galleryButton) => (
        <View style={{ paddingHorizontal: spacings.content.horizontal }}>
          <Gallery images={galleryButton.gallery_images} />
        </View>
      )}
    </DataLoaderWrapper>
  );
}
