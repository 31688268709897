import React, { useContext, useState, useEffect } from 'react';
import {
  StyleSheet,
  ScrollView,
  Platform,
  Dimensions,
  Image,
  Share,
} from 'react-native';
import { Text, ActivityIndicator, FAB } from 'react-native-paper';

import ScreenWrapper from '../../../components/ScreenWrapper';
import { DataLoadStatus } from '../../../store/types';
import { useEffects, useStore } from '../../../store';
import { useCompanyPositionLabel } from '../../../hooks/useCompanyPositionLabel';

import { useTheme } from '../../../theming';
import { useTranslation } from '../../../translations';

import UserMedia from '../../../components/UserMedia';
import { stdAlert } from '../../../components/alert';

import DataContext from '../../Profile/DataContext';

import { NavigationProp } from '../index';
import Header from '../Header';

type Props = {
  navigation: NavigationProp<'DBC-Home'>;
};

export default function DBCHome({ navigation }: Props) {
  const theme = useTheme();
  const {
    user: { token },
  } = useStore();
  const { api } = useEffects();
  const { colors } = theme;
  const texts = useTranslation('errors', 'common', 'digitalBusinessCard');
  const [isUserAlreadySavedProfile, setIsUserAlreadySavedProfile] = useState<
    boolean | null
  >(null);
  const [isOpen, setIsOpen] = useState(false);
  const { status, data, saveProfile, isUserProfile, generateShareLink } =
    useContext(DataContext.Context);
  const companyLabel = useCompanyPositionLabel({
    company: data?.company,
    profileRole: data?.profile_role,
  });

  useEffect(() => {
    (async () => {
      const isUserLoggedIn = token !== null;
      if (
        isUserLoggedIn &&
        status === DataLoadStatus.SUCCESS &&
        data !== null
      ) {
        const response = await api.getSavedProfilesList();
        if (response.success) {
          const { data: savedProfiles } = response.success;
          setIsUserAlreadySavedProfile(
            savedProfiles.profiles.find(
              (savedProfile) => savedProfile.id === data.id,
            ) !== undefined,
          );
        }
      } else {
        setIsUserAlreadySavedProfile(false);
      }
    })();
  }, [status, data, token, api]);

  const handleFABPress = ({ open }: { open: boolean }) => {
    setIsOpen(open);
  };

  const fabStyles = { backgroundColor: colors.primary };

  const handleQRCodeActionPress = () => {
    navigation.navigate('DBC-QRCode');
  };
  const handleSaveProfilePress = async () => {
    const saveResult = await saveProfile();
    if (saveResult === true) {
      stdAlert(texts.digitalBusinessCard.saveSuccess);
    } else {
      stdAlert(texts.errors.unexpected);
    }
  };

  const handleShareProfile = async () => {
    const shareLink = await generateShareLink();
    if (shareLink !== null) {
      Share.share({ message: shareLink });
    }
  };

  const handleScanActionPress = () => {
    navigation.navigate('QRScanner');
  };

  const fabActions = [
    {
      style: fabStyles,
      icon: 'qrcode',
      color: colors.black,
      label: texts.digitalBusinessCard.QRCode,
      onPress: handleQRCodeActionPress,
    },
    {
      style: fabStyles,
      color: colors.black,
      icon: 'share',
      label: texts.digitalBusinessCard.share,
      onPress: handleShareProfile,
    },
  ];

  if (isUserProfile === true) {
    fabActions.push({
      style: fabStyles,
      color: colors.black,
      icon: 'qrcode-scan',
      label: texts.digitalBusinessCard.scan,
      onPress: handleScanActionPress,
    });
  } else {
    if (isUserAlreadySavedProfile === false) {
      fabActions.push({
        style: fabStyles,
        color: colors.black,
        icon: 'content-save',
        label: texts.digitalBusinessCard.save,
        onPress: handleSaveProfilePress,
      });
    }
  }

  return (
    <ScreenWrapper style={styles.container} noPadding={true}>
      {status === DataLoadStatus.SUCCESS &&
      data !== null &&
      isUserAlreadySavedProfile !== null ? (
        <>
          {data.main_bg_image_url !== null ? (
            <Image
              source={{
                uri: data.main_bg_image_url,
              }}
              accessibilityIgnoresInvertColors
              style={styles.backgroundImage}
            />
          ) : null}
          <ScrollView
            style={styles.fullWidth}
            contentContainerStyle={styles.center}
          >
            <Header background={data.title_bg_image_url}>{data.title}</Header>
            <ScreenWrapper.Content style={[styles.fullWidth, styles.center]}>
              <UserMedia
                video={data.video}
                videoClip={data.video_clip_url}
                pictureUrl={data.picture_url}
                pictureOrVideo={data.picture_or_video}
                style={styles.userMedia}
              />
              {companyLabel !== null ? (
                <Text style={styles.companyRole}>{companyLabel}</Text>
              ) : null}
            </ScreenWrapper.Content>
          </ScrollView>
          <FAB.Group
            color={colors.black}
            theme={{ colors: { text: colors.black } }}
            fabStyle={fabStyles}
            visible
            style={styles.fabPosition}
            open={isOpen}
            icon={isOpen ? 'close' : 'menu'}
            actions={fabActions}
            onStateChange={handleFABPress}
          />
        </>
      ) : status === DataLoadStatus.ERROR ? (
        <Text>{texts.errors.unexpected}</Text>
      ) : (
        <ActivityIndicator />
      )}
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  center: {
    alignItems: 'center',
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    resizeMode: 'repeat',
  },
  userMedia: {
    width: '80%',
    minHeight:
      Platform.OS === 'web' ? undefined : Dimensions.get('window').width * 0.5,
    margin: 20,
    borderRadius: 8,
    overflow: 'hidden',
  },
  companyRole: {
    marginBottom: 20,
    fontSize: 16,
  },
  fabPosition: {
    padding: 8,
  },
});
