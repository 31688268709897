import React from 'react';
import { RouteProp as NavigationRouteProp } from '@react-navigation/native';
import {
  createMaterialTopTabNavigator,
  MaterialTopTabNavigationProp,
} from '@react-navigation/material-top-tabs';

import { useTheme } from '../../../theming';
import ScreenWrapper from '../../../components/ScreenWrapper';
import ChangeProfilePhotoVideoScreen from './ChangeProfilePhotoVideoScreen';
import ChangeProfileTopBanner from './ChangeProfileTopBanner';

type TabNavigatorParams = {
  ChangeProfilePhotoVideo: undefined;
  ChangeProfileTopBanner: undefined;
};

export type Screens = keyof TabNavigatorParams;

export type NavigationProp<S extends Screens> = MaterialTopTabNavigationProp<
  TabNavigatorParams,
  S
>;

export type RouteProp<S extends Screens> = NavigationRouteProp<
  TabNavigatorParams,
  S
>;

const Tab = createMaterialTopTabNavigator<TabNavigatorParams>();

export default function EditUserInfo() {
  const { colors, spacings } = useTheme();
  return (
    <ScreenWrapper noPadding withKeyboardAwareScrollView>
      <Tab.Navigator
        sceneContainerStyle={{
          backgroundColor: colors.black,
          paddingHorizontal: spacings.content.horizontal,
        }}
        tabBarOptions={{
          indicatorStyle: { backgroundColor: colors.primary },
          labelStyle: { color: colors.primary },
          style: { backgroundColor: colors.darkGrey },
        }}
      >
        <Tab.Screen
          name="ChangeProfilePhotoVideo"
          options={{
            tabBarLabel: 'Photo/video',
          }}
          component={ChangeProfilePhotoVideoScreen}
        />
        <Tab.Screen
          name="ChangeProfileTopBanner"
          options={{
            tabBarLabel: 'Top banner',
          }}
          component={ChangeProfileTopBanner}
        />
      </Tab.Navigator>
    </ScreenWrapper>
  );
}
