import React, { useEffect, useState } from 'react';
import {
  Platform,
  StyleSheet,
  Image,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { WebView } from 'react-native-webview';

import { useTheme } from '../../theming';

import { getEmbeddedUrl, getVideoThumbnail } from './videoUtils';

type Props = {
  url: string;
  previewOnly?: boolean;
  videoStyles?: StyleProp<ViewStyle>;
};

export default function ExternalVideo({
  url,
  previewOnly,
  videoStyles,
}: Props) {
  const { colors } = useTheme();
  const [thumbnail, setThumbnail] = useState<string | undefined>(undefined);
  // We need this only fot ios, because for some reason, setting because colors though style doesn't work, and setting it through JS happens with delay
  const [webViewReady, setWebViewReady] = useState<boolean>(
    Platform.OS === 'ios' ? false : true,
  );

  useEffect(() => {
    if (previewOnly === true) {
      getVideoThumbnail(url).then((thumbnailUrl) => {
        setThumbnail(thumbnailUrl);
      });
    }
  }, [url, previewOnly]);

  if (previewOnly === true) {
    return (
      <Image
        source={{ uri: thumbnail }}
        style={styles.picture}
        accessibilityIgnoresInvertColors
      />
    );
  }
  if (Platform.OS === 'web') {
    return (
      <div style={responsiveWebVideo.wrapper}>
        <iframe
          style={responsiveWebVideo.iframe}
          src={getEmbeddedUrl(url)}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    );
  }
  return (
    <WebView
      originWhitelist={['*']}
      source={{ uri: getEmbeddedUrl(url) }}
      style={[{ backgroundColor: colors.background }, videoStyles]}
      // setting backgroundColor does not work on gitios...
      containerStyle={{ opacity: webViewReady ? 1 : 0 }}
      injectedJavaScript={`document.body.style.backgroundColor='${colors.background}'`}
      onLoadEnd={() => setWebViewReady(true)}
    />
  );
}

const responsiveWebVideo = {
  wrapper: {
    padding: '56.25% 0 0 0',
    position: 'relative',
  },
  iframe: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
} as const;

const styles = StyleSheet.create({
  picture: {
    flex: 1,
    resizeMode: 'contain',
  },
});
